import React, { useEffect, useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  Typography,
  Button,
  Box,
  Card,
  CardContent,
  Divider,
  CircularProgress,
} from "@mui/material";
import { getactivities } from "../../api/appApi";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

const ActivityDrawer = ({ open, onClose }) => {

  const dispatch = useDispatch();


  const UserId = useSelector((state) => state.user?.user?.userid);
  const projectId = useSelector((state) => state.projectId.projectId);

  const [selectedActivity, setSelectedActivity] = useState(false);
  const [activities, setactivities] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // console.log(projectId);
  }, [projectId]);


  const handleProjectSelect = async () => {
    if (selectedActivity == false) {
      try {

        setLoading(true);
        const response = await getactivities(projectId, dispatch);
        // console.log(response.data);
        setSelectedActivity(response.data.activities);
        setactivities(response.data.activities);

        // toast.success("Project Data Fetched Successfully", {
        //   position: "top-right",
        //   autoClose: 1000,
        //   toastId: "task-error",
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        // });
      } catch (err) {
        // toast.error(
        //   "Oops! There was an issue in Fetchng Project Data. Please try again!",
        //   {
        //     position: "top-right",
        //     autoClose: 1000,
        //     toastId: "task-error",
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //   }
        // );
      } finally {
        setLoading(false);
      }
    }

    // setSelectedActivity(prevState => !prevState);
  };

  const handleClose = () => {
    setSelectedActivity(false);
    onClose();
  };

  useEffect(() => {
    // console.log("Selected Activity:", selectedActivity);
  }, [selectedActivity]);

  // useEffect(() => {
  //   const fetchProjects = async () => {
  //     // setLoading(true);
  //     try {
  //       const response = await getactivities(UserId);
  //       // console.log(response.data);
  //       setactivities(response.data.activities);

  //       // toast.success("Project Data Fetched Successfully", {
  //       //   position: "top-right",
  //       //   autoClose: 1000,
  //       //   toastId: "task-error",
  //       //   hideProgressBar: false,
  //       //   closeOnClick: true,
  //       //   pauseOnHover: true,
  //       //   draggable: true,
  //       // });
  //     } catch (err) {
  //       // toast.error(
  //       //   "Oops! There was an issue in Fetchng Project Data. Please try again!",
  //       //   {
  //       //     position: "top-right",
  //       //     autoClose: 1000,
  //       //     toastId: "task-error",
  //       //     hideProgressBar: false,
  //       //     closeOnClick: true,
  //       //     pauseOnHover: true,
  //       //     draggable: true,
  //       //   }
  //       // );
  //     } finally {
  //       // setLoading(false);
  //     }
  //   };

  //   fetchProjects();
  // }, []);


  // const extractChanges = (change) => {
  //   const [fromTo, to] = change.split('To:');
  //   const from = fromTo.replace('From:', '').trim();
  //   return { from, to: to.trim() };
  // };

  const extractChanges = (change) => {
    const [fromTo, to] = change.split('To:');
    const from = fromTo.replace('From:', '').trim();
    return { from, to: to ? to.trim() : '' }; // Handle undefined 'to'
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose && handleClose}>
      <div role="presentation" style={{ width: 250 }}>
        <List>
          {/* <ListItem>
            <Typography variant="h6" sx={{ color: "#2e7d32", marginBottom: 2 }}>
              Activity
            </Typography>
          </ListItem> */}
          <ListItem>
            <Button
              variant="outlined"
              sx={{
                marginBottom: 0,
                textTransform: "capitalize",
                width: "100%",
                color: "#47934a",
                backgroundColor: "transparent",
                borderColor: "#47934a",
                "&:hover": {
                  backgroundColor: "#c8e6c9",
                  borderColor: "#47934a",
                },
              }}
              onClick={() => handleProjectSelect()}
              disabled={loading}
              endIcon={
                loading ? (
                  <CircularProgress size={20} sx={{ color: "#47934a" }} />
                ) : null
              }
            >
              Activity
            </Button>
          </ListItem>
        </List>
      </div>

      {selectedActivity && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            right: 250,
            height: "100vh",
            width: 400,
            backgroundColor: "#fff",
            padding: 2,
            boxShadow: 2,
            zIndex: 1000,
            overflowY: "auto",
            borderLeft: "1px solid #ccc",
          }}
          className='scrollable'
        >
          <Typography variant="h6" sx={{ color: "#2e7d32", marginBottom: 2 }}>
            Details
          </Typography>
          {activities.length > 0 ? (
            activities.map((activity) => (
              <>
                <Card key={activity._id}
                  //sx={{ marginBottom: 2, boxShadow: 4 ,borderRadius:'6px',borderLeft:'6px solid #a5d6a7'}}
                  sx={{
                    marginBottom: 2,
                    boxShadow: 4,
                    borderRadius: '12px',
                    border: 'none',
                    backgroundColor: '#fff',
                    padding: 2,
                    // borderLeft: '7px solid #2e7d32',
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.02)',
                      boxShadow: 8,
                    },
                  }}
                >
                  <CardContent>
                    <Typography variant="body1">{activity.action}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {activity.user.name} ({activity.user.email})
                    </Typography>
                    <Typography variant="body2">{activity.details}</Typography>

                    {activity.changes && Object.keys(activity.changes).length > 0 && (
                      <div className="mt-2">
                        {Object.entries(activity.changes).map(([key, value]) => {
                          const { from, to } = typeof value === 'string' ? extractChanges(value) : { from: '', to: '' };
                          return (
                            <div key={key} className="mb-1">
                              <Typography variant="subtitle2">{key.charAt(0).toUpperCase() + key.slice(1)}:</Typography>
                              <Typography variant="body2">
                                {from} <span style={{ fontWeight: "bolder" }}>{to && to !== '' ? '➺' : null}</span> {to}
                                {/* {from} <span style={{ fontWeight: "bolder" }}> &#10170; </span> {to} */}
                              </Typography>
                            </div>
                          );
                        })}
                      </div>
                    )}

                    {/* {activity.changes && Object.keys(activity.changes).length > 0 && (
                      <div className="mt-2">
                        {Object.entries(activity.changes).map(([key, value]) => {
                          const { from, to } = extractChanges(value);

                          return (
                            <div key={key} className="mb-1">
                              <Typography variant="subtitle2">{key.charAt(0).toUpperCase() + key.slice(1)}:</Typography>
                              <Typography variant="body2">
                                {from} <span style={{fontWeight:"bolder"}}>&#10170;</span> {to}
                              </Typography>
                            </div>
                          );
                        })}
                      </div>
                    )} */}

                    {/* <Typography variant="body2">
                    {activity.details.includes("Changes:") ? (
                      <div>
                        {(() => {
                          const detailsString = activity.details.split("Changes: ")[1]?.trim();
                          console.log("Details String:", detailsString); 

                          const jsonMatch = detailsString.match(/({.*?})(?=\s*to\s*{)/);
                          const validJsonString = jsonMatch ? jsonMatch[0] : null;

                          if (validJsonString) {
                            try {
                              const changes = JSON.parse(validJsonString);

                              return Object.entries(changes).map(([key, value]) => (
                                <div key={key}>
                                  <Typography variant="subtitle1">{key}</Typography>
                                  <Typography variant="body2">
                                    From: {value.from} <br />
                                    To: {value.to}
                                  </Typography>
                                </div>
                              ));
                            } catch (error) {
                              console.error("JSON parsing error:", error);
                              return <Typography variant="body2" color="error">Error parsing changes.</Typography>;
                            }
                          } else {
                            return <Typography variant="body2" color="error">No valid changes found.</Typography>;
                          }
                        })()}
                      </div>
                    ) : (
                      <Typography variant="body2">{activity.details}</Typography>
                    )}
                  </Typography> */}


                    <Typography variant="caption" color="textSecondary">
                      {new Date(activity.timestamp).toLocaleString()}
                    </Typography>
                  </CardContent>


                </Card>

                <Divider sx={{ marginBottom: 1 }} />


              </>
            ))
          ) : (
            <Typography variant="body2" color="textSecondary">
              No activities available.
            </Typography>
          )}
        </Box>
      )}

      {/* {selectedActivity && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            right: 250,
            height: "100vh",
            width: 280,
            backgroundColor: "#f7f7f7",
            padding: 2,
            boxShadow: 2,
            zIndex: 1000,
            overflowY: "auto",
            borderLeft: "1px solid #ccc",
          }}
          className="scrollable"
        >
          <Typography variant="h6" sx={{ color: "#2e7d32", marginBottom: 2 }}>
            Details
          </Typography>
        </Box>
      )} */}
    </Drawer>
  );
};

export default ActivityDrawer;