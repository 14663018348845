import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user:  JSON.parse(localStorage.getItem("user")) || null,
    token: localStorage.getItem("token") || null, // Initialize token from local storage
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        signIn(state, action) {
            // console.log(action.payload.user , "from slice");
            state.user = action.payload.user; // Store user details
            state.token = action.payload.token; // Store token
            localStorage.setItem("token", action.payload.token); // Save token to local storage
            localStorage.setItem("user", JSON.stringify(action.payload.user)); // Save token to local storage
        },
        signOut(state) {
            state.user = null;
            state.token = null;
            localStorage.removeItem("token"); // Remove token from local storage
            localStorage.removeItem("user"); // Remove token from local storage
        },
    },
});


// console.log(userSlice);

export const { signIn, signOut } = userSlice.actions;

export default userSlice.reducer;
