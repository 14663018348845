import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, TextField, Typography, FormControl, Select, MenuItem, CircularProgress } from '@mui/material';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import { MdOutlineEdit, MdDeleteOutline } from "react-icons/md";
import { toast } from 'react-toastify';
import { RxUpdate } from "react-icons/rx";
import { useDispatch } from 'react-redux';
import { createDetailedWorkBreakdown } from '../../api/appApi';

const ResourceAllocation = ({ onNext, onBack, data, detailsData, projectId }) => {

    console.log(`Data From ResourceAllocation : ${data}`);
    

    const dispatch = useDispatch();

    const [inputData, setInputData] = useState(data || []);
    const [isEditing, setIsEditing] = useState(false);
    const [currentRowIndex, setCurrentRowIndex] = useState(null);
    const [sprintRow, setSprintRow] = useState({ email: '', type: '' });
    const [loading, setLoading] = useState(false);

    const isValidEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return emailRegex.test(email);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSprintRow({ ...sprintRow, [name]: value });
    };

    const handleAddOrUpdateRow = () => {
        const { email, type } = sprintRow;

        if (!email || !type) {
            toast.info("Please complete all fields.", {
                position: "top-right",
                autoClose: 1000,
                toastId: "no-tasks",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            return;
        }

        if (!isValidEmail(email)) {
            toast.error("Please enter a valid email address.", {
                position: "top-right",
                autoClose: 1000,
                toastId: "invalid-email",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            return;
        }

        if (isEditing) {
            const updatedData = inputData.map((row, index) =>
                index === currentRowIndex ? sprintRow : row
            );
            setInputData(updatedData);
        } else {
            setInputData([...inputData, sprintRow]);
        }

        resetForm();
    };

    const resetForm = () => {
        setSprintRow({ email: '', type: '' });
        setIsEditing(false);
        setCurrentRowIndex(null);
    };

    const handleEditRow = (index) => {
        setCurrentRowIndex(index);
        setSprintRow(inputData[index]);
        setIsEditing(true);
    };

    const handleDeleteRow = (index) => {
        const updatedData = inputData.filter((_, i) => i !== index);
        setInputData(updatedData);
    };

    const handleNext = async () => {
        // console.log(`inputData is ${inputData}`);

        const PostWorkBreakdown = {
            projectId: projectId,
            "detailed_requirements": detailsData,
            "resources": inputData
        }

        console.log(`PostHlData is ${PostWorkBreakdown}`);


        if (detailsData.trim() === '' || inputData.length === 0) {

            toast.info("Please ensure all fields are completed before proceeding.", {
                position: "top-right",
                autoClose: 1000,
                toastId: "incomplete-fields",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });

            return;
        }

        try {
            setLoading(true);
            const response = await createDetailedWorkBreakdown(PostWorkBreakdown, dispatch);
            toast.success("🎉 project has been Initalize successfully!", {
                position: "top-right",
                autoClose: 1000,
                toastId: "task-success",
                hideProgressBar: false,
            });

            console.log(response.data.generateDetailedWorkBreakdownData.response.work_breakdown);
            

            // console.log(response.data.detailedWorkBreakdown.work_breakdown);

            onNext({ workflowBreakdown: response.data.generateDetailedWorkBreakdownData.response.work_breakdown, resources: inputData });

        } catch (error) {
            console.error("Error updating task:", error);
            toast.error(
                "Oops! There was an issue updating the task. Please try again!",
                {
                    position: "top-right",
                    autoClose: 1000,
                    toastId: "task-error",
                    hideProgressBar: false,
                }
            );

        } finally {
            setLoading(false);
        }



        // onNext({ resources: inputData });
    };

    useEffect(() => {

        console.log(projectId);

        // console.log("Resource Data:", data);
        // console.log("Detailed Specs Data:", detailsData);
    }, [data, detailsData, projectId]);


    return (
        <>
            <Box sx={{
                width: '100%',
                height: 'auto',
                paddingLeft: { xs: 2, sm: 4, md: 6 },
                paddingRight: { xs: 2, sm: 4, md: 6 },
                paddingTop: '30px',
                // borderRadius: '8px',
                // backgroundColor: '#f9f9f9',
                // boxShadow: 3,
                display: 'flex',
                flexDirection: 'column',
            }}>
                <Typography
                    variant="h5"
                    sx={{
                        fontWeight: 'bold',
                        color: '#2c3e50',
                        marginBottom: 3,
                        textAlign: 'center',
                    }}
                >
                    Add Email & Type Information
                </Typography>


                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: 2,
                    }}
                >
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: '14px',
                            color: '#34495e',
                            fontWeight: 500,
                        }}
                    >
                        Email
                    </Typography>

                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: '14px',
                            color: '#34495e',
                            fontWeight: 500,
                        }}
                    >
                        Type
                    </Typography>

                    <Box>
                    </Box>
                </Box>



                {inputData.length > 0 && (
                    <Box sx={{ marginBottom: 2 }}>

                        {inputData.map((row, index) => (
                            <>


                                <Box
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginBottom: 1,
                                    }}
                                >

                                    <TextField
                                        value={row.email}
                                        variant="outlined"
                                        fullWidth
                                        sx={{ marginRight: 2 }}
                                        disabled
                                        placeholder="Email Address"
                                        InputProps={{
                                            style: {
                                                fontSize: '14px',
                                                color: '#34495e',
                                            },
                                        }}
                                    />

                                    <TextField
                                        value={row.type}
                                        variant="outlined"
                                        fullWidth
                                        sx={{ marginRight: 2 }}
                                        disabled
                                        placeholder="User Type"
                                        InputProps={{
                                            style: {
                                                fontSize: '14px',
                                                color: '#34495e',
                                            },
                                        }}
                                    />

                                    <Box sx={{ display: 'flex', gap: '0.2rem' }}>
                                        <MdOutlineEdit onClick={() => handleEditRow(index)} size={30} style={{ color: '#4caf50', cursor: 'pointer' }} />
                                        <MdDeleteOutline onClick={() => handleDeleteRow(index)} size={30} style={{ color: '#e74c3c', cursor: 'pointer' }} />
                                    </Box>

                                    {/*                                     
                                    <Button
                                        onClick={handleAddOrUpdateRow}
                                        variant="contained"
                                        color="primary"
                                        // startIcon={isEditing ? <RxUpdate size={12}/> : <FaPlus  size={12} />}
                                        sx={{
                                            backgroundColor: "#4caf50",
                                            textTransform: "capitalize",
                                            color: "#fff",
                                            borderRadius: "8px",
                                            padding: "18px 0px",
                                            transition: "background-color 0.3s, transform 0.2s",
                                            "&:hover": {
                                                backgroundColor: "#388e3c",
                                                transform: "scale(1.05)",
                                            },
                                        }}
                                    >
                                        {isEditing ? <RxUpdate size={18} /> : <FaPlus size={18} />}

                                    </Button> */}
                                </Box>

                                {/* <Box
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginBottom: 1,

                                    }}
                                >

                                    <TextField
                                        value={row.email}
                                        variant="outlined"
                                        fullWidth
                                        sx={{ marginRight: 2 }}
                                        disabled
                                        placeholder="Email Address"
                                        InputProps={{
                                            style: {
                                                fontSize: '14px',
                                                color: '#34495e',
                                            },
                                        }}
                                    />

                                    <TextField
                                        value={row.type}
                                        variant="outlined"
                                        fullWidth
                                        sx={{ marginRight: 2 }}
                                        disabled
                                        placeholder="User Type"
                                        InputProps={{
                                            style: {
                                                fontSize: '14px',
                                                color: '#34495e',
                                            },
                                        }}
                                    />

                                    <Box sx={{ display: 'flex', gap: '0.2rem' }}>
                                        <MdOutlineEdit onClick={() => handleEditRow(index)} size={30} style={{ color: '#4caf50', cursor: 'pointer' }} />
                                        <MdDeleteOutline onClick={() => handleDeleteRow(index)} size={30} style={{ color: '#e74c3c', cursor: 'pointer' }} />
                                    </Box>
                                </Box> */}
                            </>
                        ))}
                    </Box>
                )}

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: 1,
                    }}
                >
                    <TextField
                        name="email"
                        value={sprintRow.email}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth
                        placeholder="Enter Email"
                        sx={{ marginRight: 2 }}
                        InputProps={{
                            style: {
                                fontSize: '14px',
                                color: '#34495e',
                            },
                        }}
                    />

                    <TextField
                        name="type"
                        value={sprintRow.type}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth
                        placeholder="Enter Type (e.g. Admin)"
                        sx={{ marginRight: 2 }}
                        InputProps={{
                            style: {
                                fontSize: '14px',
                                color: '#34495e',
                            },
                        }}
                    />


                    <Button
                        onClick={handleAddOrUpdateRow}
                        variant="contained"
                        color="primary"
                        // startIcon={isEditing ? <RxUpdate size={12}/> : <FaPlus  size={12} />}
                        sx={{
                            backgroundColor: "#4caf50",
                            textTransform: "capitalize",
                            color: "#fff",
                            borderRadius: "8px",
                            height: '50px',
                            transition: "background-color 0.3s, transform 0.2s",
                            "&:hover": {
                                backgroundColor: "#388e3c",
                                transform: "scale(1.05)",
                            },
                        }}
                    >
                        {isEditing ? <RxUpdate size={18} /> : <FaPlus size={18} />}

                    </Button>
                </Box>


            </Box>


            <Box sx={{
                mt: 2,
                paddingLeft: { xs: 2, sm: 4, md: 6 },
                paddingRight: { xs: 2, sm: 4, md: 6 },
                textAlign: { xs: 'center', sm: 'right' },
            }}>

                <Button
                    onClick={onBack}
                    variant="contained"
                    style={{
                        backgroundColor: '#4caf50', textTransform: 'capitalize', color: '#fff', borderRadius: '8px', padding: '10px 20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', transition: "background-color 0.3s, transform 0.2s", marginRight: '0.7rem',
                        "&:hover": { backgroundColor: "#388e3c", transform: "scale(1.05)", },
                    }}
                    disabled={loading}
                // endIcon={loading ? <CircularProgress size={20} style={{ color: '#fff' }} /> : null}
                >
                    Back
                </Button>


                <Button
                    onClick={handleNext}
                    variant="contained"
                    style={{
                        backgroundColor: '#4caf50', textTransform: 'capitalize', color: '#fff', borderRadius: '8px', padding: '10px 20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', transition: "background-color 0.3s, transform 0.2s",
                        "&:hover": { backgroundColor: "#388e3c", transform: "scale(1.05)", },
                    }}
                    disabled={loading}
                    endIcon={loading ? <CircularProgress size={20} style={{ color: '#fff' }} /> : null}
                >
                    Next
                </Button>
            </Box>
        </>
    );
};

export default ResourceAllocation;