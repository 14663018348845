import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "../redux/reduxslices/userSlices";
import { clearProjectId } from "../redux/reduxslices/ProjectIdSlice";

const apiClient = axios.create({
  // baseURL: "http://localhost:4000/api",
    // baseURL:"https://us-central1-islam360-stagging.cloudfunctions.net/taskmanagerserver/api",
    baseURL: "https://us-central1-sampleprojectforusman.cloudfunctions.net/taskmanagerserver/api",

});

// Function to sign up a new user
export const signupUser = async (values) => {
  console.log("Sign up values:", values);
  const response = await apiClient.post("/users/signup", values);
  console.log("Sign up successful:", response.data);
  return response; // Return the user data if needed
};
