import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
// import Navbar from './Navbar';
// import Sidebar from './Sidebar';
import { useDispatch, useSelector } from 'react-redux';
// import { setProjectId } from '../redux/reduxslices/ProjectIdSlice'; 
import { Navbar } from '../Navbar.jsx';
import { setProjectId } from '../../redux/reduxslices/ProjectIdSlice.jsx';
import Sidebar from '../Sidebar.jsx';

const MainLayout = ({ children }) => {

    const dispatch = useDispatch();
    const projectId = useSelector((state) => state.projectId.projectId);
    const [activeProject, setActiveProject] = useState(projectId);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const handleSidebarToggle = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const handleProjectSelect = (selectedProjectId) => {
        setActiveProject(selectedProjectId);
        dispatch(setProjectId(selectedProjectId));
        setSidebarOpen(false);
    };

    return (
        <Box className="flex flex-col h-screen">
            <Navbar onSidebarToggle={handleSidebarToggle} />

            <Box className="flex flex-grow">
                <Sidebar
                    open={sidebarOpen}
                    onClose={() => setSidebarOpen(false)}
                    onProjectSelect={handleProjectSelect}
                    activeProject={activeProject}
                />

                <Box className="flex-1 p-4 bg-white overflow-y-auto">
                    {children}
                </Box>
            </Box>
        </Box>
    );
};

export default MainLayout;
