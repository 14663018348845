import React, { useState } from "react";
import { Formik } from "formik";
import {
  TextField,
  Button,
  Typography,
  Paper,
  Box,
  InputAdornment,
  IconButton,
  Grid,
  CircularProgress,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { SignInSchema } from "./Validation";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { signIn } from "../../redux/reduxslices/userSlices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loginUser } from "../../api/signin";

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);

  // console.log(dispatch);
  const token = useSelector((state) => state.user.token);
  // console.log("Token is :", token)

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setLoader(true);
    try {
      const response = await loginUser(values);
      // console.log("Sign in successful:", response.data.user);
      dispatch(
        signIn({
          user: response.data.user,
          token: response.data.token,
        })
      );
      toast.success("Login Successfully!", {
        position: "top-right",
        autoClose: 1000,
        toastId: "login-success",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      setTimeout(() => {
        navigate("/");
      }, 1000);
    } catch (error) {

      if (error.response && error.response.data.message === "Invalid credentials") {
        toast.error("Credentials are incorrect. Please check and try again!", {
          position: "top-right",
          autoClose: 1200,
          toastId: "credential-error",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        toast.error("Login Error Please Try Again!", {
          position: "top-right",
          autoClose: 1000,
          toastId: "login-error",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }

      // toast.error("Error in login Please Try Again!", {
      //   position: "top-right",
      //   autoClose: 1000,
      //   toastId: "login-error",
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      // });

      console.error("Error during signin:", error);
      // Show error toast with a custom ID

      const errorMessage = error.response?.data?.message || "Error signing up";
      setErrors({ submit: errorMessage });
    } finally {
      setLoader(false);
      setSubmitting(false);
    }
  };

  // const handleSubmit = (values, { setSubmitting }) => {
  //     console.log('Form submitted:', values);
  //     setSubmitting(false);
  // };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <Box className="flex justify-center items-center min-h-[85vh] bg-gray-100 p-4">
      <Paper
        elevation={5}
        sx={{ borderRadius: "13px" }}
        className="w-full max-w-md p-8 shadow-lg"
      >
        <Typography
          variant="h4"
          component="h1"
          className="text-center mb-6 font-semibold text-gray-800"
        >
          Sign In
        </Typography>
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={SignInSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body1" className="mb-1 font-medium">
                    Email or Name
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    style={{ backgroundColor: "white" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" className="mb-1 font-medium">
                    Password
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    style={{ backgroundColor: "white" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={togglePasswordVisibility}
                            edge="end"
                          >
                            <FontAwesomeIcon
                              icon={showPassword ? faEyeSlash : faEye}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              <div className="text-start mt-4 mb-4">
                <Typography variant="body2">
                  Don't have an account?{" "}
                  <Link to="/signup" className="text-blue-500 hover:underline">
                    Sign Up
                  </Link>
                </Typography>

                <Typography mt={0.2} variant="body2">
                  <Link
                    to="/forgot-password"
                    className="text-blue-500 hover:underline"
                  >
                    Forgot Password?
                  </Link>
                </Typography>
              </div>

              <div
                style={{ marginTop: "24px" }}
                className="flex justify-center"
              >
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    backgroundColor: "#4caf50",
                    textTransform: "capitalize",
                    color: "#fff",
                    borderRadius: "8px",
                    padding: "10px 20px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    transition: "background-color 0.3s, transform 0.2s",
                    "&:hover": {
                      backgroundColor: "#388e3c",
                      transform: "scale(1.05)",
                    },
                  }}
                  disabled={loader}
                  endIcon={
                    loader ? (
                      <CircularProgress size={20} sx={{ color: "#fff" }} />
                    ) : null
                  }
                >
                  Sign In
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Paper>

      <ToastContainer />
    </Box>
  );
};

export default SignIn;