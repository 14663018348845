import React, { useEffect, useState } from "react";
import { Box, Button, Modal, TextField, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Typography, Paper, CircularProgress, Autocomplete, Chip, Tooltip, Select, Menu, Checkbox, FormControlLabel, Dialog, DialogTitle, DialogContent, DialogActions, } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { addTask, updateTask } from "../api/appApi";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TbFilterSearch } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { signOut } from "../redux/reduxslices/userSlices";
import { clearProjectId } from "../redux/reduxslices/ProjectIdSlice";

const TableTrello = ({ data, onUpdate }) => {
  // console.log(`Data has been Succeessfully received in TrelloBoard.jsx ${data}`);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const UserRole = useSelector((state) => state.user?.user?.role);
  const UserId = useSelector((state) => state.user?.user?.userid);
  // console.log(`UserId is ${UserId}`);
  // console.log(UserRole);
  const [projectData, setProjectData] = useState(null);
  const [projectId, setProjectId] = useState("");
  const [userId, setUserId] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [dialogueOpen, setDialogueOpen] = useState(false);
  const handleOpenDialogue = () => setDialogueOpen(true);
  const handleCloseDialogue = () => setDialogueOpen(false);
  const [droppedItem, setDroppedItem] = useState(null);
  const [checked, setChecked] = useState({ deploy: false, test: false, document: false, });
  const [SprintData, setSprintData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const checkboxes = [{ name: 'deploy', label: 'Did you deploy the code?' }, { name: 'test', label: 'Did you test the application?' }, { name: 'document', label: 'Did you document your code?' },];

  const handleCheckboxChange = (event) => {
    setChecked({
      ...checked,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    if (data) {
      setProjectData(data);
      setProjectId(data.project._id);
      setTasks(data.project.tasks);
      setUserId(data.project.members);
      setSprintData(data.project.sprints);
      // setFilteredTasks(data.project.tasks);
      // handleApplyFilters();
    }
  }, [data]);

  const [openModal, setOpenModal] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);
  const [newTask, setNewTask] = useState({ title: "", status: "Not Started", description: "", assignDate: "", sprintname: '', sprintcatname: '', dueDate: "", etaDate: "", assign: [], projectId: "", priority: "", });
  const statuses_ = ["Not Started", "In Progress", "Done", "Backlogs", "Finished",];
  const priorities = ["High", "Medium", "Low"];
  const statuses = UserRole !== "admin" ? statuses_.filter((status) => status !== "Finished") : statuses_;
  const statusOrder = { Finished: 5, Done: 4, "In Progress": 3, "Not Started": 2, Backlogs: 1, };

  const handleOpen = (task) => {
    if (task) {
      const formattedTask = {
        ...task,
        assignDate: formatDate(task.assignDate),
        dueDate: formatDate(task.dueDate),
        etaDate: formatDate(task.etaDate),
      };

      setCurrentTask(task);
      setNewTask(formattedTask);
    } else {
      setCurrentTask(null);
      setNewTask({ title: "", status: 'Backlogs', description: "", assignDate: "", sprintname: '', sprintcatname: '', dueDate: "", etaDate: "", assign: "", projectId: projectId });
      // setNewTask({ title: "", status: "Backlogs", description: "", assignDate: "", dueDate: "", etaDate: "", assign: "", projectId: projectId, });
    }
    setOpenModal(true);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const [day, month, year] = dateString.split("/");
    return `${year}-${month}-${day}`; // Converts to YYYY-MM-DD
  };

  const handleClose = () => {
    setOpenModal(false);
    setCurrentTask(null);
  };

  const handleAddTask = async () => {
    if (newTask.assign.length > 0 && newTask.title.trim() !== "" && newTask.description.trim() !== "") {
      setLoading(true);
      try {
        const updatedTask = {
          ...newTask,
          UserId: UserId,
        };

        // console.log(updatedTask);
        const response = await addTask(updatedTask, dispatch);
        // const response = await addTask(updatedTask); 
        const createdTask = response.data;
        // console.log(`Task has been created Successfully ${createdTask}`);
        setTasks((prevTasks) => [
          ...prevTasks,
          { _id: createdTask._id, ...createdTask },
        ]);

        const updatedProjectData = {
          ...projectData,
          project: {
            ...projectData.project,
            tasks: [...tasks, { _id: createdTask._id, ...createdTask }],
          },
        };
        setProjectData(updatedProjectData);
        onUpdate(updatedProjectData);

        setOpenModal(false);
        toast.success("🎉 Task has been added successfully!",
          { position: "top-right", autoClose: 1000, toastId: "task-success", hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, }
        );
      } catch (error) {
        console.error("Error adding task:", error);

        toast.error(
          "Oops! There was an issue adding the task. Please try again!",
          { position: "top-right", autoClose: 1000, toastId: "task-error", hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, }
        );

        // if (error.response && error.response.data.message === "Invalid token") {

        //   toast.error(
        //     "Your session has expired. Please sign in again to continue.",
        //     { position: "top-right", autoClose: 1000, toastId: "token-error", hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, }
        //   );
        //   console.error("Invalid token. Please log in again.");
        //   setTimeout(() => {
        //     dispatch(signOut({ user: null, token: null }));
        //     dispatch(clearProjectId());
        //     navigate("/signout");
        //   }, 1000);
        // }
        // else {
        //   toast.error(
        //     "Oops! There was an issue adding the task. Please try again!",
        //     { position: "top-right", autoClose: 1000, toastId: "task-error", hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, }
        //   );
        // }

      } finally {
        setLoading(false);
      }
    }
    setOpenModal(false);
  };

  const handleUpdateTask = async () => {

    if (newTask.status === "Done") {

      setChecked({
        deploy: false,
        test: false,
        document: false,
      });
      setDroppedItem({
        id: newTask["_id"],
        statusTitle: newTask.status,
      });
      setDialogueOpen(true);
      handleClose();
      return;
    }



    setLoading(true);

    try {
      const newTask_JsonObj = {
        title: newTask.title,
        status: newTask.status,
        description: newTask.description,
        assignDate: newTask.assignDate,
        dueDate: newTask.dueDate,
        etaDate: newTask.etaDate,
        assign: newTask.assign,
        priority: newTask.priority,
        projectId: newTask.project,
        sprintname: newTask.sprintname,
        sprintcatname: newTask.sprintcatname,
        UserId: UserId,
      };

      const response = await updateTask(newTask._id, newTask_JsonObj, dispatch);
      // const response = await updateTask(newTask._id, newTask_JsonObj); //axios.put(`https://us-central1-islam360-stagging.cloudfunctions.net/taskmanagerserver/api/tasks/${newTask._id}`, newTask_JsonObj);
      const updatedTask = response.data.task;
      // console.log(`Task has been updated successfully:`, updatedTask);
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task._id === updatedTask._id ? { ...updatedTask } : task
        )
      );

      const updatedProjectData = {
        ...projectData,
        project: {
          ...projectData.project,
          tasks: [
            ...tasks.map((task) =>
              task._id === updatedTask._id ? updatedTask : task
            ),
          ],
        },
      };
      setProjectData(updatedProjectData);
      onUpdate(updatedProjectData);

      setOpenModal(false);
      toast.success("🎉 Task has been update successfully!", {
        position: "top-right",
        autoClose: 1000,
        toastId: "task-success",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      console.error("Error updating task:", error);
      setOpenModal(false);
      toast.error(
        "Oops! There was an issue updating the task. Please try again!",
        {
          position: "top-right",
          autoClose: 1000,
          toastId: "task-error",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );

      // if (error.response && error.response.data.message === "Invalid token") {

      //   toast.error(
      //     "Your session has expired. Please sign in again to continue.",
      //     {
      //       position: "top-right",
      //       autoClose: 1000,
      //       toastId: "token-error",
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       draggable: true,
      //     }
      //   );
      //   console.error("Invalid token. Please log in again.");
      //   setTimeout(() => {
      //     dispatch(signOut({ user: null, token: null }));
      //     dispatch(clearProjectId());
      //     navigate("/signout");
      //   }, 1000);
      // }
      // else {
      //   toast.error(
      //     "Oops! There was an issue updating the task. Please try again!",
      //     {
      //       position: "top-right",
      //       autoClose: 1000,
      //       toastId: "task-error",
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       draggable: true,
      //     }
      //   );
      // }


    } finally {
      setLoading(false);
    }
    setCurrentTask(null);
  };

  // Handle chip addition/removal
  const handleChipChange = (value) => {
    setNewTask((prev) => {
      const isAlreadyAssigned = prev.assign.includes(value);
      if (isAlreadyAssigned) {
        setSelectedUser(null);
        return {
          ...prev,
          assign: prev.assign.filter((user) => user !== value),
          
        };

      } else {
        setSelectedUser(null);
        return { ...prev, assign: [...prev.assign, value] };

      }
    });
  };

  const handleStatusChange = async (newTask, newStatus) => {

    if (newStatus === "Done") {

      setChecked({
        deploy: false,
        test: false,
        document: false,
      });
      setDroppedItem({
        id: newTask["_id"],
        statusTitle: newStatus,
      });
      setDialogueOpen(true);
      handleClose();
      return;
    }


    setLoading(true);
    try {
      const newTask_JsonObj = {
        title: newTask.title, status: newStatus, sprintname: newTask.sprintname,
        sprintcatname: newTask.sprintcatname, description: newTask.description, assignDate: formatDate(newTask.assignDate), dueDate: formatDate(newTask.dueDate), etaDate: formatDate(newTask.etaDate), assign: newTask.assign, priority: newTask.priority, projectId: newTask.project, UserId: UserId,
      };

      const response = await updateTask(newTask._id, newTask_JsonObj, dispatch);
      // const response = await updateTask(newTask._id, newTask_JsonObj);
      const updatedTask = response.data.task;

      // console.log(`Task has been updated successfully:`, updatedTask);

      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task._id === updatedTask._id ? { ...updatedTask } : task
        )
      );

      const updatedProjectData = {
        ...projectData,
        project: {
          ...projectData.project,
          tasks: [
            ...tasks.map((task) =>
              task._id === updatedTask._id ? updatedTask : task
            ),
          ],
        },
      };
      setProjectData(updatedProjectData);
      onUpdate(updatedProjectData);

      setOpenModal(false);
      toast.success("🎉 Task has been update successfully!", {
        position: "top-right", autoClose: 1000, toastId: "task-success", hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true,
      });
    } catch (error) {
      console.error("Error updating task:", error);
      setOpenModal(false);
      if (error.response && error.response.data.message === "Invalid token") {

        toast.error(
          "Your session has expired. Please sign in again to continue.",
          {
            position: "top-right", autoClose: 1000, toastId: "token-error", hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true,
          }
        );
        console.error("Invalid token. Please log in again.");
        setTimeout(() => {
          dispatch(signOut({ user: null, token: null }));
          dispatch(clearProjectId());
          navigate("/signout");
        }, 1000);
      }
      else {
        toast.error(
          "Oops! There was an issue updating the task. Please try again!",
          {
            position: "top-right", autoClose: 1000, toastId: "task-error", hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true,
          }
        );
      }

    } finally {
      setLoading(false);
    }
    setCurrentTask(null);
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose_fr_filter = () => {
    setAnchorEl(null);
  };

  const handleStatusChange_fr_filter = async (status) => {
    setSelectedStatuses((prev) =>
      prev.includes(status)
        ? prev.filter((s) => s !== status)
        : [...prev, status]
    );
    // console.log("Data for selected filter status are" ,selectedStatuses)
  };

  const handleApplyFilters = (tasksdetails) => {
    const updatedFilteredTasks = tasksdetails
      .filter(
        (task) =>
          selectedStatuses.length === 0 ||
          selectedStatuses.includes(task.status)
      )
      .sort((a, b) => {
        const priorityOrder = { High: 3, Medium: 2, Low: 1 };
        if (selectedStatuses.length === 0) {
          return (
            statusOrder[b.status] - statusOrder[a.status] ||
            priorityOrder[b.priority] - priorityOrder[a.priority]
          );
        } else {
          return (
            statusOrder[b.status] - statusOrder[a.status] ||
            priorityOrder[b.priority] - priorityOrder[a.priority]
          );
        }
      });

    setFilteredTasks(updatedFilteredTasks);
    // console.log("Filters applied:", selectedStatuses);
    handleClose_fr_filter();
  };


  const handleDialogSubmit = async () => {
    const allChecked = Object.values(checked).every(Boolean);

    if (allChecked && droppedItem) {
      const taskId = droppedItem.id;
      const taskToUpdate = tasks.find((task) => task._id === taskId);
      if (!taskToUpdate) return;
      const updatedTask = {
        ...taskToUpdate,
        status: droppedItem.statusTitle,
        assignDate: formatDate(taskToUpdate.assignDate),
        dueDate: formatDate(taskToUpdate.dueDate),
        UserId: UserId,
        sprintname: newTask.sprintname,
        sprintcatname: newTask.sprintcatname,
        etaDate: formatDate(taskToUpdate.etaDate),
      };
      // console.log(updatedTask);
      try {
        setLoading(true);
        const response = await updateTask(taskId, updatedTask, dispatch);
        const responseData = response.data.task;
        setTasks((prevTasks) =>
          prevTasks.map((task) =>
            task._id === responseData._id ? responseData : task
          )
        );
        const updatedProjectData = {
          ...projectData,
          project: {
            ...projectData.project,
            tasks: tasks.map((task) =>
              task._id === responseData._id ? responseData : task
            ),
          },
        };

        setProjectData(updatedProjectData);
        onUpdate(updatedProjectData);

        toast.success("🎉 Task has been updated successfully!", {
          position: "top-right", autoClose: 1000, toastId: "upd-task-success", hideProgressBar: false,
        });
      } catch (error) {
        console.error("Error updating task:", error);

      } finally {
        setDialogueOpen(false);
        setLoading(false);

      }
    } else {
      toast.error("Please select all activities before submitting.", {
        position: "top-right", autoClose: 1000, toastId: "act-task-error", hideProgressBar: false,
      });
    }
  };


  useEffect(() => {
    // console.log("Data for selected filter status are", selectedStatuses);
  }, [selectedStatuses]);

  useEffect(() => {
    handleApplyFilters(data.project.tasks);
  }, [data]);

  return (
    <Box sx={{ paddingTop: 2, borderRadius: "10px" }}>
      <TableContainer component={Paper} elevation={3} sx={{ borderRadius: "px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={{ width: "15%", backgroundColor: "#c8e6c9", color: "#47934a", fontWeight: "bold", }}>
                Title
              </TableCell>
              <TableCell align="left" sx={{ width: "10%", backgroundColor: "#c8e6c9", color: "#47934a", fontWeight: "bold", }}>
                Status
                <IconButton onClick={handleFilterClick}>
                  <TbFilterSearch size={18} color="#47934a" />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose_fr_filter}
                >
                  <Box sx={{ padding: 2, minWidth: 200 }}>
                    <Typography variant="h6" gutterBottom>
                      Select Statuses
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      {statuses.map((status) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedStatuses.includes(status)}
                              onChange={() =>
                                handleStatusChange_fr_filter(status)
                              }
                              sx={{ color: "#4caf50", "&.Mui-checked": { color: "#388e3c", }, }}
                            />
                          }
                          label={status}
                          key={status}
                        />
                      ))}
                    </Box>
                    <Button
                      variant="contained"
                      onClick={() => handleApplyFilters(data.project.tasks)}
                      sx={{
                        backgroundColor: "#4caf50", textTransform: "capitalize", color: "#fff", borderRadius: "8px", padding: "10px 20px", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", transition: "background-color 0.3s, transform 0.2s", marginTop: 2,
                        "&:hover": { backgroundColor: "#388e3c", transform: "scale(1.05)", },
                      }}
                    >
                      Apply Filter
                    </Button>
                  </Box>
                </Menu>
              </TableCell>
              <TableCell align="left" sx={{ width: "10%", backgroundColor: "#c8e6c9", color: "#47934a", fontWeight: "bold", }}>
                Priority
              </TableCell>
              <TableCell align="left" sx={{ width: "15%", backgroundColor: "#c8e6c9", color: "#47934a", fontWeight: "bold", }}>
                Assigned To
              </TableCell>
              <TableCell align="left" sx={{ width: "25%", backgroundColor: "#c8e6c9", color: "#47934a", fontWeight: "bold", }}>
                Description
              </TableCell>
              <TableCell align="left" sx={{ width: "10%", backgroundColor: "#c8e6c9", color: "#47934a", fontWeight: "bold", }}>
                Assign Date
              </TableCell>
              <TableCell align="left" sx={{ width: "10%", backgroundColor: "#c8e6c9", color: "#47934a", fontWeight: "bold", }}>
                Due Date
              </TableCell>
              {
                UserRole && UserRole !== 'client' &&
                <TableCell align="left" sx={{ width: "5%", backgroundColor: "#c8e6c9", color: "#47934a", fontWeight: "bold", }}>
                  Actions
                </TableCell>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTasks.length === 0 ? (
              <TableRow>
                <TableCell colSpan={9} align="center" sx={{ fontStyle: "italic" }}>
                  No tasks available.
                </TableCell>
              </TableRow>
            ) : (
              filteredTasks.map((task) => (
                <TableRow
                  key={task.id}
                  sx={{ "&:hover": { backgroundColor: "#b2e0b2e3" } }}
                >
                  <TableCell align="left" sx={{ maxHeight: "50px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", }}>
                    {task.title}
                  </TableCell>

                  <TableCell align="left" sx={{ maxHeight: "50px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", }}>

                    {UserRole && UserRole === 'client' ? (
                      task.status
                    ) : (
                      <Select
                        value={task.status}
                        onChange={(e) => handleStatusChange(task, e.target.value)}
                        displayEmpty
                        variant="standard"
                        sx={{
                          "& .MuiSelect-select": { padding: "0.5rem", borderBottom: "none" },
                          "& .MuiSelect-outlined": { border: "none" },
                          "& .MuiInputBase-root": { borderBottom: "none", boxShadow: "none" },
                          "&:before": { display: "none" },
                          "&:after": { display: "none" }
                        }}
                      >
                        {statuses.map((status) => (
                          <MenuItem key={status} value={status}>
                            {status}
                          </MenuItem>
                        ))}
                      </Select>
                    )}

                    {/* <Select
                      value={task.status}
                      onChange={(e) => handleStatusChange(task, e.target.value)}
                      displayEmpty
                      variant="standard"
                      sx={{ "& .MuiSelect-select": { padding: "0.5rem", borderBottom: "none", }, "& .MuiSelect-outlined": { border: "none", }, "& .MuiInputBase-root": { borderBottom: "none", boxShadow: "none", }, "&:before": { display: "none", }, "&:after": { display: "none", }, }}
                    >
                      {statuses.map((status) => (
                        <MenuItem key={status} value={status}>
                          {status}
                        </MenuItem>
                      ))}
                    </Select> */}

                  </TableCell>


                  <TableCell align="left"
                    sx={{
                      maxHeight: "50px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis",
                      color: task.priority === "High" ? "#e63946" : task.priority === "Medium" ? "#f1c40f" : task.priority === "Low" ? "#27ae60" : "transparent",
                      fontWeight: "bolder",
                      padding: "8px",
                      // backgroundColor: task.priority === "High" ? "rgba(230, 57, 70, 0.2)" : task.priority === "Medium" ? "rgba(241, 196, 15, 0.2)" : task.priority === "Low" ? "rgba(39, 174, 96, 0.2)" : "transparent",
                    }}
                  >
                    {task.priority}
                  </TableCell>
                  <TableCell align="left"
                    sx={{ maxWidth: "200px", maxHeight: "50px", whiteSpace: "nowrap", overflow: "hidden", }}
                  >
                    <Box className="scrollable"
                      sx={{ display: "flex", overflowX: "auto", padding: "1px", maxWidth: "200px", "&::-webkit-scrollbar": { height: "6px" }, "&::-webkit-scrollbar-thumb": { background: "#888" }, }}
                    >
                      {task.assign.length > 0 &&
                        task.assign.map((member, index) => {
                          const label =
                            typeof member === "string" ? member : member.name;
                          return (
                            <Tooltip key={index} title={label} arrow>
                              <Chip
                                label={label}
                                size="small"
                                sx={{ whiteSpace: "nowrap", marginRight: "8px", }}
                              />
                            </Tooltip>
                          );
                        })}
                    </Box>
                  </TableCell>
                  <TableCell align="left" sx={{ maxHeight: "100px", overflow: "hidden", }}>
                    <div className="ellipsis-text" style={{ maxHeight: "100px", overflow: "hidden", whiteSpace: "nowrap", }}>
                      <ReactQuill
                        value={task.description}
                        readOnly={true}
                        theme={"bubble"}
                        style={{ maxHeight: "100px", overflow: "hidden", textOverflow: "ellipsis", display: "block", }}
                      />
                    </div>
                  </TableCell>

                  <TableCell align="left"
                    sx={{ maxHeight: "50px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", }}
                  >
                    {task.assignDate}
                  </TableCell>
                  <TableCell align="left"
                    sx={{ maxHeight: "50px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", }}
                  >
                    {task.dueDate}
                  </TableCell>
                  {
                    UserRole && UserRole !== 'client' &&
                    <TableCell align="left">
                      <IconButton
                        onClick={() => handleOpen(task)}
                        sx={{ color: "#47934a" }}
                      >
                        <FontAwesomeIcon icon={faPen} />
                      </IconButton>
                    </TableCell>
                  }
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {
        UserRole && UserRole !== 'client' &&
        <Button
          variant="contained"
          onClick={() => handleOpen(null)}
          sx={{ marginTop: 2, backgroundColor: "#c8e6c9", fontWeight: "bold", color: "#47934a", "&:hover": { backgroundColor: "#c8e6c9" }, }}
        >
          + New Task
        </Button>
      }


      <Modal className="h-auto" open={openModal} onClose={handleClose}>
        <Box
          className="bg-white p-6 rounded-lg scrollable"
          style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: { xs: "98%", sm: 600 }, maxHeight: "70vh", overflowY: "auto", boxShadow: 24, }}
        >
          <Typography variant="h6" sx={{ marginBottom: "8px" }}>
            {currentTask ? "Edit Task" : "Add a New Task"}
          </Typography>

          <span
            style={{ display: "flex", gap: "1rem", marginBottom: "5px", alignItems: "center", border: "1px solid gray", padding: "10px", borderRadius: "5px", backgroundColor: "#f9f9f9", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", }}
          >
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
              Title :
            </Typography>
            <TextField
              value={newTask.title}
              onChange={(e) =>
                setNewTask({ ...newTask, title: e.target.value })
              }
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{
                style: { border: "none", padding: "5px", fontSize: "16px" },
              }}
            />
          </span>
          <span
            style={{ display: "flex", gap: "1rem", marginBottom: "5px", alignItems: "center", border: "1px solid gray", padding: "10px", borderRadius: "5px", backgroundColor: "#f9f9f9", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", }}
          >
            <Typography
              variant="body1"
              style={{ fontWeight: "bold", width: "atuo" }}
            >
              Priority :
            </Typography>

            <TextField
              select
              value={newTask.priority}
              onChange={(e) => {
                // console.log("Selected User ID:", e.target.value);
                setNewTask({ ...newTask, priority: e.target.value });
              }}
              className="mb-4"
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{
                style: { border: "none", padding: "5px", fontSize: "16px" },
              }}
            >
              {priorities.map((priority) => (
                <MenuItem key={priority} value={priority}>
                  {priority}
                </MenuItem>
              ))}
            </TextField>
          </span>
          <span
            style={{ marginBottom: "5px", display: "flex", gap: "1rem", alignItems: "center", border: "1px solid gray", padding: "10px", borderRadius: "5px", backgroundColor: "#f9f9f9", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", }}
          >
            <Typography
              variant="body1"
              style={{ fontWeight: "bold", width: "atuo" }}
            >
              Status :
            </Typography>

            <TextField
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{
                style: { border: "none", padding: "5px", fontSize: "16px" },
              }}
              select
              value={newTask.status}
              onChange={(e) =>
                setNewTask({ ...newTask, status: e.target.value })
              }
            >
              {statuses.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </TextField>
          </span>
          <span style={{ marginBottom: "5px", display: "flex", gap: "1rem", alignItems: "center", border: "1px solid gray", padding: "10px", borderRadius: "5px", backgroundColor: "#f9f9f9", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", }}
          >
            <Typography
              variant="body1"
              style={{ fontWeight: "bold", width: "atuo" }}
            >
              Assign Date :
            </Typography>
            <TextField
              type="date"
              value={newTask.assignDate}
              onChange={(e) =>
                setNewTask({ ...newTask, assignDate: e.target.value })
              }
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{
                style: { border: "none", padding: "5px", fontSize: "16px" },
              }}
            />
          </span>

          <span style={{ marginBottom: "5px", display: "flex", gap: "1rem", alignItems: "center", border: "1px solid gray", padding: "10px", borderRadius: "5px", backgroundColor: "#f9f9f9", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", }}>
            <Typography
              variant="body1"
              style={{ fontWeight: "bold", width: "atuo" }}
            >
              Due Date :
            </Typography>
            <TextField
              type="date"
              value={newTask.dueDate}
              onChange={(e) =>
                setNewTask({ ...newTask, dueDate: e.target.value })
              }
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{
                style: { border: "none", padding: "5px", fontSize: "16px" },
              }}
            />
          </span>

          <span style={{ display: "flex", flexDirection: "column", gap: "0.5rem", marginBottom: "5px", borderRadius: "5px", width: "100%", }} >
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Assign To:
            </Typography>

            <Autocomplete
              options={userId.map((user) => user.name)}
              value={selectedUser}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  value={selectedUser || ""} 
                  placeholder="Select User"
                  InputProps={{
                    ...params.InputProps,
                    value: selectedUser || ""
                  }}
                  style={{ width: "100%" }}
                />
              )}
              onChange={(event, value) => {
                if (value) {
                  handleChipChange(value);
                }
              }}
              getOptionLabel={(option) => option}
              style={{ width: "100%", borderRadius: "5px", }}
            />
            
            <div
              style={{ maxWidth: "550px" }}
              className="flex flex-wrap gap-1 mb-2"
            >
              {newTask.assign.length > 0
                ? newTask.assign.map((name, index) => (
                  <Chip
                    key={index}
                    label={name}
                    onDelete={() => handleChipChange(name)}
                  />
                ))
                : ""}
            </div>
          </span>

          {SprintData && SprintData.length > 0 && (
            <span style={{ display: "flex", flexDirection: "column", gap: "0.5rem", marginBottom: "5px", borderRadius: "5px", width: "100%", }}>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Assign Sprint:
              </Typography>
              <TextField
                select
                value={newTask.sprintname}
                onChange={(e) => setNewTask({ ...newTask, sprintname: e.target.value })}
                className="mb-4"
                variant="standard"
                InputProps={{ disableUnderline: true, }}
                inputProps={{ style: { border: "none", padding: "5px", fontSize: "16px" }, }}
                placeholder="Select Sprint"
                style={{ width: "100%", border: "1px solid gray", padding: "10px", borderRadius: "5px", backgroundColor: "#f9f9f9", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", }}
              >
                {SprintData.map((sprint) => (
                  <MenuItem key={sprint.name} value={sprint.name}>
                    {sprint.name}
                  </MenuItem>
                ))}
              </TextField>
            </span>
          )}

          {SprintData && SprintData.length > 0 && newTask.sprintname && (
            <span style={{ display: "flex", flexDirection: "column", gap: "0.5rem", marginBottom: "5px", borderRadius: "5px", width: "100%", }}>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Select Task:
              </Typography>
              <TextField
                select
                value={newTask.sprintcatname}
                onChange={(e) => setNewTask({ ...newTask, sprintcatname: e.target.value })}
                className="mb-4"
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
                inputProps={{
                  style: { border: "none", padding: "5px", fontSize: "16px" },
                }}
                placeholder="Select Task"
                style={{ width: "100%", border: "1px solid gray", padding: "10px", borderRadius: "5px", backgroundColor: "#f9f9f9", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", }}
              >
                {SprintData.filter((sprint) => sprint.name === newTask.sprintname)
                  .map((sprint) => sprint.tasks)
                  .flat()
                  .map((task) => (
                    <MenuItem key={task._id} value={task.taskName}>
                      {task.taskName}
                    </MenuItem>
                  ))}
              </TextField>
            </span>
          )}



          <span className="flex flex-col gap-2 mb-8 sm:mb-16 md:mb-18">
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>Description:</Typography>
            <ReactQuill
              value={newTask.description}
              onChange={(value) => {
                setNewTask({ ...newTask, description: value });
              }}
              modules={{
                toolbar: [
                  [{ header: [1, 2, false] }],
                  ["bold", "italic", "underline", "strike"],
                  ["link", "image", "blockquote", "code-block"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["clean"],
                ],
              }}
              style={{ height: "150px", borderRadius: "5px", width: "100%", maxWidth: "550px", }}
            />
          </span>

          <Button
            variant="contained"
            onClick={currentTask ? handleUpdateTask : handleAddTask}
            sx={{
              backgroundColor: "#4caf50", textTransform: "capitalize", color: "#fff", borderRadius: "8px", padding: "10px 20px", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", transition: "background-color 0.3s, transform 0.2s",
              "&:hover": {
                backgroundColor: "#388e3c",
                transform: "scale(1.05)",
              },
            }}
            disabled={loading}
            endIcon={
              loading ? (
                <CircularProgress size={20} sx={{ color: "#fff" }} />
              ) : null
            }
          >
            {currentTask ? "Update Task" : "Add Task"}
          </Button>
        </Box>
      </Modal>

      <Dialog
        open={dialogueOpen}
        onClose={handleCloseDialogue}
        maxWidth="sm"
        fullWidth
        PaperProps={{ style: { backgroundColor: 'white', borderRadius: '8px', padding: '24px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', }, }}
      >
        <DialogTitle style={{ fontSize: '1.25rem', fontWeight: '600', textAlign: 'center' }}>
          Select Your Activities
        </DialogTitle>
        <DialogContent style={{ padding: '16px' }} className="flex flex-col space-y-2">
          {checkboxes.map(({ name, label }) => (
            <FormControlLabel
              key={name}
              control={
                <Checkbox
                  checked={checked[name]}
                  onChange={handleCheckboxChange}
                  name={name}
                  sx={{ color: "#9e9e9e", "&.Mui-checked": { color: "#2e7d32", }, }}
                />
              }
              label={label}
              className="flex items-center justify-start"
            />
          ))}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            onClick={handleDialogSubmit}
            variant="contained"
            style={{
              backgroundColor: '#4caf50', textTransform: 'capitalize', color: '#fff', borderRadius: '8px', padding: '10px 20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', transition: "background-color 0.3s, transform 0.2s",
              "&:hover": {
                backgroundColor: "#388e3c", transform: "scale(1.05)",
              },
            }}
            disabled={loading}
            endIcon={loading ? <CircularProgress size={20} style={{ color: '#fff' }} /> : null}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <ToastContainer />
    </Box>
  );
};

export default TableTrello;