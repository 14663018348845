import React, { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Box, Button, CircularProgress, Container, IconButton, TextField, Typography } from '@mui/material';
import { FaDownload } from "react-icons/fa";
import { FaEdit } from 'react-icons/fa';
import { jsPDF } from 'jspdf';
import { VscCloudDownload } from "react-icons/vsc";
import { CiEdit } from "react-icons/ci";
import TurndownService from 'turndown';
import { marked } from 'marked';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from 'react-router-dom';
import { UpdDetailsProjectDoc } from '../../api/appApi';
import { useDispatch } from 'react-redux';


// pdfMake.vfs = pdfFonts.pdfMake.vfs; 

const DetailedSpecs = ({ onNext, onBack, data, projectId }) => {

    // console.log('data is DetailedSpecs : ', data);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [inputData, setInputData] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingfrSave, setLoadingfrSave] = useState(false);
    const [loadingfrSavePdf, setLoadingfrSavePdf] = useState(false);
    // const editorRef = useRef(null);
    const [isEditorReady, setIsEditorReady] = useState(false);


    const handleChange = (value) => {
        setInputData(value);
    };

    const handleNext = () => {


        const turndownService = new TurndownService();
        const convertedMarkdown = turndownService.turndown(inputData);

        // const turndownService = new TurndownService();

        // if (isEditing) {
        //     const convertedMarkdown = turndownService.turndown(inputData);
        //     setInputData(convertedMarkdown);
        // } else {
        //     const convertedHtml = marked(inputData);
        //     setInputData(convertedHtml);
        // }

        // const markdownData = turndownService.turndown(inputData);
        // console.log(`Converted Markdown: ${markdownData}`);
        onNext({ detailedSpecs: convertedMarkdown });
    };


    const handleSave = async() => {
        const turndownService = new TurndownService();
        const convertedMarkdown = turndownService.turndown(inputData);

        // console.log(convertedMarkdown);

        if (convertedMarkdown == "") {
            return;
        }

        const UpdDataDoc = {
            "updatedResponse": {
                "detailed_requirements": convertedMarkdown
            },
            "step": 1
        }

        try {
            setLoadingfrSave(true);
            const response = await UpdDetailsProjectDoc(projectId, UpdDataDoc, dispatch)
            console.log(response.data);
            onNext({ detailedSpecs: convertedMarkdown });
            navigate('/project-dashboard');

        } catch (error) {
            console.error('Error in PDF generation:', error);
            alert('An error occurred while generating the PDF. Please try again.');
        } finally {
            setLoadingfrSave(false);
        }

        // onNext({ detailedSpecs: convertedMarkdown });
        // navigate('/project-dashboard');
    };

    const toggleEdit = () => {
        const turndownService = new TurndownService();

        if (isEditing) {
            const convertedMarkdown = turndownService.turndown(inputData);
            setInputData(convertedMarkdown);
        } else {
            const convertedHtml = marked(inputData);
            setInputData(convertedHtml);
        }

        setIsEditing((prev) => !prev);
    };

    const downloadPDF = async () => {

        console.log(`downloadPDF button clicked`);
        
        setLoadingfrSavePdf(true);

        try {
            const requestData = {
                html: inputData,
                filename: 'project_documentation.pdf',
            };

            // const element = document.createElement('div');
            // element.innerHTML = requestData.inputData; // Set the input HTML content to the created div element

            // // Use html2pdf.js to download the PDF
            // html2pdf()
            //     .from(element)
            //     .save("project_documentation.pdf")
            //     .then(() => {
            //         setLoadingfrSavePdf(false);
            //         alert("PDF generated and downloaded successfully!");
            //     })
            //     .catch((error) => {
            //         setLoadingfrSavePdf(false);
            //         console.error("Error generating PDF:", error);
            //         alert("Failed to generate PDF.");
            //     });

            // const response = await axios.post(
            //     'https://dqv33xacf7.ap-south-1.awsapprunner.com/html_to_pdf',
            //     requestData,
            //     {
            //         headers: {
            //             'Accept': 'application/json, text/plain, */*',
            //             'Content-Type': 'application/json',
            //         },
            //     }
            // );

            // if (response.status === 200 && response.data && response.data.pdf_url) {
            //     const pdfUrl = response.data.pdf_url;
            //     window.open(pdfUrl, '_blank');
            //     // saveAs(pdfUrl, 'project_documentation.pdf');

            // } else {
            //     throw new Error('No valid PDF URL returned.');
            // }


        } catch (error) {
            console.error('Error in PDF generation:', error);
        } finally {
            setLoadingfrSavePdf(false);
        }
    };

    // const downloadPDF = () => {
    //     try {
    //         // Convert markdown to HTML using the marked library
    //         const htmlContent = marked(inputData);

    //         if (!htmlContent) throw new Error('Failed to convert markdown to HTML.');

    //         const turndownService = new TurndownService();
    //         const convertedMarkdown = turndownService.turndown(inputData);

    //         console.log(`convertedMarkdown is : ${convertedMarkdown}`);

    //         // Function to simulate Markdown formatting into plain text

    //         // Function to simulate Markdown formatting into plain text
    //         const formatMarkdownForPlainText = (markdown) => {
    //             let formattedText = markdown;

    //             // Handle headers (e.g., # Header => *Header*)
    //             formattedText = formattedText.replace(/^# (.*)/gm, '\n\n* $1'); // H1 headers
    //             formattedText = formattedText.replace(/^## (.*)/gm, '\n\n**$1**'); // H2 headers
    //             formattedText = formattedText.replace(/^### (.*)/gm, '\n\n***$1***'); // H3 headers

    //             // Handle bold text (e.g., **bold** => *bold*)
    //             formattedText = formattedText.replace(/\*\*(.*?)\*\*/g, '**$1**');

    //             // Handle italic text (e.g., *italic* => _italic_)
    //             formattedText = formattedText.replace(/\*(.*?)\*/g, '_$1_');

    //             // Handle unordered lists (e.g., - item => * item)
    //             formattedText = formattedText.replace(/^\s*-\s+(.*)/gm, '* $1');

    //             // Handle ordered lists (e.g., 1. item => 1. item)
    //             formattedText = formattedText.replace(/^\s*\d+\.\s+(.*)/gm, '1. $1');

    //             // Handle blockquotes (e.g., > quote => > quote)
    //             formattedText = formattedText.replace(/^> (.*)/gm, '> $1');

    //             // Handle code blocks (e.g., ```code block => <code>code block</code>)
    //             formattedText = formattedText.replace(/```([\s\S]*?)```/gm, '<code>$1</code>');

    //             // Clean up multiple line breaks (to avoid too much space)
    //             formattedText = formattedText.replace(/\n\s*\n/g, '\n');

    //             return formattedText;
    //         };

    //         const formattedMarkdown = formatMarkdownForPlainText(convertedMarkdown);

    //         console.log(`formattedMarkdown is: ${formattedMarkdown}`);


    //         const docDefinition = {
    //             content: [
    //                 {
    //                     text: 'Detailed Project Documentation',
    //                     style: 'header',
    //                 },
    //                 {
    //                     text: formattedMarkdown,
    //                     style: 'content',
    //                 },
    //             ],
    //             styles: {
    //                 header: {
    //                     fontSize: 18,
    //                     bold: true,
    //                     margin: [0, 0, 0, 10],
    //                     color: '#2e7d32',
    //                 },
    //                 content: {
    //                     fontSize: 12,
    //                     lineHeight: 1.5,
    //                     color: '#000000',
    //                 },
    //             },
    //         };

    //         // Generate and download the PDF
    //         pdfMake.createPdf(docDefinition).download('project_documentation.pdf');
    //     } catch (error) {
    //         console.error('Error in PDF generation:', error);
    //         alert('An error occurred while generating the PDF.');
    //     }
    // };




    // const downloadPDF = () => {
    //     const doc = new jsPDF();

    //     doc.setFontSize(10);
    //     const htmlContent = marked(inputData);
    //     doc.html(htmlContent, {
    //         callback: function (doc) {
    //             doc.save('detailed_specs.pdf');
    //         },
    //         margin: [15, 15, 15, 15],
    //         html2canvas: {
    //             scale: 3,
    //             useCORS: true,
    //             letterRendering: true,
    //         },
    //         autoPaging: true,
    //         x: 15,
    //         y: 15,
    //     });
    // };

    useEffect(() => {
        if (data !== '') {

            const convertedHtml = marked(data);
            // console.log(`convertedHtml Text is : ${convertedHtml}`);
            setInputData(convertedHtml);

            // const turndownService = new TurndownService();
            // const convertedMarkdown = turndownService.turndown(data);
            // console.log(`convertedMarkdown Text is : ${convertedMarkdown}`);
            // setInputData(convertedMarkdown);

        }
        // console.log(data)
    }, [data]);




    return (
        <>
            <Box sx={{
                width: '100%',
                height: 'auto',
                paddingLeft: { xs: 2, sm: 4, md: 6 },
                paddingRight: { xs: 2, sm: 4, md: 6 },
                paddingTop: '5px',
            }}>


                <Typography variant="h5" sx={{ marginBottom: 3, marginTop: 3 }}>Detailed Project Documentation</Typography>


                <Container className="React-Quill-textArea" >

                    <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center', marginBottom: 2 }}>
                        <div><Typography variant="h5" sx={{}}>Update Project Specifications</Typography></div>
                        <div>
                            {/* <IconButton onClick={toggleEdit} sx={{ color: '#2e7d32' }}>
                                <CiEdit />
                            </IconButton> */}

                            <Button
                                onClick={downloadPDF}
                                variant="contained"
                                style={{
                                    backgroundColor: '#4caf50', textTransform: 'capitalize', color: '#fff', borderRadius: '8px', padding: '10px 20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', transition: "background-color 0.3s, transform 0.2s",
                                    "&:hover": { backgroundColor: "#388e3c", transform: "scale(1.05)", },
                                }}
                                disabled={loadingfrSavePdf}
                                endIcon={loadingfrSavePdf ? <CircularProgress size={20} style={{ color: '#fff' }} /> : null}
                                startIcon={<VscCloudDownload />}
                            >
                                Download pdf
                            </Button>

                            {/* <IconButton onClick={downloadPDF} sx={{ color: '#2e7d32' }}>
                                Download pdf
                                <VscCloudDownload />
                            </IconButton> */}
                        </div>
                    </Box>

                    <ReactQuill
                        value={inputData}
                        onChange={handleChange}
                        // theme='snow'
                        // ref={editorRef}
                        modules={{
                            toolbar: [
                                [{ 'header': '1' }, { 'header': '2' }],
                                [{ 'font': [] }, { 'size': ['small', false, 'large', 'huge'] }],
                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                [{ 'indent': '-1' }, { 'indent': '+1' }],
                                ['bold', 'italic', 'underline', 'strike'],
                                [{ 'align': [] }],
                                [{ 'color': [] }, { 'background': [] }],
                                ['link', 'image', 'video'],
                                ['blockquote'],
                                ['code-block'],
                                ['clean'],
                                [{ 'direction': 'rtl' }],
                            ],
                        }}
                    />
                </Container>

                {/* {isEditing ? (
                    <>

                        <Container>

                            <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center', marginBottom: 2 }}>
                                <div><Typography variant="h5" sx={{}}>Update Project Specifications</Typography></div>
                                <div>
                                    <IconButton onClick={toggleEdit} sx={{ color: '#2e7d32' }}>
                                        <CiEdit />
                                    </IconButton>
                                    <IconButton onClick={downloadPDF} sx={{ color: '#2e7d32' }}>
                                        <VscCloudDownload />
                                    </IconButton>
                                </div>
                            </Box>

                            <ReactQuill
                                value={inputData}
                                onChange={handleChange}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }],
                                        [{ 'font': [] }, { 'size': ['small', false, 'large', 'huge'] }],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                        [{ 'indent': '-1' }, { 'indent': '+1' }],
                                        ['bold', 'italic', 'underline', 'strike'],
                                        [{ 'align': [] }],
                                        [{ 'color': [] }, { 'background': [] }],
                                        ['link', 'image', 'video'],
                                        ['blockquote'],
                                        ['code-block'],
                                        ['clean'],
                                        [{ 'direction': 'rtl' }],
                                    ],
                                }}
                            />
                        </Container>
                    </>
                ) : (
                    <Box sx={{
                        overflowY: 'auto',
                        padding: '10px',
                        borderRadius: '8px',
                        backgroundColor: '#fff',
                        marginBottom: 3,
                        textAlign: 'left'
                    }}>

                        <Container>

                        <Box sx={{ display: 'flex', gap:'0.5rem', alignItems: 'center',marginBottom:2 }}>
                            <div><Typography variant="h5" sx={{ textAlign: 'center' }}>Project Specifications Overview</Typography></div>
                            <div>
                                <IconButton onClick={toggleEdit} sx={{ color: '#2e7d32' }}>
                                    <CiEdit />
                                </IconButton>
                                <IconButton onClick={downloadPDF} sx={{ color: '#2e7d32' }}>
                                    <VscCloudDownload />
                                </IconButton>
                            </div>
                        </Box>
                        <ReactMarkdown>{inputData}</ReactMarkdown>
                        </Container>
                    </Box>
                )} */}




            </Box>

            <Box sx={{
                mt: 2,
                paddingLeft: { xs: 2, sm: 4, md: 6 },
                paddingRight: { xs: 2, sm: 4, md: 6 },
                textAlign: { xs: 'center', sm: 'right' },
            }}>

                <Button
                    onClick={handleSave}
                    variant="contained"
                    style={{
                        backgroundColor: '#4caf50', textTransform: 'capitalize', color: '#fff', borderRadius: '8px', padding: '10px 20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', transition: "background-color 0.3s, transform 0.2s",
                        "&:hover": { backgroundColor: "#388e3c", transform: "scale(1.05)", }, marginRight: '3px'
                    }}
                    disabled={loadingfrSave}
                    endIcon={loadingfrSave ? <CircularProgress size={20} style={{ color: '#fff' }} /> : null}
                >
                    Save & Close
                </Button>
            </Box>

            <Box sx={{
                mt: 2,
                paddingLeft: { xs: 2, sm: 4, md: 6 },
                paddingRight: { xs: 2, sm: 4, md: 6 },
                textAlign: { xs: 'center', sm: 'right' },
            }}>

                <Button
                    onClick={onBack}
                    variant="contained"
                    style={{
                        backgroundColor: '#4caf50', textTransform: 'capitalize', color: '#fff', borderRadius: '8px', padding: '10px 20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', transition: "background-color 0.3s, transform 0.2s", marginRight: '0.7rem',
                        "&:hover": { backgroundColor: "#388e3c", transform: "scale(1.05)", },
                    }}
                    disabled={loading}
                // endIcon={loading ? <CircularProgress size={20} style={{ color: '#fff' }} /> : null}
                >
                    Back
                </Button>


                <Button
                    onClick={handleNext}
                    variant="contained"
                    style={{
                        backgroundColor: '#4caf50', textTransform: 'capitalize', color: '#fff', borderRadius: '8px', padding: '10px 20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', transition: "background-color 0.3s, transform 0.2s",
                        "&:hover": { backgroundColor: "#388e3c", transform: "scale(1.05)", },
                    }}
                    disabled={loading}
                    endIcon={loading ? <CircularProgress size={20} style={{ color: '#fff' }} /> : null}
                >
                    Next
                </Button>
            </Box>
        </>
    );
};

export default DetailedSpecs;