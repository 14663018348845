import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "../redux/reduxslices/userSlices";
import { clearProjectId } from "../redux/reduxslices/ProjectIdSlice";
import { Navigate } from "react-router-dom";

const apiClient = axios.create({
  // baseURL: "http://localhost:4000/api",
    // baseURL:"https://us-central1-islam360-stagging.cloudfunctions.net/taskmanagerserver/api",
    baseURL: "https://us-central1-sampleprojectforusman.cloudfunctions.net/taskmanagerserver/api",

});






// Utility function to get authorization headers
const getAuthHeaders = () => {
  const token = localStorage.getItem("token") || "";
  return {
    Authorization: `Bearer ${token}`,
  };
};

// Function to handle invalid token errors
const handleInvalidTokenError = (error) => {
  // const dispatch = useDispatch();
  // Check if the error indicates an invalid token
  if (error.response && error.response.data.message === "Invalid token") {
    //   console.alert("Invalid token. Please log in again.");
    console.error("Invalid token. Please log in again.");
    //   dispatch(signOut({ user: null, token: null }));
    //   dispatch(clearProjectId());
    //   navigate("/signout");
    //   dispatch(signOut({ user: null, token: null }));
    // dispatch(clearProjectId());
    // Navigate('/signout');
    // Add any additional logic, like redirecting to a login page or refreshing the token
  } else {
    console.error("Error:", error);
  }
};

// export const baytPostingCall=async(payload)=>{
//    const data=await axiosInstance.post('/api/bayt/EmployerJobs/',payload).catch((e)=>{
//     console.log(e);
//   })
//    return data
// }

// Function to fetch projects
export const fetchProjectsData = async () => {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  const response = await apiClient
    .get("/projects", {
      headers: getAuthHeaders(),
    })
    // .catch((e) => {
    //   console.log("Error fetching projects:", e);
    //   handleInvalidTokenError(e); // Handle invalid token error
    // });

  return response;
};

// // Function to fetch projects
// export const fetchProjectsData = async () => {
//   const response = await apiClient.get("/projects");
//   return response; // Adjust based on your API response structure
// };

// Function to fetch users
export const fetchUsersData = async () => {
  const response = await apiClient.get("/users", {
    headers: getAuthHeaders(),
  });
  return response; // Adjust based on your API response structure
};

// Function to create a new project
export const createNewProject = async (newProject) => {
  console.log("Project Details are:", newProject);
  const response = await apiClient.post("/projects", newProject, {
    headers: getAuthHeaders(),
  });
  console.log("Project created:", response.data);
  return response; // Return the created project data if needed
};

// Function to update a project
export const updateProject = async (projectId, updatedProject) => {
  console.log("Updating project ID:", projectId);
  const response = await apiClient.put(
    `/projects/${projectId}`,
    updatedProject,
    {
      headers: getAuthHeaders(),
    }
  );
  console.log("Project updated:", response);
  return response; // Return the updated project data if needed
};

// Function to fetch a project by ID
export const GetfetchProjectById = async (projectId) => {
  const response = await apiClient.get(`/projects/${projectId}`, {
    headers: getAuthHeaders(),
  });
  return response; // Adjust based on your API response structure
};

// Function to add a new task
export const addTask = async (newTask) => {
  console.log("New Task:", newTask);
  const response = await apiClient.post("/tasks", newTask, {
    headers: getAuthHeaders(),
  });
  const createdTask = response; // Adjust based on your API response structure
  console.log("Task created:", createdTask);
  return createdTask; // Return the created task data if needed
};

// Function to update a task
export const updateTask = async (taskId, taskData) => {
  const response = await apiClient.put(`/tasks/${taskId}`, taskData, {
    headers: getAuthHeaders(),
  });
  const updatedTask = response; // Adjust based on your API response structure
  // console.log("Task updated:", updatedTask);
  return updatedTask; // Return the updated task data if needed
};

// Function to fetch a project by ID
export const getactivities = async (projectactivity) => {
  const response = await apiClient.get(`/activities/${projectactivity}`, {
    headers: getAuthHeaders(),
  });
  return response; 
};


// Function to add a new task
export const AddSprint = async (newTask) => {
  // console.log("New Task:", newTask);
  const response = await apiClient.post("/sprints", newTask, {
    headers: getAuthHeaders(),
  });
  const createdSprints = response; // Adjust based on your API response structure
  console.log("Sprints Created:", createdSprints);
  return createdSprints; // Return the created task data if needed
};


// Function to fetch a project by ID
export const getSprints = async (projectactivity) => {
  const response = await apiClient.get(`/sprints/get-sprint/${projectactivity}`, {
    headers: getAuthHeaders(),
  });
  return response; 
};


// http://localhost:4000/api/sprints/get-sprint/67124493751249612bf37d4f