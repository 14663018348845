import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { Navbar } from './components/Navbar';
import GithubChatbot from './components/GithubChatbot/GithubChatbot';
import { ProjectList } from './components/ProjectList/ProjectList';
import UpdProjectId from './components/Dasboard/UpdProjectId';
import Signup from './components/authRoutes/Signup';
import SignIn from './components/authRoutes/SignIn';
import PrivateRoute from './components/PrivteRoutes/PrivateRoutes';
import RedirectRoute from './components/PrivteRoutes/RedirectRoute';
import { useDispatch, useSelector } from 'react-redux';
import SalarySlip from './components/SalarySlip/SalarySlip';
import Attendance from './components/Attendance/Attendance';
import TabsComponent from './components/TabsComp';
import MainLayout from './components/Main/MainLayout';
import MainComponent from './components/MainComponent';
import InitProject from './components/ProJects/InitProject';
import { useEffect, useState } from 'react';
import UploadAttendanceSheet from './components/Attendance/UploadAttendanceSheet';
import ForgotPassword from './components/authRoutes/ForgotPassword';
import ResetPassword from './components/authRoutes/ResetPassword';
import InitProjectLists from './components/ProJects/InitProjectLists';
import { setProjectId } from './redux/reduxslices/ProjectIdSlice';


function App() {

  const dispatch = useDispatch();

  const token = useSelector((state) => state.user.token);
  const projectId = useSelector((state) => state.projectId.projectId);
  const [activeProject, setActiveProject] = useState(null);

  const handleProjectSelect = (projectId) => {
    setActiveProject(projectId);
    dispatch(setProjectId(projectId));
  };

  useEffect(() => {
    setActiveProject(projectId);
    // console.log(projectId);
  }, [projectId]);

  return (
    <>
      <Router>

        {!token && <Navbar />}
        <Routes>
          {!token ? (
            <>
              <Route path="/signin" element={<SignIn />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
            </>
          ) : (

            <Route
              path="/"
              element={
                <>
                  <PrivateRoute element={<MainLayout>
                    {/* <TabsComponent updId={activeProject} /> */}
                    {
                      (projectId && projectId !== null && projectId !== "") ? <TabsComponent updId={activeProject} /> : <ProjectList onSelectProject={handleProjectSelect} />
                    }

                  </MainLayout>} />
                </>
              }
            />



          )}

          {/* <Route
            path="/"
            element={<PrivateRoute element={<MainComponent />} />}
          /> */}

          {/* <Route
            path="/dashboard"
            element={<PrivateRoute element={<TabsComponent />} />}
          /> */}

          <Route
            path="/create-new-project/"
            // path="/create-new-project"
            element={
              <>
                <PrivateRoute element={<MainLayout>
                  <InitProject />
                </MainLayout>} />
              </>
            }
          />

          <Route
            path="/edit-project/:projectId"
            // path="/create-new-project"
            element={
              <>
                <PrivateRoute element={<MainLayout>
                  <InitProject />
                </MainLayout>} />
              </>
            }
          />

          <Route
            path="/upload-attendance"
            element={
              <>
                <PrivateRoute element={<MainLayout>
                  <UploadAttendanceSheet />
                </MainLayout>} />
              </>
            }
          />

          <Route
            path="/project-dashboard"
            element={
              <>
                <PrivateRoute element={<MainLayout>
                  <InitProjectLists />
                </MainLayout>} />
              </>
            }
          />

          <Route
            path="/salary-slip"
            element={
              <>
                <PrivateRoute element={<MainLayout>
                  <SalarySlip />
                </MainLayout>} />
              </>
            }
          />

          <Route
            path="/attendance-sheet"
            element={
              <>
                <PrivateRoute element={<MainLayout>
                  <Attendance />
                </MainLayout>} />
              </>
            }
          />

          <Route
            path="/track-record"
            element={
              <>
                <PrivateRoute element={<MainLayout>
                  <GithubChatbot />
                </MainLayout>} />
              </>
            }
          />
          {/* <Route
            path="salary-slip"
            element={<PrivateRoute element={<SalarySlip />} />}
          />

          <Route
            path="attendance-sheet"
            element={<PrivateRoute element={<Attendance />} />}
          />

          <Route
            path="/track-record"
            element={<PrivateRoute element={<GithubChatbot />} />}
          /> */}
          <Route path="*" element={<RedirectRoute />} />
        </Routes>
      </Router>


    </>
  );
}



export default App;










































// import { BrowserRouter as Router, Route, Routes, useNavigate, Navigate } from 'react-router-dom';
// import './App.css';
// import { Navbar } from './components/Navbar';
// import { HomeContent } from './components/HomeContent';
// import TabsComponent from './components/TabsComp';
// import GithubChatbot from './components/GithubChatbot/GithubChatbot';
// import { ProjectList } from './components/ProjectList/ProjectList';
// import UpdProjectId from './components/Dasboard/UpdProjectId';
// import Signup from './components/authRoutes/Signup';
// import SignIn from './components/authRoutes/SignIn';
// import PrivateRoute from './components/PrivteRoutes/PrivateRoutes';
// import RedirectRoute from './components/PrivteRoutes/RedirectRoute';
// import MainComponent from './components/MainComponent';
// import { useSelector } from 'react-redux';
// import SalarySlip from './components/SalarySlip/SalarySlip';
// import Attendance from './components/Attendance/Attendance';


// function App() {

//   const token = useSelector((state) => state.user.token);


//   return (
//     <>
//       <Router>
//         {/* <MainComponent /> */}
//         {!token && <Navbar />}
//         <Routes>
//           {!token ? (
//             <>
//               <Route path="/signin" element={<SignIn />} />
//               <Route path="/signup" element={<Signup />} />
//             </>
//           ) : (

//             <Route
//               path="/"
//               element={<PrivateRoute element={<MainComponent />} />}
//             />
//           )}
//           {/* <Route
//             path="/"
//             element={
//               <PrivateRoute element={<UpdProjectId initialShowTabs={false} />} />

//         salary-slip

//         attendance
//             }
//           /> */}
//           <Route
//             path="/"
//             element={<PrivateRoute element={<MainComponent />} />}
//           />
//           {/* <Route
//             path="/dashboard"
//             element={<PrivateRoute element={<TabsComponent />} />}
//           /> */}


//           <Route
//             path="salary-slip"
//             element={<PrivateRoute element={<SalarySlip />} />}
//           />

//           <Route
//             path="attendance-sheet"
//             element={<PrivateRoute element={<Attendance />} />}
//           />



//           <Route
//             path="/track-record"
//             element={<PrivateRoute element={<GithubChatbot />} />}
//           />
//           <Route path="*" element={<RedirectRoute />} />
//           {/* <Route path="*" element={<Navigate to="/signin" />} /> */}
//         </Routes>
//       </Router>

//       {/* <Router>
//         <Navbar />
//         <Routes>
//           <Route path="/" element={<UpdProjectId initialShowTabs={false} />} />
//           <Route path="/signin" element={<SignIn />} />
//           <Route path="/signup" element={<Signup />} />
//           // <Route path="/dashboard" element={<TabsComponent />} />
//           <Route path="/track-record" element={<GithubChatbot />} />
//         </Routes>
//       </Router> */}

//       {/* <Navbar /> */}
//       {/* <HomeContent />
//       <TabsComponent /> */}
//     </>
//   );
// }

// export default App;