import React, { useState, useEffect } from 'react';
import { Box, Button, IconButton, Typography, Modal, ImageList, ImageListItem, CircularProgress } from '@mui/material';
import { FaTimes } from 'react-icons/fa';
import TurndownService from 'turndown';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { UpdFigmaDesign } from '../../api/appApi';


const FigmaDesigns = ({ onNext, onBack, data, projectId }) => {

    // console.log(`Figma Designs are ${data}`);
    const dispatch= useDispatch();
    const navigate = useNavigate();

    const [figmaDesigns, setFigmaDesigns] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingfrSave, setLoadingfrSave] = useState(false);

    useEffect(() => {
        if (data) {
            // console.log(`Figma Designs are ${data}`);
            const imageArray = Object.values(data);
            setFigmaDesigns(imageArray);
        }
    }, [data]);

    const handleImageClick = (image) => {
        setSelectedImage(image);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedImage(null);
    };

    const handleRemoveImage = (index) => {
        const updatedImages = figmaDesigns.filter((_, i) => i !== index);
        setFigmaDesigns(updatedImages);
    };


    const handleSave = async () => {
      
      
        const UpdDataDoc = {
            "message": "Step updated successfully.",
            "step": 4
        }

        try {
            setLoadingfrSave(true);
            const response = await UpdFigmaDesign(projectId, UpdDataDoc, dispatch)
            console.log("From Figma Screen :", response.data);
            onNext({ figmaDesign: figmaDesigns });
            navigate('/project-dashboard');

        } catch (error) {
            console.error('Error in PDF generation:', error);
            alert('An error occurred while generating the PDF. Please try again.');
        } finally {
            setLoadingfrSave(false);
        }

        // onNext({ detailedSpecs: convertedMarkdown });
        // navigate('/project-dashboard');
    };

    const handleNext = () => {
        onNext({ figmaDesigns: figmaDesigns });
        // navigate('/project-dashboard');
    };

    const handleBack = () => {
        onBack();
    };



    return (
        <Box sx={{ padding: { xs: 2, sm: 4, md: 6 }, paddingTop: '5px' }}>

            <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#2c3e50', marginBottom: 3, textAlign: 'center' }}>
                Figma Design Images
            </Typography>


            {figmaDesigns && figmaDesigns.length > 0 ? (


                <ImageList sx={{ width: '100%', height: 'auto' }} variant="woven" cols={3} gap={8}>
                    {figmaDesigns.map((image, index) => (
                        <ImageListItem key={index}>
                            <img
                                src={`data:image/png;base64,${image}`}
                                alt={`Figma Design ${index}`}
                                loading="lazy"
                                onClick={() => handleImageClick(image)}
                                className="cursor-pointer object-cover rounded-md shadow-md transition-all duration-300 "
                            />
                            <IconButton
                                onClick={() => handleRemoveImage(index)}
                                sx={{
                                    position: 'absolute',
                                    top: '0px',
                                    right: '0px',
                                    // backgroundColor: '#fff',
                                    // borderRadius: '50%',
                                    // boxShadow: 2,
                                    // '&:hover': { backgroundColor: '#f44336',color:'#fff' },
                                }}
                            >
                                <FaTimes color="#f44336" size={16} />
                            </IconButton>
                        </ImageListItem>
                    ))}
                </ImageList>

            ) : (
                <Box sx={{ textAlign: 'center', marginTop: 4 }}>
                    <Typography variant="h6" color="textSecondary">
                        No Figma Designs Found
                    </Typography>
                </Box>
            )}


<Box sx={{
                mt: 2,
                paddingLeft: { xs: 2, sm: 4, md: 6 },
                paddingRight: { xs: 2, sm: 4, md: 6 },
                textAlign: { xs: 'center', sm: 'right' },
            }}>

                <Button
                    onClick={handleSave}
                    variant="contained"
                    style={{
                        backgroundColor: '#4caf50', textTransform: 'capitalize', color: '#fff', borderRadius: '8px', padding: '10px 20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', transition: "background-color 0.3s, transform 0.2s",
                        "&:hover": { backgroundColor: "#388e3c", transform: "scale(1.05)", }, marginRight: '3px'
                    }}
                    disabled={loadingfrSave}
                    endIcon={loadingfrSave ? <CircularProgress size={20} style={{ color: '#fff' }} /> : null}
                >
                    Save & Close
                </Button>
            </Box>


            <Box sx={{
                mt: 2,
                paddingLeft: { xs: 2, sm: 4, md: 6 },
                paddingRight: { xs: 2, sm: 4, md: 6 },
                textAlign: { xs: 'center', sm: 'right' },
            }}>

                <Button
                    onClick={onBack}
                    variant="contained"
                    style={{
                        backgroundColor: '#4caf50', textTransform: 'capitalize', color: '#fff', borderRadius: '8px', padding: '10px 20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', transition: "background-color 0.3s, transform 0.2s", marginRight: '0.7rem',
                        "&:hover": { backgroundColor: "#388e3c", transform: "scale(1.05)", },
                    }}
                    disabled={loading}
                // endIcon={loading ? <CircularProgress size={20} style={{ color: '#fff' }} /> : null}
                >
                    Back
                </Button>


                <Button
                    onClick={handleNext}
                    variant="contained"
                    style={{
                        backgroundColor: '#4caf50', textTransform: 'capitalize', color: '#fff', borderRadius: '8px', padding: '10px 20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', transition: "background-color 0.3s, transform 0.2s",
                        "&:hover": { backgroundColor: "#388e3c", transform: "scale(1.05)", },
                    }}
                    disabled={loading}
                    endIcon={loading ? <CircularProgress size={20} style={{ color: '#fff' }} /> : null}
                >
                    Next
                </Button>
            </Box>

            {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 3 }}>
                <Button
                    onClick={handleBack}
                    sx={{
                        backgroundColor: '#2e7d32',
                        color: '#fff',
                        fontWeight: 'bold',
                        textTransform: 'capitalize',
                        padding: '6px 12px',
                        borderRadius: '4px',
                        '&:hover': {
                            backgroundColor: '#388e3c',
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                        },
                    }}
                >
                    Back
                </Button>
                <Button
                    onClick={handleNext}
                    sx={{
                        backgroundColor: '#2e7d32',
                        color: '#fff',
                        fontWeight: 'bold',
                        textTransform: 'capitalize',
                        padding: '6px 12px',
                        borderRadius: '4px',
                        '&:hover': {
                            backgroundColor: '#388e3c',
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                        },
                    }}
                >
                    Next
                </Button>
            </Box> */}

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Box
                    sx={{
                        position: 'relative',
                        width: '100%',
                        maxWidth: '800px',
                        height: 'auto',
                        backgroundColor: '#fff',
                        borderRadius: '8px',
                        boxShadow: 3,
                        padding: 2,
                    }}
                >
                    <IconButton
                        onClick={handleCloseModal}
                        sx={{
                            position: 'absolute',
                            top: '0px',
                            right: '0px',
                            //   backgroundColor: '#fff',
                            //   borderRadius: '50%',
                            //   boxShadow: 2,
                            //     '&:hover': { backgroundColor: '#fff', color: '#fff' },
                        }}
                    >
                        <FaTimes color="#f44336" size={18} />
                    </IconButton>
                    <img
                        src={`data:image/png;base64,${selectedImage}`}
                        alt="Selected Figma Design"
                        style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
                    />
                </Box>
            </Modal>
        </Box>
    );
};

export default FigmaDesigns;