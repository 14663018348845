import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { FaUpload, FaCheckCircle } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import { MdOutlineCloudUpload } from "react-icons/md";
import { SiTicktick } from "react-icons/si";

const UploadAttendanceSheet = () => {
    const [file, setFile] = useState(null);

    const handleFileUpload = (acceptedFiles) => {
        const uploadedFile = acceptedFiles[0];
        if (uploadedFile?.type === 'text/csv') {
            setFile(uploadedFile);
        } else {
            toast.error("Please upload a valid CSV file.", {
                position: "top-right",
                autoClose: 1000,
                toastId: "csv-format-error",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: '.csv',
        onDrop: handleFileUpload,
    });

    const handleSubmit = () => {
        if (!file) {
            toast.error("No file selected. Please upload a CSV file.", {
                position: "top-right",
                autoClose: 1000,
                toastId: "file-not-found",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            // toast.error('No file selected. Please upload a CSV file.');
            return;
        }

        // toast.success(`File "${file.name}" uploaded successfully!`);
        toast.success(`File "${file.name}" uploaded successfully!`, {
            position: "top-right",
            autoClose: 1000,
            toastId: "file-uploaded-successfully",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
        
        console.log('Uploading file:', file);
    };

    return (
        <Box className="flex justify-center items-center min-h-[85vh] p-4">
            <Box className="bg-white p-8 rounded-lg shadow-2xl w-full max-w-lg">
                <Typography variant="h6" marginBottom={3} className="text-center mb-6 font-semibold text-gray-700">
                    Upload Attendance Sheet (CSV)
                </Typography>

                <div
                    {...getRootProps()}
                    className="flex flex-col justify-center items-center p-12 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:bg-gray-50 transition-all"
                >
                    <input {...getInputProps()} />

                    {file ? (
                        <div className="flex flex-col items-center space-y-4">
                            <SiTicktick className="text-green-500 text-6xl" />
                            <Typography variant="body1" className="text-center text-green-500 text-lg">
                                File: {file.name}
                            </Typography>
                        </div>
                    ) : (
                        <div className="flex flex-col items-center space-y-4">
                            <MdOutlineCloudUpload className="text-gray-500 text-6xl" />
                            <Typography variant="body1" className="text-center text-gray-500 text-lg">
                                Drag & Drop or Click to Upload a CSV File
                            </Typography>

                         
                        </div>
                    )}
                </div>



                <Box className="mt-6 flex justify-center">
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#4caf50',
                            textTransform: 'capitalize',
                            color: '#fff',
                            borderRadius: '8px',
                            padding: '12px 24px',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            transition: 'background-color 0.3s, transform 0.2s',
                            '&:hover': {
                                backgroundColor: '#388e3c',
                                transform: 'scale(1.05)',
                            },
                            '&:disabled': {
                                backgroundColor: '#d0e4d5',
                                boxShadow: 'none',
                            },
                        }}
                        startIcon={<FaUpload />}
                        onClick={handleSubmit}
                        disabled={!file}
                    >
                        Upload File
                    </Button>
                </Box>
            </Box>

            <ToastContainer />
        </Box>
    );
};

export default UploadAttendanceSheet;







// import React from 'react'

// const UploadAttendanceSheet = () => {
//   return (
//     <div>UploadAttendanceSheet</div>
//   )
// }

// export default UploadAttendanceSheet