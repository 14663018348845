import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Box, Button, CircularProgress, Container, IconButton, TextField, Typography } from '@mui/material';
import { FaDownload } from "react-icons/fa";
import { FaEdit } from 'react-icons/fa';
import { jsPDF } from 'jspdf';
import { VscCloudDownload } from "react-icons/vsc";
import { CiEdit } from "react-icons/ci";
import TurndownService from 'turndown';
import { marked } from 'marked';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { generateFigmaDesign, UpdWorkFlowBreakdown } from '../../api/appApi';
import { useNavigate } from 'react-router-dom';


const WorkflowBreakdown = ({ onNext, onBack, data, projectId }) => {

    // console.log('data is : ', data);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [inputData, setInputData] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingfrSave, setLoadingfrSave] = useState(false);

    const handleChange = (value) => {
        setInputData(value);
    };

    const handleNext = async () => {
        const turndownService = new TurndownService();
        const markdownData = turndownService.turndown(inputData);

        // generateFigmaDesign
        const PostFigmaDesign = {
            projectId: projectId,
            breakdown: markdownData
        }

        if (markdownData.trim() === '') {

            toast.info("Please Fillout the Field First!", {
                position: "top-right",
                autoClose: 1000,
                toastId: "incomplete-fields",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });

            return;
        }

        try {
            setLoading(true);
            const response = await generateFigmaDesign(PostFigmaDesign, dispatch);
            toast.success("🎉 project has been Initalize successfully!", {
                position: "top-right",
                autoClose: 1000,
                toastId: "task-success",
                hideProgressBar: false,
            });

            // console.log(response.data.detailedWorkBreakdown.work_breakdown);

            onNext({ workflowBreakdown: markdownData, figmaDesign: response.data.generateFigmaDesignData.response.figma_designs });

        } catch (error) {
            console.error("Error updating task:", error);
            toast.error(
                "Oops! There was an issue updating the task. Please try again!",
                {
                    position: "top-right",
                    autoClose: 1000,
                    toastId: "task-error",
                    hideProgressBar: false,
                }
            );

        } finally {
            setLoading(false);
        }

        // console.log(`Converted Markdown: ${markdownData}`);
        // onNext({ workflowBreakdown: markdownData });
    };


    const handleSave = async () => {
        const turndownService = new TurndownService();
        const convertedMarkdown = turndownService.turndown(inputData);

        // console.log(convertedMarkdown);

        if (convertedMarkdown == "") {
            return;
        }

        const UpdDataDoc = {
            "response": {
                "newKey": convertedMarkdown
            },
            "step": 3
        }

        try {
            setLoadingfrSave(true);
            const response = await UpdWorkFlowBreakdown(projectId, UpdDataDoc, dispatch)
            console.log(response.data);
            onNext({ workflowBreakdown: convertedMarkdown });
            navigate('/project-dashboard');

        } catch (error) {
            console.error('Error in PDF generation:', error);
            alert('An error occurred while generating the PDF. Please try again.');
        } finally {
            setLoadingfrSave(false);
        }

        // onNext({ detailedSpecs: convertedMarkdown });
        // navigate('/project-dashboard');
    };

    const toggleEdit = () => {
        const turndownService = new TurndownService();

        if (isEditing) {
            const convertedMarkdown = turndownService.turndown(inputData);
            setInputData(convertedMarkdown);
        } else {
            const convertedHtml = marked(inputData);
            setInputData(convertedHtml);
        }

        setIsEditing((prev) => !prev);
    };

    const downloadPDF = () => {
        const doc = new jsPDF();

        doc.setFontSize(10);
        const htmlContent = marked(inputData);
        doc.html(htmlContent, {
            callback: function (doc) {
                doc.save('detailed_specs.pdf');
            },
            margin: [15, 15, 15, 15],
            html2canvas: {
                scale: 3,
                useCORS: true,
                letterRendering: true,
            },
            autoPaging: true,
            x: 15,
            y: 15,
        });
    };

    useEffect(() => {
        if (data !== '') {
            const convertedHtml = marked(data);
            console.log(`convertedHtml Text is : ${convertedHtml}`);
            setInputData(convertedHtml);
        }
    }, [data]);


    return (
        <>
            <Box sx={{
                width: '100%',
                height: 'auto',
                paddingLeft: { xs: 2, sm: 4, md: 6 },
                paddingRight: { xs: 2, sm: 4, md: 6 },
                paddingTop: '5px',
            }}>


                <Typography variant="h5" sx={{ marginBottom: 3, marginTop: 3 }}>Project Scope & Resource Allocation Overview</Typography>

                <Container className="React-Quill-textArea" >

                    <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center', marginBottom: 2 }}>
                        <div><Typography variant="h5" sx={{}}>Work Breakdown & Resource Allocation Summary</Typography></div>
                        <div>
                            {/* <IconButton onClick={toggleEdit} sx={{ color: '#2e7d32' }}>
                            <CiEdit />
                        </IconButton> */}
                            <IconButton onClick={downloadPDF} sx={{ color: '#2e7d32' }}>
                                <VscCloudDownload />
                            </IconButton>
                        </div>
                    </Box>

                    <ReactQuill
                        value={inputData}
                        onChange={handleChange}
                        modules={{
                            toolbar: [
                                [{ 'header': '1' }, { 'header': '2' }],
                                [{ 'font': [] }, { 'size': ['small', false, 'large', 'huge'] }],
                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                [{ 'indent': '-1' }, { 'indent': '+1' }],
                                ['bold', 'italic', 'underline', 'strike'],
                                [{ 'align': [] }],
                                [{ 'color': [] }, { 'background': [] }],
                                ['link', 'image', 'video'],
                                ['blockquote'],
                                ['code-block'],
                                ['clean'],
                                [{ 'direction': 'rtl' }],
                            ],
                        }}
                    />

                </Container>
                
                
                {/* {isEditing ? (
                    <>

                        <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center', marginBottom: 2 }}>
                            <div><Typography variant="h5" sx={{}}>Update Work Breakdown & Resource Strategy</Typography></div>
                            <div>
                                <IconButton onClick={toggleEdit} sx={{ color: '#2e7d32' }}>
                                    <CiEdit />
                                </IconButton>
                                <IconButton onClick={downloadPDF} sx={{ color: '#2e7d32' }}>
                                    <VscCloudDownload />
                                </IconButton>
                            </div>
                        </Box>

                        <ReactQuill
                            value={inputData}
                            onChange={handleChange}
                            modules={{
                                toolbar: [
                                    [{ 'header': '1' }, { 'header': '2' }],
                                    [{ 'font': [] }, { 'size': ['small', false, 'large', 'huge'] }],
                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                    [{ 'indent': '-1' }, { 'indent': '+1' }],
                                    ['bold', 'italic', 'underline', 'strike'],
                                    [{ 'align': [] }],
                                    [{ 'color': [] }, { 'background': [] }],
                                    ['link', 'image', 'video'],
                                    ['blockquote'],
                                    ['code-block'],
                                    ['clean'],
                                    [{ 'direction': 'rtl' }],
                                ],
                            }}
                        />
                    </>
                ) : (
                    <Box sx={{
                        overflowY: 'auto',
                        padding: '10px',
                        borderRadius: '8px',
                        backgroundColor: '#fff',
                        marginBottom: 3,
                        textAlign: 'left'
                    }}>


                        <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center', marginBottom: 2 }}>
                            <div><Typography variant="h5" sx={{ textAlign: 'center' }}>Work Breakdown & Resource Allocation Summary</Typography></div>
                            <div>
                                <IconButton onClick={toggleEdit} sx={{ color: '#2e7d32' }}>
                                    <CiEdit />
                                </IconButton>
                                <IconButton onClick={downloadPDF} sx={{ color: '#2e7d32' }}>
                                    <VscCloudDownload />
                                </IconButton>
                            </div>
                        </Box>
                        <ReactMarkdown>{inputData}</ReactMarkdown>
                    </Box>
                )} */}




            </Box>

            <Box sx={{
                mt: 2,
                paddingLeft: { xs: 2, sm: 4, md: 6 },
                paddingRight: { xs: 2, sm: 4, md: 6 },
                textAlign: { xs: 'center', sm: 'right' },
            }}>

                <Button
                    onClick={handleSave}
                    variant="contained"
                    style={{
                        backgroundColor: '#4caf50', textTransform: 'capitalize', color: '#fff', borderRadius: '8px', padding: '10px 20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', transition: "background-color 0.3s, transform 0.2s",
                        "&:hover": { backgroundColor: "#388e3c", transform: "scale(1.05)", }, marginRight: '3px'
                    }}
                    disabled={loadingfrSave}
                    endIcon={loadingfrSave ? <CircularProgress size={20} style={{ color: '#fff' }} /> : null}
                >
                    Save & Close
                </Button>
            </Box>

            <Box sx={{
                mt: 2,
                paddingLeft: { xs: 2, sm: 4, md: 6 },
                paddingRight: { xs: 2, sm: 4, md: 6 },
                textAlign: { xs: 'center', sm: 'right' },
            }}>

                <Button
                    onClick={onBack}
                    variant="contained"
                    style={{
                        backgroundColor: '#4caf50', textTransform: 'capitalize', color: '#fff', borderRadius: '8px', padding: '10px 20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', transition: "background-color 0.3s, transform 0.2s", marginRight: '0.7rem',
                        "&:hover": { backgroundColor: "#388e3c", transform: "scale(1.05)", },
                    }}
                    disabled={loading}
                    // endIcon={loading ? <CircularProgress size={20} style={{ color: '#fff' }} /> : null}
                >
                    Back
                </Button>


                <Button
                    onClick={handleNext}
                    variant="contained"
                    style={{
                        backgroundColor: '#4caf50', textTransform: 'capitalize', color: '#fff', borderRadius: '8px', padding: '10px 20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', transition: "background-color 0.3s, transform 0.2s",
                        "&:hover": { backgroundColor: "#388e3c", transform: "scale(1.05)", },
                    }}
                    disabled={loading}
                    endIcon={loading ? <CircularProgress size={20} style={{ color: '#fff' }} /> : null}
                >
                    Next
                </Button>
            </Box>
        </>
    );
};

export default WorkflowBreakdown;