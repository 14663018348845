import React, { useState } from "react";
import { Formik } from "formik";
import {
  TextField,
  Button,
  Typography,
  Paper,
  Box,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { SignupSchema } from "./Validation";
import axios from "axios";
// import { signupUser } from '../../api/authApi';
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { signupUser } from "../../api/signup";

const Signup = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setLoading(true);
    try {
      const response = await signupUser(values); //axios.post('http://localhost:4000/api/users/signup', values);
      console.log("Sign up successful:", response.data);
      toast.success("SignUp Successfully!", {
        position: "top-right",
        autoClose: 1000,
        toastId: "login-success",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      setTimeout(() => {
        navigate("/signin");
      }, 1000);
    } catch (error) {
      console.error("Error during signup:", error);


      if (error.response && error.response.data.message === "User already exists") {
        toast.error("User already exists. Try to sign in!", {
          position: "top-right",
          autoClose: 1000,
          toastId: "login-error",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        toast.error("Error in SignUp. Please try again!", {
          position: "top-right",
          autoClose: 1000,
          toastId: "login-error",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      
      const errorMessage = error.response?.data?.message || "Error signing up";
      setErrors({ submit: errorMessage });
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  // const handleSubmit = (values, { setSubmitting }) => {
  //     console.log('Form submitted:', values);
  //     setSubmitting(false);
  // };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <Box className="flex justify-center items-center min-h-[85vh] bg-gray-100 p-4">
      <Paper
        elevation={5}
        sx={{ borderRadius: "13px" }}
        className="w-full max-w-md p-6 rounded-3xl shadow-md"
      >
        <Typography variant="h4" component="h1" className="text-center mb-6">
          Sign Up
        </Typography>
        <Formik
          initialValues={{ name: "", email: "", password: "" }}
          validationSchema={SignupSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="mb-5">
                <Typography variant="body1" className="mb-2 font-medium">
                  Name
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  style={{ backgroundColor: "white" }}
                />
              </div>
              <div className="mb-5">
                <Typography variant="body1" className="mb-2 font-medium">
                  Email
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  style={{ backgroundColor: "white" }}
                />
              </div>
              <div className="mb-5">
                <Typography variant="body1" className="mb-2 font-medium">
                  Password
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  style={{ backgroundColor: "white" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={togglePasswordVisibility}
                          edge="end"
                        >
                          <FontAwesomeIcon
                            icon={showPassword ? faEyeSlash : faEye}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              <div className="text-start mt-4 mb-4">
                <Typography variant="body2">
                  Already have an account?{" "}
                  <Link to="/signin" className="text-blue-500 hover:underline">
                    Sign In
                  </Link>
                </Typography>
              </div>

              <div className="flex justify-center">
                <Button
                  variant="contained"
                  type="submit"
                  // onClick={currentTask ? handleUpdateTask : handleAddTask}
                  sx={{
                    backgroundColor: "#4caf50",
                    textTransform: "capitalize",
                    color: "#fff",
                    borderRadius: "8px",
                    padding: "10px 20px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    transition: "background-color 0.3s, transform 0.2s",
                    "&:hover": {
                      backgroundColor: "#388e3c",
                      transform: "scale(1.05)",
                    },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  disabled={loading}
                  endIcon={
                    loading ? (
                      <CircularProgress size={20} sx={{ color: "#fff" }} />
                    ) : null
                  }
                >
                  Sign Up
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Paper>

      <ToastContainer />
    </Box>
  );
};

export default Signup;