import React, { useState } from "react";
import {
  AppBar,
  Button,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  Box,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "../redux/reduxslices/userSlices";
import { clearProjectId } from "../redux/reduxslices/ProjectIdSlice";
import { GoSidebarCollapse, GoSidebarExpand } from "react-icons/go";
import ActivityDrawer from "./menu/ActivityDrawer";

const routes = [
  { path: "/signin", label: "Sign In" },
  { path: "/signup", label: "Sign Up" },
];

const SignInRoutes = [
  // { path: "/create-new-project", label: "Create Project" },
  { path: "/project-dashboard", label: "Project Insights" },
  { path: "/upload-attendance", label: "Update Attendance" },
];

export const Navbar = ({ onSidebarToggle }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activityDrawerOpen, setActivityDrawerOpen] = useState(false);
  const [navLinksVisible, setNavLinksVisible] = useState(false);

  const token = useSelector((state) => state.user.token);
  const projectId = useSelector((state) => state.projectId.projectId);

  const isActive = (path) => location.pathname === path;

  const handleSignOut = () => {
    dispatch(signOut({ user: null, token: null }));
    dispatch(clearProjectId());
  };

  const drawerList = () => (
    <div
      role="presentation"
      onClick={() => setDrawerOpen(false)}
      onKeyDown={() => setDrawerOpen(false)}
    >
      <List>
        {!token &&
          routes.map(({ path, label }) => (
            <Link key={path} to={path} style={{ textDecoration: "none" }}>
              <ListItem button>
                <Button
                  color="inherit"
                  sx={{
                    color: isActive(path) ? "white" : "#2e7d32",
                    background: isActive(path) ? "#2e7d32" : "transparent",
                    textTransform: "capitalize",
                    transition: "background 0.3s",
                    width: "100%",
                  }}
                >
                  {label}
                </Button>
              </ListItem>
            </Link>
          ))

        }

        {token && (
          <>
            {SignInRoutes.map(({ path, label }) => (
              <Link key={path} to={path} style={{ textDecoration: "none" }}>
                <ListItem button>
                  <Button
                    color="inherit"
                    sx={{
                      color: isActive(path) ? "white" : "#2e7d32",
                      borderRadius: isActive(path) ? "8px" : "",
                      background: isActive(path) ? "#2e7d32" : "transparent",
                      textTransform: "capitalize",
                      transition: "background 0.3s",
                      width: "100%",
                    }}
                  >
                    {label}
                  </Button>
                </ListItem>
              </Link>
            ))}

            <ListItem button>
              <Button
                color="inherit"
                onClick={handleSignOut}
                sx={{
                  color: "#2e7d32",
                  background: "transparent",
                  textTransform: "capitalize",
                  transition: "background 0.3s",
                  width: "100%",
                }}
              >
                Sign Out
              </Button>
            </ListItem>
          </>
        )}
      </List>
    </div>
  );

  return (
    <AppBar position="static" sx={{ background: "#a5d6a7" }}>
      <Toolbar className="flex justify-between items-center relative">

        {token && projectId &&
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => {
              onSidebarToggle();
              setNavLinksVisible(false);
            }}
            // onClick={onSidebarToggle} 
            sx={{
              borderRadius: "50%",
              padding: "8px",
              "&:hover": {
                color: "#000",
              },
            }}
          >
            <GoSidebarCollapse color="#2e7d32" />
          </IconButton>
        }

        <Link
          to="/"
          style={{
            textDecoration: "none",
            marginLeft: "15px",
            color: "#2e7d32",
            flexGrow: 1,
            fontWeight: "bold",
            // width:""
          }}
        >
          <Typography
            variant="h6"
            sx={{
              marginLeft: "0px",
              color: "#2e7d32",
              flexGrow: 1,
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            Task Management
          </Typography>
        </Link>


        <Box
          sx={{
            display: { xs: 'none', md: 'flex' },
            gap: { md: 4 },
          }}
        // className="hidden md:flex md:space-x-4"
        >
          {!token &&
            routes.map(({ path, label }) => (
              <Link key={path} to={path} style={{ textDecoration: "none" }}>
                <Button
                  color="inherit"
                  sx={{
                    color: isActive(path) ? "white" : "#2e7d32",
                    background: isActive(path) ? "#2e7d32" : "transparent",
                    textTransform: "capitalize",
                    transition: "background 0.3s",
                  }}
                >
                  {label}
                </Button>
              </Link>
            ))}

          {token && (
            <>
              {SignInRoutes.map(({ path, label }) => (
                <Link key={path} to={path} style={{ textDecoration: "none" }}>
                  <Button
                    color="inherit"
                    sx={{
                      color: isActive(path) ? "white" : "#2e7d32",
                      background: isActive(path) ? "#2e7d32" : "transparent",
                      borderRadius: isActive(path) ? "8px" : "",
                      textTransform: "capitalize",
                      transition: "background 0.3s",
                    }}
                  >
                    {label}
                  </Button>
                </Link>
              ))}

              {/* Sign Out Button */}
              <Button
                color="inherit"
                onClick={handleSignOut}
                sx={{
                  color: "#2e7d32",
                  background: "transparent",
                  textTransform: "capitalize",
                  transition: "background 0.3s",
                }}
              >
                Sign Out
              </Button>
            </>
          )}
        </Box>

        {token && projectId && (
          <>

            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={() => setActivityDrawerOpen(true)}
              sx={{
                borderRadius: "50%",
                padding: "8px",
                "&:hover": {
                  color: "#000",
                },
                marginRight: '3px'
              }}
            >
              <GoSidebarExpand color="#2e7d32" />
            </IconButton>

            {/* Sidebar Toggle Button (Hamburger) for Mobile */}
            {token && projectId && (
              // <div className="md:hidden absolute right-10 top-1/2 transform -translate-y-1/2 z-10">
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => setNavLinksVisible(!navLinksVisible)}
                sx={{
                  borderRadius: "50%",
                  padding: "8px",
                  "&:hover": {
                    color: "#000",
                  },
                  display: { xs: 'block', md: 'none' }
                }}
              >
                <FontAwesomeIcon icon={faBars} color="#2e7d32" />
              </IconButton>
              // </div>
            )}
          </>
        )}
      </Toolbar>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        {drawerList()}
      </Drawer>

      {navLinksVisible && (
        <Box sx={{
          display: { md: 'none' },
          backgroundColor: 'white',
          padding: { md: 1, sm: 0.5 },
        }}>
          <List>
            {!token &&
              routes.map(({ path, label }) => (
                <Link
                  key={path} to={path} style={{ textDecoration: "none" }}>
                  <ListItem button>
                    <Button
                      color="inherit"
                      sx={{
                        color: isActive(path) ? "white" : "#2e7d32",
                        background: isActive(path) ? "#2e7d32" : "transparent",
                        textTransform: "capitalize",
                        transition: "background 0.3s",
                        width: "100%",
                      }}

                    >
                      {label}
                    </Button>
                  </ListItem>
                </Link>
              ))}

            {token &&
              SignInRoutes.map(({ path, label }) => (
                <Link key={path} to={path} style={{ textDecoration: "none" }}
                  onClick={() => setNavLinksVisible(false)}
                >
                  <ListItem button>
                    <Button
                      color="inherit"
                      sx={{
                        color: isActive(path) ? "white" : "#2e7d32",
                        background: isActive(path) ? "#2e7d32" : "transparent",
                        textTransform: "capitalize",
                        transition: "background 0.3s",
                        width: "100%",
                      }}
                    >
                      {label}
                    </Button>
                  </ListItem>
                </Link>
              ))}

            {token && (
              <ListItem button>
                <Button
                  color="inherit"
                  onClick={handleSignOut}
                  sx={{
                    color: "#2e7d32",
                    background: "transparent",
                    textTransform: "capitalize",
                    transition: "background 0.3s",
                    width: "100%",
                  }}
                >
                  Sign Out
                </Button>
              </ListItem>
            )}
          </List>
        </Box>
      )}


      <ActivityDrawer
        open={activityDrawerOpen}
        onClose={() => setActivityDrawerOpen(false)}
      />
    </AppBar>
  );
};
















// import React, { useState } from "react";
// import {
//   AppBar,
//   Button,
//   Toolbar,
//   Typography,
//   IconButton,
//   Drawer,
//   List,
//   ListItem,
// } from "@mui/material";
// import { Link, useLocation } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBars } from "@fortawesome/free-solid-svg-icons";
// import { useDispatch, useSelector } from "react-redux";
// import { signOut } from "../redux/reduxslices/userSlices";
// import { clearProjectId } from "../redux/reduxslices/ProjectIdSlice";
// import { GoSidebarCollapse, GoSidebarExpand } from "react-icons/go";
// import ActivityDrawer from "./menu/ActivityDrawer";

// const routes = [
//   { path: "/signin", label: "Sign In" },
//   { path: "/signup", label: "Sign Up" },
// ];

// const SignInRoutes = [
//   { path: "/create-new-project", label: "Create Project" },
//   { path: "/project-dashboard", label: "Project Insights" },
//   { path: "/upload-attendance", label: "Update Attendance" },
// ];

// export const Navbar = ({ onSidebarToggle }) => {
//   const dispatch = useDispatch();
//   const location = useLocation();
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const [activityDrawerOpen, setActivityDrawerOpen] = useState(false);

//   const token = useSelector((state) => state.user.token);
//   const projectId = useSelector((state) => state.projectId.projectId);

//   const isActive = (path) => location.pathname === path;

//   const handleSignOut = () => {
//     dispatch(signOut({ user: null, token: null }));
//     dispatch(clearProjectId());
//   };

//   const drawerList = () => (
//     <div
//       role="presentation"
//       onClick={() => setDrawerOpen(false)}
//       onKeyDown={() => setDrawerOpen(false)}
//     >
//       <List>
//         {!token &&
//           routes.map(({ path, label }) => (
//             <Link key={path} to={path} style={{ textDecoration: "none" }}>
//               <ListItem button>
//                 <Button
//                   color="inherit"
//                   sx={{
//                     color: isActive(path) ? "white" : "#2e7d32",
//                     background: isActive(path) ? "#2e7d32" : "transparent",
//                     textTransform: "capitalize",
//                     transition: "background 0.3s",
//                     width: "100%",
//                   }}
//                 >
//                   {label}
//                 </Button>
//               </ListItem>
//             </Link>
//           ))}


//         {token && (
//           <>
//             {SignInRoutes.map(({ path, label }) => (
//               <Link key={path} to={path} style={{ textDecoration: "none" }}>
//                 <ListItem button>
//                   <Button
//                     color="inherit"
//                     sx={{
//                       color: isActive(path) ? "white" : "#2e7d32",
//                       borderRadius: isActive(path) ? "8px" : "",
//                       background: isActive(path) ? "#2e7d32" : "transparent",
//                       textTransform: "capitalize",
//                       transition: "background 0.3s",
//                       width: "100%",
//                     }}
//                   >
//                     {label}
//                   </Button>
//                 </ListItem>
//               </Link>
//             ))}

//             <ListItem button>
//               <Button
//                 color="inherit"
//                 onClick={handleSignOut}
//                 sx={{
//                   color: "#2e7d32",
//                   background: "transparent",
//                   textTransform: "capitalize",
//                   transition: "background 0.3s",
//                   width: "100%",
//                 }}
//               >
//                 Sign Out
//               </Button>
//             </ListItem>
//           </>
//         )}


//         {/* {token && (
//           <ListItem button>
//             <Button
//               color="inherit"
//               onClick={handleSignOut}
//               sx={{
//                 color: "#2e7d32",
//                 background: "transparent",
//                 textTransform: "capitalize",
//                 transition: "background 0.3s",
//                 width: "100%",
//               }}
//             >
//               Sign Out
//             </Button>
//           </ListItem>
//         )} */}



//       </List>
//     </div>
//   );

//   return (
//     <AppBar position="static" sx={{ background: "#a5d6a7" }}>
//       <Toolbar className="flex justify-between items-center relative">
//         {/* Sidebar Toggle Button (Hamburger) */}
//         {token && projectId && (
//           <div className="absolute left-4 top-1/2 transform -translate-y-1/2 z-10">
//             <IconButton
//               edge="start"
//               color="inherit"
//               aria-label="menu"
//               onClick={onSidebarToggle}
//               sx={{
//                 borderRadius: "50%",
//                 padding: "8px",
//                 "&:hover": {
//                   color: "#000",
//                 },
//               }}
//             >
//               <GoSidebarCollapse color="#2e7d32" />
//             </IconButton>
//           </div>
//         )}

//         {/* Logo */}
//         <Link to="/" style={{
//           textDecoration: "none", marginLeft: "15px",
//           color: "#2e7d32",
//           flexGrow: 1,
//           fontWeight: "bold",
//         }}>
//           <Typography
//             variant="h6"
//             sx={{
//               marginLeft: "20px",
//               color: "#2e7d32",
//               flexGrow: 1,
//               fontWeight: "bold",
//               cursor: "pointer",
//             }}
//           >
//             Task Management
//           </Typography>
//         </Link>


//         {/* <Typography
//           variant="h6"
//           sx={{
//             marginLeft: "20px",
//             color: "#2e7d32",
//             flexGrow: 1,
//             fontWeight: "bold",
//           }}
//         >
//           Task Management
//         </Typography> */}

//         {/* Right-side navigation buttons (only for large screens) */}
//         <div className="hidden md:flex md:space-x-4">
//           {!token &&
//             routes.map(({ path, label }) => (
//               <Link key={path} to={path} style={{ textDecoration: "none" }}>
//                 <Button
//                   color="inherit"
//                   sx={{
//                     color: isActive(path) ? "white" : "#2e7d32",
//                     background: isActive(path) ? "#2e7d32" : "transparent",
//                     textTransform: "capitalize",
//                     transition: "background 0.3s",
//                   }}
//                 >
//                   {label}
//                 </Button>
//               </Link>
//             ))}

//           {token && (
//             <>
//               {SignInRoutes.map(({ path, label }) => (
//                 <Link key={path} to={path} style={{ textDecoration: "none" }}>
//                   <Button
//                     color="inherit"
//                     sx={{
//                       color: isActive(path) ? "white" : "#2e7d32",
//                       background: isActive(path) ? "#2e7d32" : "transparent",
//                       borderRadius: isActive(path) ? "8px" : "",
//                       textTransform: "capitalize",
//                       transition: "background 0.3s",
//                       width: "100%",
//                       // marginBottom: 1,
//                     }}
//                   >
//                     {label}
//                   </Button>
//                 </Link>
//               ))}

//               {/* Sign Out Button */}
//               <Button
//                 color="inherit"
//                 onClick={handleSignOut}
//                 sx={{
//                   color: "#2e7d32",
//                   background: "transparent",
//                   textTransform: "capitalize",
//                   transition: "background 0.3s",
//                 }}
//               >
//                 Sign Out
//               </Button>
//             </>
//           )}




//           {/* {token && (
//             <Button
//               color="inherit"
//               onClick={handleSignOut}
//               sx={{
//                 color: "#2e7d32",
//                 background: "transparent",
//                 textTransform: "capitalize",
//                 transition: "background 0.3s",
//               }}
//             >
//               Sign Out
//             </Button>
//           )} */}


//         </div>

//         {/* Show menu icon after signing out */}
//         {token && projectId && (
//           <IconButton
//             edge="end"
//             color="inherit"
//             aria-label="menu"
//             onClick={() => setActivityDrawerOpen(true)}
//             sx={{
//               borderRadius: "50%",
//               padding: "8px",
//               "&:hover": {
//                 color: "#000",
//               },
//             }}
//           >
//             <GoSidebarExpand color="#2e7d32" />
//           </IconButton>
//           // <IconButton
//           //   edge="end"
//           //   color="inherit"
//           //   aria-label="menu"
//           //   onClick={() => setActivityDrawerOpen(true)}
//           // >
//           //  <FontAwesomeIcon color="#2e7d32" icon={faBars} size="lg" />
//           // </IconButton>
//         )}
//       </Toolbar>

//       {/* Drawer (Sidebar) */}
//       <Drawer
//         anchor="right"
//         open={drawerOpen}
//         onClose={() => setDrawerOpen(false)}
//       >
//         {drawerList()}
//       </Drawer>

//       {/* Activity Drawer */}
//       <ActivityDrawer
//         open={activityDrawerOpen}
//         onClose={() => setActivityDrawerOpen(false)}
//       />
//     </AppBar>
//   );
// };