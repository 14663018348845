import {
    Box,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    IconButton,
    CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MdEdit, MdDelete } from 'react-icons/md';
import { MdAdd } from 'react-icons/md';
import { AddSprint, DeleteSprint, getSprints, updateSprint } from "../../api/appApi";
import { toast } from "react-toastify";
import { signOut } from "../../redux/reduxslices/userSlices";
import { clearProjectId } from "../../redux/reduxslices/ProjectIdSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IoIosArrowDropdown } from "react-icons/io";
import { MdArrowDropDown } from "react-icons/md";


const Sprint = ({ data, onUpdate }) => {

    // console.log(`data are ${data}`);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const UserRole = useSelector((state) => state.user?.user?.role);
    const UserId = useSelector((state) => state.user?.user?.userid);

    const [projectData, setProjectData] = useState(null);
    const [projectId, setProjectId] = useState("");
    const [open, setOpen] = useState(false);
    const [sprintTitle, setSprintTitle] = useState('');
    const [sprintList, setSprintList] = useState([]);
    const [sprints, setSprints] = useState([]);
    const [sprintRow, setSprintRow] = useState({ taskName: '', complexity: '', priority: '', timeSpent: '' });
    const [currentRowIndex, setCurrentRowIndex] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [openSprints, setOpenSprints] = useState({});
    const [PopupEditing, setPopupEditing] = useState(false);
    const [UpdSprintId, setUpdSprintId] = useState('');


    const handleClickOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setSprintTitle('');
        resetSprintRow();
        setSprintList([]);
        setIsEditing(false);
        setPopupEditing(false);
        setUpdSprintId('');

    };

    const resetSprintRow = () => setSprintRow({ taskName: '', complexity: '', priority: '', timeSpent: '' });

    const handleSave = async () => {

        if (sprintTitle.trim() == '' || sprintList.length == 0) {
            toast.info("Please Fill the form first", {
                position: "top-right",
                autoClose: 1000,
                toastId: "Sprint-task-field",
                hideProgressBar: false,
            });
            // alert("Please Fill the form first");
            return;
        }
        const sprintData = {
            sprintName: sprintTitle,
            projectId: projectId,
            tasks: sprintList.filter(item => item.taskName),
        };

        // console.log("Sprint Data:", sprintData);
        try {
            setLoading(true);

            const response = await AddSprint(sprintData, dispatch);  //addTask(updatedTask); 

            // setSprints((prevSprints) => [...prevSprints, response.data.sprint]);

            const updatedSprints = response.data.sprint;

            const updatedProjectData = {
                ...projectData,
                project: {
                    ...projectData.project,
                    sprints: [
                        ...projectData.project.sprints,
                        updatedSprints
                    ],
                },
            };

            setProjectData(updatedProjectData);
            onUpdate(updatedProjectData);

            toast.success("🎉 Sprint has been added successfully!", {
                position: "top-right",
                autoClose: 1000,
                toastId: "Sprint-task-success",
                hideProgressBar: false,
            });



            handleClose();


        } catch (error) {
            console.error("Error adding task:", error);
            handleClose();

            toast.error(
                "Oops! There was an issue adding the Sprint. Please try again!",
                {
                    position: "top-right",
                    autoClose: 1000,
                    toastId: "Sprint-error",
                    hideProgressBar: false,

                }
            );

        } finally {
            setLoading(false);
            handleClose();

        }
    };

    const handleAddOrUpdateRow = () => {
        const { taskName, complexity, priority, timeSpent } = sprintRow;

        // if (!taskName || !complexity || !timeSpent) {
        if (!taskName) {
            toast.info("TaskName must be filled out!", {
                position: "top-right",
                autoClose: 1000,
                toastId: "Sprint-task-field",
                hideProgressBar: false,
            });

            return;
        }

        if (isEditing) {

            const updatedList = sprintList.map((row, index) =>
                index === currentRowIndex ? sprintRow : row
            );
            setSprintList(updatedList);
        } else {

            setSprintList([...sprintList, sprintRow]);
        }

        resetSprintRow();
        setIsEditing(false);
    };

    const handleEditRow = (index) => {
        setCurrentRowIndex(index);
        setSprintRow(sprintList[index]);
        setIsEditing(true);
    };

    const handleDeleteRow = (index) => {
        const newSprintList = sprintList.filter((_, i) => i !== index);
        setSprintList(newSprintList);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(`name is ${name}`);
        setSprintRow({ ...sprintRow, [name]: value });
    };

    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };


    const handleToggleSprint = (index) => {
        // Toggle the open state of the clicked sprint
        setOpenSprints((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };


    const handleEditSprint = (sprint) => {
        console.log(sprint);
        const sprintIndex = sprintList.findIndex((item) => item._id === sprint._id);
        handleClickOpen();
        setCurrentRowIndex(sprintIndex);
        setSprintTitle(sprint.name);
        setSprintList(sprint.tasks)
        setUpdSprintId(sprint._id);
        setPopupEditing(true);

    };


    const handleUpdateSprint = async () => {
        console.log('Updating sprint with data:',);

        if (sprintTitle.trim() == '' || sprintList.length == 0) {

            toast.info("Please Fill the form first", {
                position: "top-right",
                autoClose: 1000,
                toastId: "Sprint-task-field",
                hideProgressBar: false,
            });

            // alert("Please Fill the form first");
            return;
        }

        const sprintData = {
            sprintName: sprintTitle,
            tasks: sprintList.filter(item => item.taskName),
        };

        // console.log('id is ', UpdSprintId, 'and data is ', sprintData );

        try {
            setLoading(true);

            const response = await updateSprint(UpdSprintId, sprintData, dispatch);  //AddSprint(sprintData, dispatch);

            // setSprints((prevSprints) => [...prevSprints, response.data.sprint]);

            const updatedSprint = response.data.sprint;

            const updatedProjectData = {
                ...projectData,
                project: {
                    ...projectData.project,
                    sprints: projectData.project.sprints.map((sprint) =>
                        sprint._id === updatedSprint._id ? updatedSprint : sprint
                    ),
                    // sprints: [
                    //     ...projectData.project.sprints,
                    //     updatedSprints
                    // ],
                },
            };

            setProjectData(updatedProjectData);
            onUpdate(updatedProjectData);

            toast.success("🎉 Sprint has been added successfully!", {
                position: "top-right",
                autoClose: 1000,
                toastId: "Sprint-task-success",
                hideProgressBar: false,
            });



            handleClose();


        } catch (error) {
            console.error("Error adding task:", error);
            handleClose();

            toast.error(
                "Oops! There was an issue adding the Sprint. Please try again!",
                {
                    position: "top-right",
                    autoClose: 1000,
                    toastId: "Sprint-error",
                    hideProgressBar: false,

                }
            );

        } finally {
            setLoading(false);
            handleClose();

        }

    };

    const handleDeleteSprint = async (sprintId) => {

        console.log("handleDeleteSprint id is ", sprintId._id);

        try {
            setLoading(true);

            const response = await DeleteSprint(sprintId._id, dispatch);

            console.log(response.data);

            const updatedSprints = projectData.project.sprints.filter(sprint => sprint._id !== sprintId._id);

            const updatedProjectData = {
                ...projectData,
                project: {
                    ...projectData.project,
                    sprints: updatedSprints,
                },
            };

            setProjectData(updatedProjectData);
            onUpdate(updatedProjectData);

            toast.success("🎉 Sprint has been deleted successfully!", {
                position: "top-right",
                autoClose: 1000,
                toastId: "Sprint-deleted-success",
                hideProgressBar: false,
            });

        } catch (error) {
            console.error("Error deleting sprint:", error);

            toast.error(
                "Oops! There was an issue deleting the Sprint. Please try again!",
                {
                    position: "top-right",
                    autoClose: 1000,
                    toastId: "Sprint-error",
                    hideProgressBar: false,
                }
            );

        } finally {
            setLoading(false);
        }

    };





    useEffect(() => {
        if (data) {
            setProjectData(data);
            setProjectId(data.project._id);
            // getSprints(data.project._id);
            // console.log(userId);
        }
        if (data?.project?.sprints) {
            setSprints(data.project.sprints);
        }
    }, [data]);

    // useEffect(()=>{

    // },[])

    return (
        <Box className="p-6 pb-1">
            <Typography variant="h4" sx={{ fontWeight: 600, color: "main", textTransform: 'capitalize' }}>
                Sprint Overview
            </Typography>


            <Box className='p-6 pt-2'>


                {sprints && sprints.length > 0 ? (
                    <div>
                        {sprints.map((sprint, index) => (
                            <details
                                key={sprint._id}
                                style={{ marginBottom: '0.2rem' }}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                            >
                                <summary
                                    style={{
                                        fontWeight: 400,
                                        fontSize: '1.1rem',
                                        cursor: 'pointer',
                                        color: '#333',
                                        padding: '0.1rem 0',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >

                                    <span style={{ marginBottom: '5px', transition: 'transform 0.3s' }}>
                                        <MdArrowDropDown
                                            size={25}
                                            style={{
                                                transform: openSprints[index] ? 'rotate(180deg)' : 'rotate(0deg)',
                                            }}
                                        />
                                    </span>

                                    {/* <span style={{ marginRight: '10px' }}>
                                        <MdArrowDropDown size={25} />
                                    </span> */}

                                    <span style={{ marginRight: '15px' }}>
                                        {sprint.name}
                                    </span>

                                    {hoveredIndex === index && (
                                        <div style={{ display: 'flex', gap: '0.3rem' }}>
                                            <MdEdit
                                                size={18}
                                                style={{ cursor: 'pointer', transition: 'color 0.3s' }}
                                                onClick={() => handleEditSprint(sprint)}
                                            />

                                            <MdDelete
                                                size={18}
                                                style={{ cursor: 'pointer', transition: 'color 0.3s' }}
                                                onClick={() => handleDeleteSprint(sprint)}
                                            />
                                        </div>
                                    )}

                                </summary>
                                <ul style={{ marginTop: '0.2em', paddingLeft: '1.5em', marginBottom: '0.2em' }}>
                                    {sprint.tasks.map((task) => (
                                        <li key={task._id} style={{ listStyle: 'disc', marginBottom: '0.3rem', marginLeft: '10px' }}>
                                            <Typography variant="body2" sx={{ color: '#555' }}>
                                                {task.taskName}
                                            </Typography>
                                        </li>
                                    ))}
                                </ul>
                            </details>
                        ))}
                    </div>
                ) : (
                    <Typography variant="body2" sx={{ marginTop: "16px", color: "text.disabled" }}>
                        No sprints found.
                    </Typography>
                )}









                {/* {sprints && sprints.length > 0 ? (
                    <div>
                        {sprints.map((sprint, index) => (
                            <details key={sprint._id} style={{ marginBottom: '0.2rem' }}>
                                <summary
                                    style={{
                                        fontWeight: 400,
                                        fontSize: '1.1rem',
                                        cursor: 'pointer',
                                        color: '#333',
                                        padding: '0.1rem 0',
                                    }}
                                >
                                    {sprint.name}
                                </summary>
                                <ul style={{ marginTop: '0.2em', paddingLeft: '1.5em', marginBottom: '0.2em' }}>
                                    {sprint.tasks.map((task) => (
                                        <li key={task._id} style={{ listStyle: 'disc', marginBottom: '0.3rem' }}>
                                            <Typography variant="body2" sx={{ color: '#555' }}>
                                                {task.taskName}
                                            </Typography>
                                        </li>
                                    ))}
                                </ul>
                            </details>
                        ))}
                    </div>
                ) : (
                    <Typography variant="body2" sx={{ marginTop: "16px", color: "text.disabled" }}>
                        No sprints found.
                    </Typography>
                )} */}




                {/* {sprints && sprints.length > 0 ? (
                    <ol style={{ listStyle: 'auto' }}>
                        {sprints.map((sprint, index) => (
                            <li key={sprint._id}>
                                <Typography variant="h6" sx={{ fontWeight: 500, display: 'inline' }}>
                                    {sprint.name}
                                </Typography>
                                <ul style={{ marginTop: '0em', paddingLeft: '1em' }}>
                                    {sprint.tasks.map((task) => (
                                        <li style={{ listStyle: 'disc' }} key={task._id}>
                                            <Typography variant="body2">
                                                {task.taskName}
                                            </Typography>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </ol>
                ) : (
                    <Typography variant="body2" sx={{ marginTop: "16px", color: "text.disabled" }}>
                        No sprints found.
                    </Typography>
                )} */}
            </Box>

            {
                UserRole && UserRole.trim() === 'admin' && (
                    <Box className="mt-6">
                        <Button
                            variant="contained"
                            onClick={handleClickOpen}
                            startIcon={<MdAdd />}
                            sx={{
                                backgroundColor: "#4caf50",
                                textTransform: "capitalize",
                                color: "#fff",
                                borderRadius: "8px",
                                padding: "10px 10px",
                                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                transition: "background-color 0.3s, transform 0.2s",
                                "&:hover": {
                                    backgroundColor: "#388e3c",
                                    transform: "scale(1.05)",
                                },
                            }}
                        >
                            Add New Sprint
                        </Button>
                    </Box>
                )

            }



            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">

                <DialogTitle>{PopupEditing ? 'Update Sprint' : 'Add New Sprint'}</DialogTitle>
                <DialogContent>

                    <span
                        style={{
                            display: "flex",
                            gap: "1rem",
                            marginBottom: "10px",
                            alignItems: "center",
                            border: "1px solid gray",
                            padding: "10px",
                            borderRadius: "5px",
                            backgroundColor: "#f9f9f9",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            width: "auto",
                        }}
                    >
                        <Typography variant="body1" style={{ fontWeight: "bold" }}>Title:</Typography>
                        <TextField
                            autoFocus
                            margin="dense"
                            sx={{ width: "100%", background: "transparent" }}
                            value={sprintTitle}
                            onChange={(e) => setSprintTitle(e.target.value)}
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                            }}
                            inputProps={{
                                style: { border: "none", padding: "5px", fontSize: "16px" },
                            }}
                        />
                    </span>


                    <TableContainer
                        component={Paper}
                        sx={{
                            marginTop: 2,
                            boxShadow: 2,
                            borderRadius: 2,
                        }}
                    >
                        <Table sx={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#333' }}>Name</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#333' }}>Complexity</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#333' }}>Priority</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#333' }}>Time Required</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#333' }}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sprintList && sprintList.length > 0 ? (
                                    sprintList.map((row, index) => (
                                        <TableRow key={index} sx={{ '&:hover': { backgroundColor: '#f1f1f1' } }}>
                                            <TableCell>{row.taskName}</TableCell>
                                            <TableCell>{row.complexity}</TableCell>
                                            <TableCell>{row.priority}</TableCell>
                                            <TableCell>{row.timeSpent}</TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => handleEditRow(index)} sx={{ color: '#1976d2' }}>
                                                    <MdEdit />
                                                </IconButton>
                                                <IconButton onClick={() => handleDeleteRow(index)} sx={{ color: '#d32f2f' }}>
                                                    <MdDelete />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))

                                ) : (
                                    <TableRow>
                                        <TableCell align="center" colSpan={5} className="text-center">
                                            No sprints found.
                                        </TableCell>
                                    </TableRow>
                                )}


                                <TableRow sx={{ '&:hover': { backgroundColor: '#f1f1f1' } }}>
                                    <TableCell>
                                        <TextField
                                            // label="Name"
                                            name="taskName"
                                            value={sprintRow.taskName}
                                            onChange={handleChange}
                                            required
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            // label="Complexity"
                                            name="complexity"
                                            value={sprintRow.complexity}
                                            onChange={handleChange}
                                            required
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <FormControl>
                                            {/* <InputLabel>Priority</InputLabel> */}
                                            <Select
                                                name="priority"
                                                value={sprintRow.priority}
                                                onChange={handleChange}
                                            >
                                                <MenuItem value="High">High</MenuItem>
                                                <MenuItem value="Medium">Medium</MenuItem>
                                                <MenuItem value="Low">Low</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            // label="Time Spent"
                                            name="timeSpent"
                                            value={sprintRow.timeSpent}
                                            onChange={handleChange}
                                            required
                                        />


                                        {/* <FormControl fullWidth>
                                            <Select
                                                name="timeSpent"
                                                value={sprintRow.timeSpent}
                                                onChange={handleChange}
                                                required
                                                displayEmpty
                                            >
                                                {Array.from({ length: 24 }, (_, index) => (
                                                    <MenuItem key={index} value={index}>
                                                        {index} hour{index !== 1 ? 's' : ''}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl> */}

                                    </TableCell>
                                    <TableCell>


                                        <Button
                                            onClick={handleAddOrUpdateRow}
                                            variant="contained"
                                            color="primary"
                                            startIcon={isEditing ? <MdEdit /> : <MdAdd />}
                                            sx={{
                                                backgroundColor: "#4caf50",
                                                textTransform: "capitalize",
                                                color: "#fff",
                                                borderRadius: "8px",
                                                padding: "10px 20px",
                                                transition: "background-color 0.3s, transform 0.2s",
                                                "&:hover": {
                                                    backgroundColor: "#388e3c",
                                                    transform: "scale(1.05)",
                                                },
                                            }}
                                        >

                                            {isEditing ? 'Update' : 'Add'}
                                        </Button>

                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={handleClose} color="primary">Cancel</Button> */}
                    <Button
                        onClick={PopupEditing ? handleUpdateSprint : handleSave}   // {handleSave}
                        sx={{
                            backgroundColor: "#4caf50", textTransform: "capitalize", color: "#fff", borderRadius: "8px", padding: "10px 20px", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", transition: "background-color 0.3s, transform 0.2s",
                            "&:hover": {
                                backgroundColor: "#388e3c",
                                transform: "scale(1.05)",
                            },
                        }}
                        disabled={loading}
                        endIcon={
                            loading ? (
                                <CircularProgress size={20} sx={{ color: "#fff" }} />
                            ) : null
                        }
                    >
                        {PopupEditing ? 'Update Sprint' : 'Add Sprint'}
                    </Button>
                </DialogActions>
            </Dialog>


        </Box>
    );
}

export default Sprint;