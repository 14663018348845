import React, { useEffect, useState } from 'react';
import { Backdrop, Box, Button, Card, CardContent, Chip, CircularProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { MdAdd } from 'react-icons/md';
import ReactMarkdown from 'react-markdown';
import { GetInitProjectList } from '../../api/appApi';
import { useDispatch } from 'react-redux';

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress sx={{
        color: 'rgb(76, 175, 80)',
      }} size={50} thickness={5} variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography fontSize={14} variant="caption" component="div" sx={{ color: 'text.secondary' }}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const InitProjectLists = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [projectsList, setprojectsList] = useState([]);

  const projects = [
    {
      Projectid: '6756e610a134391d33244f6e',
      projectName: 'GreenTech Initiative',
      description:
        'GreenTech Initiative is a groundbreaking project aimed at utilizing renewable energy sources to power cities. The project focuses on the development of solar power systems that can be scaled for both residential and industrial use, helping to reduce global carbon emissions and mitigate climate change.',
      date: '2024-12-10',
      status: 2,
    },
    {
      Projectid: '6758329fbe574b73851c16f0',
      projectName: 'Urban Mobility Solutions',
      description:
        'Urban Mobility Solutions is designed to tackle the problem of traffic congestion in metropolitan cities. By integrating autonomous vehicles and electric scooters with AI-powered traffic management systems, the project aims to make cities more accessible, reduce emissions, and improve public transportation efficiency.',
      date: '2024-12-01',
      status: 5,
    },
    {
      Projectid: '6751aae0170602bdfa3b9696',
      projectName: 'AI for Healthcare',
      description:
        'AI for Healthcare is an innovative initiative that leverages machine learning and big data to enhance diagnostics and treatment plans. By integrating AI tools into the healthcare system, this project aims to improve the accuracy of diagnoses, accelerate drug discovery, and make healthcare more accessible to underserved communities.',
      date: '2024-12-15',
      status: 4,
    },
    {
      Projectid: '67443ebf8317b965cfbfa72a',
      projectName: 'Smart Agriculture Project',
      description:
        'The Smart Agriculture Project aims to introduce precision farming techniques to small-scale farmers by utilizing IoT devices and AI-driven analytics. The goal is to optimize crop yield, minimize resource usage, and reduce environmental impact, making agriculture more sustainable and economically viable.',
      date: '2024-12-05',
      status: 3,
    },
    {
      Projectid: '6751aa2d170602bdfa3b9687',
      projectName: 'Global Education Reform',
      description:
        'Global Education Reform is a project dedicated to revolutionizing the education system by implementing digital tools and online learning platforms. The initiative aims to make education more accessible, reduce disparities in learning opportunities, and ensure that every student has the resources they need to succeed in the modern world.',
      date: '2024-11-25',
      status: 0,
    },
    {
      Projectid: '6751aae0170602bdfa3b9696',
      projectName: 'Blockchain for Transparency',
      description:
        'Blockchain for Transparency focuses on utilizing blockchain technology to enhance transparency and accountability in government and corporate sectors. The project seeks to create an immutable ledger that tracks transactions, reducing fraud, improving trust, and making processes more transparent for the general public.',
      date: '2024-12-12',
      status: 2,
    },
  ];


  const handleProjectClick = async (projectId) => {
    // console.log('Project ID:', projectId);
    navigate(`/edit-project/${projectId}`);
  };

  const handleInitProject = async (projectId) => {
    // console.log('Project ID:', projectId);
    navigate(`/create-new-project`);
  };


  const sortedProjects = [...projects].sort((a, b) => new Date(a.date) - new Date(b.date));

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        setLoading(true);
        const response = await GetInitProjectList(dispatch);
        const ProjectListData = response?.data || [];

        const SortedProjectList = [...ProjectListData].sort((a, b) => {
          const dateA = a.CreatedAt ? new Date(a.CreatedAt) : new Date(0);
          const dateB = b.CreatedAt ? new Date(b.CreatedAt) : new Date(0);

          if (isNaN(dateA.getTime())) return 1;
          if (isNaN(dateB.getTime())) return -1;

          return dateB - dateA;
        });

        setprojectsList(SortedProjectList);

        // console.log('Project data:', response?.data);          

      } catch (error) {
        console.error('Error fetching project data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjectData();
  }, []);


  return (


    <>
      <Backdrop
        open={loading}
        sx={{
          color: '#2e7d32',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="flex flex-col items-center space-y-6 p-6">

        <Box pl={3} pr={3} className='w-full' display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant="h4" className="text-left text-2xl font-semibold text-gray-800 mb-8">
            Projects
          </Typography>

          <Button
            variant="contained"
            onClick={handleInitProject}
            startIcon={<MdAdd />}
            sx={{
              backgroundColor: "#4caf50",
              textTransform: "none",
              color: "#fff",
              borderRadius: "25px",
              // borderRadius: "8px",
              // padding: "10px 10px",
              padding: "10px 30px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              transition: "background-color 0.3s, transform 0.2s",
              "&:hover": {
                backgroundColor: "#388e3c",
                transform: "scale(1.05)",
              },
            }}
          >
            Create New Project
          </Button>
        </Box>

        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-8 w-full">
          {projectsList && projectsList.length > 0 ? (
            projectsList.map((project, index) => {

              // const statusPercentage = 
              // (typeof project.Step === 'number' && !isNaN(project.Step)) ? 
              // (project.Step / 5) * 100 : 0;

              const statusPercentage = project.Step != null ? (project.Step / 5) * 100 : 0;

              return (
                <Card
                  elevation={4}
                  sx={{ borderRadius: '12px' }}
                  key={index}
                  className="bg-white shadow-xl hover:shadow-2xl transition-all duration-300 ease-in-out transform hover:scale-102"
                  style={{
                    borderLeft: `5px solid #4caf50`,
                    cursor: 'pointer',
                  }}
                  onClick={() => handleProjectClick(project.ProjectID)}
                >
                  <CardContent>
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={'1rem'}>
                      <Box flex={1}>
                        <Typography variant="h6" textTransform={'capitalize'} className="font-semibold text-gray-900 mb-2">
                          {project.ProjectName}
                        </Typography>
                        <Typography
                          variant="body2"
                          className="text-gray-700 mb-4 h-fit"
                          style={{
                            maxHeight: '100px', 
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          <ReactMarkdown>{project.Requirements}</ReactMarkdown>
                        </Typography>

                        {/* <Typography variant="body2" className="text-gray-500">
                          <strong>Date: </strong>
                          {new Date(project.CreatedAt).toLocaleDateString()}
                        </Typography> */}

                        {project.CreatedAt && (
                          <>
                          
                          <Typography variant="body2" className="text-gray-500">
                            <strong>Started: </strong>
                            {new Date(project.CreatedAt).toLocaleDateString()}
                          </Typography>
                            


                          </>
                        )}

                          <Chip
                              label={`Step ${project?.Step ? project.Step + 1 : 1} of 6`}
                              sx={{
                                marginTop:2,
                                border: '1px solid #bdbdbd',
                                padding: '0 5px',
                                borderRadius: '16px',
                                fontSize: '0.875rem',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                backgroundColor:'transparent',
                                textOverflow: 'ellipsis',
                                '& .MuiChip-label': {
                                  paddingLeft: '7px',
                                  paddingRight: '7px',
                                }
                              }}
                             />

                      </Box>

                      <Box position="relative" display="flex" justifyContent="center" alignItems="center">
                        <CircularProgressWithLabel value={statusPercentage} />
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              );
            })
          ) : (
            !loading && (
                <div style={{padding:3, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', gridColumn: 'span 2', }} className="w-full text-gray-500">
                  
                  <Typography textAlign={'center'}>
                  No Project Found
                  
                  </Typography>
                </div>
            )
          )}
        </div>

      </div>

    </>


  );
};

export default InitProjectLists;






























// import React from 'react';
// import { Box, Card, CardContent, Typography } from '@mui/material';

// const InitProjectLists = () => {
//   // Hardcoded project data with realistic names and descriptions
//   const projects = [
//     {
//       projectName: 'GreenTech Initiative',
//       description:
//         'GreenTech Initiative is a groundbreaking project aimed at utilizing renewable energy sources to power cities. The project focuses on the development of solar power systems that can be scaled for both residential and industrial use, helping to reduce global carbon emissions and mitigate climate change.',
//       date: '2024-12-10',
//       status:2,
//     },
//     {
//       projectName: 'Urban Mobility Solutions',
//       description:
//         'Urban Mobility Solutions is designed to tackle the problem of traffic congestion in metropolitan cities. By integrating autonomous vehicles and electric scooters with AI-powered traffic management systems, the project aims to make cities more accessible, reduce emissions, and improve public transportation efficiency.',
//       date: '2024-12-01',
//       status:5,

//     },
//     {
//       projectName: 'AI for Healthcare',
//       description:
//         'AI for Healthcare is an innovative initiative that leverages machine learning and big data to enhance diagnostics and treatment plans. By integrating AI tools into the healthcare system, this project aims to improve the accuracy of diagnoses, accelerate drug discovery, and make healthcare more accessible to underserved communities.',
//       date: '2024-12-15',
//       status:4,
//     },
//     {
//       projectName: 'Smart Agriculture Project',
//       description:
//         'The Smart Agriculture Project aims to introduce precision farming techniques to small-scale farmers by utilizing IoT devices and AI-driven analytics. The goal is to optimize crop yield, minimize resource usage, and reduce environmental impact, making agriculture more sustainable and economically viable.',
//       date: '2024-12-05',
//       status:3,
//     },
//     {
//       projectName: 'Global Education Reform',
//       description:
//         'Global Education Reform is a project dedicated to revolutionizing the education system by implementing digital tools and online learning platforms. The initiative aims to make education more accessible, reduce disparities in learning opportunities, and ensure that every student has the resources they need to succeed in the modern world.',
//       date: '2024-11-25',
//       status:0,
//     },
//     {
//       projectName: 'Blockchain for Transparency',
//       description:
//         'Blockchain for Transparency focuses on utilizing blockchain technology to enhance transparency and accountability in government and corporate sectors. The project seeks to create an immutable ledger that tracks transactions, reducing fraud, improving trust, and making processes more transparent for the general public.',
//       date: '2024-12-12',
//       status:2,
//     },
//   ];


//   const sortedProjects = [...projects].sort((a, b) => new Date(a.date) - new Date(b.date));

//   return (
//     <div className="flex flex-col items-center space-y-6 p-6">
//       <Typography
//         variant="h4"
//         className="text-center text-2xl font-semibold text-gray-800 mb-8"
//       >
//         Projects
//       </Typography>

//       <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-8 w-full">
//         {sortedProjects.map((project, index) => (
          
//             <Card
//                 elevation={4}
//                 sx={{borderRadius:'12px'}}
//                 key={index}
//                 className="bg-white shadow-xl hover:shadow-2xl transition-all duration-300 ease-in-out transform hover:scale-102"
//                 style={{
//                     borderLeft: `5px solid #4caf50`,
//                 }}
//             >
//             <CardContent>

//               <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={'0.5rem'}>
//                 <Box>
//                   <Typography
//                     variant="h6"
//                     className="text-lg font-semibold text-gray-900 mb-2"
//                   >
//                     {project.projectName}
//                   </Typography>
//                   <Typography
//                     variant="body2"
//                     className="text-gray-700 mb-4"
//                     style={{
//                       height: '100px', // Fixed height for descriptions to maintain uniformity
//                       overflow: 'hidden',
//                       textOverflow: 'ellipsis',
//                     }}
//                   >
//                     {project.description}
//                   </Typography>
//                   <Typography
//                     variant="body2"
//                     className="text-gray-500"
//                   >
//                     <strong>Date: </strong>{new Date(project.date).toLocaleDateString()}
//                   </Typography>
//                 </Box>
//                 <Box>
//                 </Box>
//               </Box>
//             </CardContent>
//           </Card>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default InitProjectLists;












// const InitProjectLists = () => {
//   return (
//     <div>InitProjectLists</div>
//   )
// }

// export default InitProjectLists