import axios from "axios";
import { signOut } from "../redux/reduxslices/userSlices";
import { clearProjectId } from "../redux/reduxslices/ProjectIdSlice";

const handleLogout = (dispatch) => {
    dispatch(signOut({ user: null, token: null }));
    dispatch(clearProjectId());
    // dispatch(logout());
};

const getAxiosInstance = (dispatch) => {
    const instance = axios.create({
        // baseURL: "http://localhost:4000/api",
        baseURL: "https://us-central1-sampleprojectforusman.cloudfunctions.net/taskmanagerserver/api",
        // baseURL: "https://us-central1-islam360-stagging.cloudfunctions.net/taskmanagerserver/api",
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
        },
    });

    // Add a response interceptor
    instance.interceptors.response.use(
        response => response,
        error => {
            if (error.response && error.response.data.message === "Invalid token") {
                // // Assuming you have a Redux action called `logout`
                handleLogout(dispatch);

                // dispatch(signOut({ user: null, token: null }));
                // dispatch(clearProjectId());
            }
            return Promise.reject(error);
        }
    );

    return instance;
};

// Generic Axios call cancellation handler
export const apiCallWithAxiosCancel = (() => {
    let cancelTokenSource = null;

    return async (apiCallFunction) => {

        // Cancel any ongoing request
        if (cancelTokenSource) {
            cancelTokenSource.cancel('Operation canceled due to a new request.');
        }

        // Create a new cancel token for the next request
        cancelTokenSource = axios.CancelToken.source();

        try {
            // Execute the API call function with the cancel token
            const response = await apiCallFunction(cancelTokenSource.token);
            cancelTokenSource = null; // Reset the cancel token after successful call
            return response; // Return the response from the API call

        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message);
            } else {
                console.error('API call failed:', error);
            }
            throw error; // Rethrow error for further handling
        }
    };
})();

// Function to fetch projects
export const fetchProjectsData = async (dispatch) => {
    const axiosInstance = getAxiosInstance(dispatch)
    const response = await axiosInstance.get("/projects");
    return response;
    // const response = await apiClient
    //   .get("/projects", {
    //     headers: getAuthHeaders(),
    //   })
    // return response;
};

// Function to fetch projects
export const fetchUsersData = async (dispatch) => {
    const axiosInstance = getAxiosInstance(dispatch)
    const response = await axiosInstance.get("/users");
    return response;
};

// Function to Create a new  projects
export const createNewProject = async (payload, dispatch) => {
    const axiosInstance = getAxiosInstance(dispatch)
    const response = await axiosInstance.post("/projects", payload);
    console.log("Project created:", response.data);
    return response;
};

// Function to Update a projects
export const updateProject = async (projectId, payload, dispatch) => {
    const axiosInstance = getAxiosInstance(dispatch)
    const response = await axiosInstance.put(`/projects/${projectId}`, payload);
    console.log("Project created:", response.data);
    return response;
};

// Function to fetch a project by ID
export const GetfetchProjectById = async (projectId, dispatch) => {
    const axiosInstance = getAxiosInstance(dispatch)
    const response = await axiosInstance.get(`/projects/${projectId}`);
    return response;
};

// Function to Create a new  DailyTask
export const createDailyTask = async (payload, dispatch) => {
    const axiosInstance = getAxiosInstance(dispatch)
    const response = await axiosInstance.post("/daily-tasks", payload);
    console.log("Project created:", response.data);
    return response;
};

// Function to add a new AddSprint
export const AddSprint = async (payload, dispatch) => {
    const axiosInstance = getAxiosInstance(dispatch)
    const response = await axiosInstance.post("/sprints", payload);
    console.log("Sprints Created:", response);
    return response;
};

// Function to Update a Sprint
export const updateSprint = async (projectId, payload, dispatch) => {
    const axiosInstance = getAxiosInstance(dispatch)
    const response = await axiosInstance.put(`/sprints/${projectId}`, payload);
    console.log("Project created:", response.data);
    return response;
};

// Function to add a new task
export const addTask = async (newTask, dispatch) => {
    const axiosInstance = getAxiosInstance(dispatch);
    const response = await axiosInstance.post(`/tasks`, newTask);
    console.log("Task Created:", response);
    return response;
};

// Function to update a task
export const updateTask = async (taskId, payload, dispatch) => {
    const axiosInstance = getAxiosInstance(dispatch)
    const response = await axiosInstance.put(`/tasks/${taskId}`, payload);
    // console.log("Project created:", response.data);
    return response;
};

// Function to fetch a activity by ID
export const getactivities = async (projectId, dispatch) => {
    const axiosInstance = getAxiosInstance(dispatch)
    const response = await axiosInstance.get(`/activities/${projectId}`);
    return response;
};

// Function to delete a sprint by ID
export const DeleteSprint = async (sprintId, dispatch) => {
    const axiosInstance = getAxiosInstance(dispatch);
    const response = await axiosInstance.delete(`/sprints/${sprintId}`);
    return response;
};

// Function to Create High-Level Requirements
export const createHighLevelRequirements = async (payload, dispatch) => {
    const axiosInstance = getAxiosInstance(dispatch)
    const response = await axiosInstance.post("/requirements/generate-requirements", payload);
    console.log("High-Level Requirements created:", response.data);
    return response;
};

// Function to Create Detailed Work Breakdown
export const createDetailedWorkBreakdown = async (payload, dispatch) => {
    const axiosInstance = getAxiosInstance(dispatch);
    const response = await axiosInstance.post("/requirements/generate-detailed-work-breakdown", payload);
    console.log("Detailed Work Breakdown created:", response.data);
    return response;
};

// Function to Create Figma Design
export const generateFigmaDesign = async (payload, dispatch) => {
    const axiosInstance = getAxiosInstance(dispatch);
    const response = await axiosInstance.post("/requirements/generate-figma-design", payload);
    console.log("Figma Design generated:", response.data);
    return response;
};

// Function to fetch a Init-project by ID
export const GetInitProjectById = async (projectId, dispatch) => {
    const axiosInstance = getAxiosInstance(dispatch)
    const response = await axiosInstance.get(`/requirements/get-data/${projectId}`);
    return response;
};


// Function to update a Details Project Doc
export const UpdDetailsProjectDoc = async (taskId, payload, dispatch) => {
    const axiosInstance = getAxiosInstance(dispatch)
    const response = await axiosInstance.put(`/requirements/edit-requirements/${taskId}`, payload);
    // console.log("Project created:", response.data);
    return response;
};

// Function to update a Details Project WorkFlowBreakDown
export const UpdWorkFlowBreakdown = async (taskId, payload, dispatch) => {
    const axiosInstance = getAxiosInstance(dispatch)
    const response = await axiosInstance.put(`/requirements/edit-detailed-work-breakdown/${taskId}`, payload);
    // console.log("Project created:", response.data);
    return response;
};

// Function to update a Details Project Figma Design
export const UpdFigmaDesign = async (taskId, payload, dispatch) => {
    const axiosInstance = getAxiosInstance(dispatch)
    const response = await axiosInstance.put(`/requirements/edit-figma-design-response/${taskId}`, payload);
    // console.log("Project created:", response.data);
    return response;
};

// Function to fetch a Init-project Lists
export const GetInitProjectList = async (dispatch) => {
    const axiosInstance = getAxiosInstance(dispatch)
    const response = await axiosInstance.get(`/requirements/projects/`);
    return response;
};



// http://localhost:4000/api/requirements/get-data/6751a3ac50757ea0bc525bdb









// Modified SaveTradingName function with CancelToken
export const SaveTradingName = async (payload, dispatch, cancelToken) => {
    const axiosInstance = getAxiosInstance(dispatch);

    try {
        // Making the API call and passing the cancel token
        const response = await axiosInstance.post("/business/create-trade-name/", payload, {
            cancelToken,  // Pass the cancel token to allow for cancellation
        });

        return response; // Return the whole response object as it is
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled');
        } else {
            console.error('API call failed:', error);
        }
        throw error;  // Rethrow error to handle it in the calling function
    }
};