import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { TextField, Button, Typography, Paper, Box, Grid, CircularProgress } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const emailRegex = /^[a-zA-Z0-9._%+-]+@techtics\.ai$/;

const validationSchema = Yup.object({
    email: Yup.string()
        .matches(emailRegex, "Email must end with @techtics.ai")
        .required("Email is required"),
});

const ForgotPassword = () => {

    const navigate = useNavigate();

    const [loader, setLoader] = useState(false);

    const handleSubmit = async (values, { setSubmitting, setErrors }) => {
        setLoader(true);
        try {
            console.log(`Submitting values for Forgetting Password: ${values.email}`);

            // const response = await resetPassword(values.email);

            toast.success("Password reset link sent to your email!", {
                position: "top-right",
                autoClose: 1000,
                toastId: "reset-success",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });

            navigate("/reset-password");

        } catch (error) {
            toast.error("Error resetting password. Please try again.", {
                position: "top-right",
                autoClose: 1000,
                toastId: "reset-error",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            console.error("Error during password reset:", error);
            setErrors({ submit: error.response?.data?.message || "Error resetting password" });
        } finally {
            setLoader(false);
            setSubmitting(false);
        }
    };

    return (
        <Box className="flex justify-center items-center min-h-[85vh] bg-gray-100 p-4">
            <Paper elevation={5} sx={{ borderRadius: "13px" }} className="w-full max-w-md p-8 shadow-lg">
                <Typography variant="h4" component="h1" className="text-center mb-6 font-semibold text-gray-800">
                    Forgot Password
                </Typography>
                <Formik
                    initialValues={{ email: "" }}
                    validationSchema={validationSchema}  
                    onSubmit={handleSubmit}
                >
                    {({ values, handleChange, handleBlur, handleSubmit, errors, touched }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography mt={2} mb={0.3} variant="body1" className="mb-3 font-medium">
                                        Email Address
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.email && Boolean(errors.email)}
                                        helperText={touched.email && errors.email}
                                        style={{ backgroundColor: "white" }}
                                    />
                                </Grid>
                            </Grid>
                            <div className="flex justify-center mt-4">
                                <Button
                                    variant="contained"
                                    type="submit"
                                    sx={{
                                        backgroundColor: "#4caf50",
                                        textTransform: "capitalize",
                                        color: "#fff",
                                        borderRadius: "8px",
                                        padding: "10px 20px",
                                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                        transition: "background-color 0.3s, transform 0.2s",
                                        "&:hover": {
                                            backgroundColor: "#388e3c",
                                            transform: "scale(1.05)",
                                        },
                                    }}
                                    disabled={loader}
                                    endIcon={loader ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : null}
                                >
                                    Reset Password
                                </Button>
                            </div>
                        </form>
                    )}
                </Formik>
            </Paper>

            <ToastContainer />
        </Box>
    );
};

export default ForgotPassword;