import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    projectId: localStorage.getItem("projectId") || null, // Initialize from local storage if available
};

const projectIdSlice = createSlice({
    name: "project",
    initialState,
    reducers: {
        setProjectId(state, action) {
            state.projectId = action.payload;
            localStorage.setItem("projectId", action.payload); // Save projectId to local storage
        },

        clearProjectId(state) {
            state.projectId = null;
            localStorage.removeItem("projectId"); // Remove projectId from local storage
        },
    },
});

// Exporting the actions
export const { setProjectId, clearProjectId } = projectIdSlice.actions;

// Exporting the reducer
export default projectIdSlice.reducer;
