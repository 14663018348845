import { configureStore } from '@reduxjs/toolkit';
import userSlices from './reduxslices/userSlices';
import ProjectIdSlice from './reduxslices/ProjectIdSlice';
import FilterSlices from "./reduxslices/FilterSlices"


const store = configureStore({
    reducer: {
        user: userSlices,
        projectId: ProjectIdSlice,
        filterSlices: FilterSlices
    },
});

export default store;
