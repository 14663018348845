import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { TextField, Button, Typography, Paper, Box, Grid, CircularProgress, IconButton } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa"; 


const validationSchema = Yup.object({
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm password is required"),
});  

const ResetPassword = () => {
  const [loader, setLoader] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setLoader(true);
    try {
      console.log(`Resetting password for: ${values}`);

      // Simulate API call for password reset
      // const response = await resetPassword(values.password);

      toast.success("Password has been reset successfully!", {
        position: "top-right",
        autoClose: 1000,
        toastId: "reset-success",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      navigate("/login");
    } catch (error) {
      toast.error("Error resetting password. Please try again.", {
        position: "top-right",
        autoClose: 1000,
        toastId: "reset-error",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      console.error("Error during password reset:", error);
      setErrors({ submit: error.response?.data?.message || "Error resetting password" });
    } finally {
      setLoader(false);
      setSubmitting(false);
    }
  };

  return (
    <Box className="flex justify-center items-center min-h-[85vh] bg-gray-100 p-6">
      <Paper elevation={5} sx={{ borderRadius: "12px" }} className="w-full max-w-md p-10 shadow-lg">
        <Typography variant="h5" component="h1" className="text-center mb-6 font-semibold text-gray-800">
          Reset Your Password
        </Typography>
        <Formik
          initialValues={{ password: "", confirmPassword: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleBlur, handleSubmit, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={1} padding={2}>
                <Grid item xs={12}>
                  <Typography mb={0.4} variant="body1" className="font-medium">
                    New Password
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="password"
                    type={passwordVisible ? "text" : "password"}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    style={{ backgroundColor: "white" }}
                    sx={{
                      fontSize: "16px",
                    }}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={() => setPasswordVisible(!passwordVisible)}
                          edge="end"
                        >
                          {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                        </IconButton>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography mb={0.4} variant="body1" className="font-medium">
                    Confirm Password
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="confirmPassword"
                    type={confirmPasswordVisible ? "text" : "password"}
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                    helperText={touched.confirmPassword && errors.confirmPassword}
                    style={{ backgroundColor: "white" }}
                    sx={{
                      fontSize: "16px",
                    }}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                          edge="end"
                        >
                          {confirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                        </IconButton>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <div className="flex justify-center mt-6">
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    backgroundColor: "#4caf50",
                    textTransform: "capitalize",
                    color: "#fff",
                    borderRadius: "8px",
                    padding: "12px 30px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    transition: "background-color 0.3s, transform 0.2s",
                    "&:hover": {
                      backgroundColor: "#388e3c",
                      transform: "scale(1.05)",
                    },
                  }}
                  disabled={loader}
                  endIcon={loader ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : null}
                >
                  {loader ? "Resetting..." : "Reset Password"}
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Paper>

      <ToastContainer />
    </Box>
  );
};

export default ResetPassword;
