import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Modal,
  TextField,
  MenuItem,
  CircularProgress,
  Backdrop,
  Chip,
  InputAdornment,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { addTask, updateTask } from "../api/appApi";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";
import { signOut } from "../redux/reduxslices/userSlices";
import { clearProjectId } from "../redux/reduxslices/ProjectIdSlice";

const TrelloBoard = ({ data, onUpdate }) => {
  // console.log( `Data has been Succeessfully received in TrelloBoard.jsx ${data}`);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const UserRole = useSelector((state) => state.user?.user?.role);
  const UserId = useSelector((state) => state.user?.user?.userid);
  // console.log(`UserId is ${UserId}`);
  const [projectData, setProjectData] = useState(null);
  const [projectId, setProjectId] = useState("");
  const [userId, setUserId] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [SprintData, setSprintData] = useState([]);
  const [selectedSprint, setSelectedSprint] = useState(null);
  const [selectedTask, setSelectedTask] = useState("");

  const today = new Date();



  const priorityMap = {
    "High": 3,
    "Medium": 2,
    "Low": 1
  };

  useEffect(() => {
    if (data) {
      setProjectData(data);
      setProjectId(data.project._id);
      setTasks(data.project.tasks);
      setUserId(data.project.members);
      setSprintData(data.project.sprints);
      // console.log(userId);
    }
  }, [data]);

  const [openModal, setOpenModal] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);
  const [newTask, setNewTask] = useState({ title: "", status: "Not Started", description: "", assignDate: "", sprintname: '', sprintcatname: '', dueDate: "", etaDate: "", assign: [], projectId: "", priority: "", });

  const priorities = ["High", "Medium", "Low"];
  const statuses_ = [
    { id: "backlog", title: "Backlogs", style: { backgroundColor: "#fce4ec" } },
    { id: "notStarted", title: "Not Started", style: { backgroundColor: "#f5f5f5" } },
    { id: "inProgress", title: "In Progress", style: { backgroundColor: "#e3f2fd" } },
    { id: "done", title: "Done", style: { backgroundColor: "#e8f5e9" } },
    { id: "finished", title: "Finished", style: { backgroundColor: "#fce4ec" } }];

  const [dialogueOpen, setDialogueOpen] = useState(false);
  const handleOpenDialogue = () => setDialogueOpen(true);
  const handleCloseDialogue = () => setDialogueOpen(false);
  const [droppedItem, setDroppedItem] = useState(null);
  const [checked, setChecked] = useState({ deploy: false, test: false, document: false });

  const checkboxes = [
    { name: 'deploy', label: 'Did you deploy the code?' },
    { name: 'test', label: 'Did you test the application?' },
    { name: 'document', label: 'Did you document your code?' }];

  const handleCheckboxChange = (event) => {
    setChecked({
      ...checked,
      [event.target.name]: event.target.checked,
    });
  };

  const statuses = UserRole !== "admin" ? statuses_.filter((status) => status.id !== "finished") : statuses_;

  const handleOpen = (task, status = "Backlogs") => {
    if (task) {
      const formattedTask = {
        ...task,
        assignDate: formatDate(task.assignDate),
        dueDate: formatDate(task.dueDate),
        etaDate: formatDate(task.etaDate),
      };
      setCurrentTask(task);
      setNewTask(formattedTask);
      // setCurrentTask(task);
      // setNewTask(task);
    } else {
      setCurrentTask(null);
      setNewTask({ title: "", status: status, description: "", assignDate: "", sprintname: '', sprintcatname: '', dueDate: "", etaDate: "", assign: "", projectId: projectId });
    }
    setOpenModal(true);
  };

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Return an empty string if dateString is null or undefined
    const [day, month, year] = dateString.split("/");
    return `${year}-${month}-${day}`; // Converts to YYYY-MM-DD
  };

  const handleClose = () => {
    setOpenModal(false);
    setCurrentTask(null);
  };

  const handleUpdateTask = async () => {

    if (newTask.status === "Done") {

      setChecked({ deploy: false, test: false, document: false });
      setDroppedItem({
        id: newTask["_id"],
        statusTitle: newTask.status,
      });
      setDialogueOpen(true);
      handleClose();
      return;
    }

    setLoading(true);

    try {
      const newTask_JsonObj = {
        title: newTask.title,
        status: newTask.status,
        description: newTask.description,
        assignDate: newTask.assignDate,
        dueDate: newTask.dueDate,
        etaDate: newTask.etaDate,
        assign: newTask.assign,
        projectId: newTask.project,
        priority: newTask.priority,
        UserId: UserId,
        sprintname: newTask.sprintname,
        sprintcatname: newTask.sprintcatname,
      };

      console.log(newTask_JsonObj);

      const response = await updateTask(newTask._id, newTask_JsonObj, dispatch);
      const updatedTask = response.data.task;
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task._id === updatedTask._id ? { ...updatedTask } : task
        )
      );

      const updatedProjectData = {
        ...projectData,
        project: {
          ...projectData.project,
          tasks: [
            ...tasks.map((task) =>
              task._id === updatedTask._id ? updatedTask : task
            ),
          ],
        },
      };
      setProjectData(updatedProjectData);
      onUpdate(updatedProjectData);
      setOpenModal(false);
      toast.success("🎉 Task has been update successfully!", {
        position: "top-right",
        autoClose: 1000,
        toastId: "upd-task-success",
        hideProgressBar: false,

      });
    } catch (error) {
      console.error("Error updating task:", error);
      setOpenModal(false);
      toast.error(
        "Oops! There was an issue updating the task. Please try again!",
        {
          position: "top-right",
          autoClose: 1000,
          toastId: "task-error",
          hideProgressBar: false,
        }
      );

      // if (error.response && error.response.data.message === "Invalid token") {
      //   toast.error(
      //     "Your session has expired. Please sign in again to continue.",
      //     {
      //       position: "top-right",
      //       autoClose: 1000,
      //       toastId: "token-error",
      //       hideProgressBar: false,
      //     }
      //   );
      //   console.error("Invalid token. Please log in again.");
      //   setTimeout(() => {
      //     dispatch(signOut({ user: null, token: null }));
      //     dispatch(clearProjectId());
      //     navigate("/signout");
      //   }, 1000);
      // }
      // else {
      //   toast.error(
      //     "Oops! There was an issue updating the task. Please try again!",
      //     {
      //       position: "top-right",
      //       autoClose: 1000,
      //       toastId: "task-error",
      //       hideProgressBar: false,
      //     }
      //   );
      // }

    } finally {
      setLoading(false);
    }
    setCurrentTask(null);
  };

  const handleAddTask = async () => {
    if (
      newTask.assign.length > 0 &&
      newTask.title.trim() !== "") {
      setLoading(true);

      try {
        // console.log(newTask);
        const updatedTask = {
          ...newTask,
          UserId: UserId,
        };

        console.log(updatedTask);

        const response = await addTask(updatedTask, dispatch);
        const createdTask = response.data;
        setTasks((prevTasks) => [
          ...prevTasks,
          { _id: createdTask._id, ...createdTask },
        ]);

        const updatedProjectData = {
          ...projectData,
          project: {
            ...projectData.project,
            tasks: [...tasks, { _id: createdTask._id, ...createdTask }],
          },
        };
        setProjectData(updatedProjectData);
        onUpdate(updatedProjectData);
        setOpenModal(false);

        toast.success("🎉 Task has been added successfully!", {
          position: "top-right",
          autoClose: 1000,
          toastId: "add-task-success",
          hideProgressBar: false,
        });
      } catch (error) {
        console.error("Error adding task:", error);
        setOpenModal(false);

        toast.error(
          "Oops! There was an issue adding the task. Please try again!",
          {
            position: "top-right",
            autoClose: 1000,
            toastId: "task-error",
            hideProgressBar: false,

          }
        );

        // if (error.response && error.response.data.message === "Invalid token") {
        //   toast.error(
        //     "Your session has expired. Please sign in again to continue.",
        //     {
        //       position: "top-right",
        //       autoClose: 1000,
        //       toastId: "token-error",
        //       hideProgressBar: false,

        //     }
        //   );
        //   console.error("Invalid token. Please log in again.");
        //   setTimeout(() => {
        //     dispatch(signOut({ user: null, token: null }));
        //     dispatch(clearProjectId());
        //     navigate("/signout");
        //   }, 1000);
        // } else {
        //   toast.error(
        //     "Oops! There was an issue adding the task. Please try again!",
        //     {
        //       position: "top-right",
        //       autoClose: 1000,
        //       toastId: "task-error",
        //       hideProgressBar: false,
        //     }
        //   );
        // }

      } finally {
        setLoading(false);
      }


    } else {
      toast.error("Field Should not be empty", {
        position: "top-right",
        autoClose: 1000,
        toastId: "task-error",
        hideProgressBar: false,
      });
    }
    setOpenModal(false);
  };

  const handleChipChange = (value) => {
    setNewTask((prev) => {
      const isAlreadyAssigned = prev.assign.includes(value);
      if (isAlreadyAssigned) {
        return {
          ...prev,
          assign: prev.assign.filter((user) => user !== value),
        };
      } else {
        return { ...prev, assign: [...prev.assign, value] };
      }
    });
  };

  const handleDialogSubmit = async () => {
    const allChecked = Object.values(checked).every(Boolean);

    if (allChecked && droppedItem) {
      const taskId = droppedItem.id;

      const taskToUpdate = tasks.find((task) => task._id === taskId);
      if (!taskToUpdate) return;

      const updatedTask = {
        ...taskToUpdate,
        status: droppedItem.statusTitle,
        assignDate: formatDate(taskToUpdate.assignDate),
        dueDate: formatDate(taskToUpdate.dueDate),
        UserId: UserId,
        sprintname: newTask.sprintname,
        sprintcatname: newTask.sprintcatname,
        etaDate: formatDate(taskToUpdate.etaDate),
      };

      console.log("updatedTask data is ", updatedTask);


      try {
        setLoading(true);

        const response = await updateTask(taskId, updatedTask, dispatch);
        const responseData = response.data.task;
        setTasks((prevTasks) =>
          prevTasks.map((task) =>
            task._id === responseData._id ? responseData : task
          )
        );
        const updatedProjectData = {
          ...projectData,
          project: {
            ...projectData.project,
            tasks: tasks.map((task) =>
              task._id === responseData._id ? responseData : task
            ),
          },
        };

        setProjectData(updatedProjectData);
        onUpdate(updatedProjectData);

        toast.success("🎉 Task has been updated successfully!", {
          position: "top-right",
          autoClose: 1000,
          toastId: "upd-task-success",
          hideProgressBar: false,
        });
      } catch (error) {
        console.error("Error updating task:", error);
        toast.error(
          "Oops! There was an issue updating the task. Please try again!",
          {
            position: "top-right",
            autoClose: 1000,
            toastId: "task-upd-error",
            hideProgressBar: false,
          }
        );

      } finally {
        setDialogueOpen(false);
        setLoading(false);

      }
    } else {
      toast.error("Please select all activities before submitting.", {
        position: "top-right",
        autoClose: 1000,
        toastId: "act-task-error",
        hideProgressBar: false,
      });
    }
  };

  const TaskCard = ({ task }) => {

    const [{ isDragging }, drag] = useDrag(() => ({
      type: "CARD",
      item: { id: task._id },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }));

    return (
      <Card
        sx={{ borderRadius: '8px' }}
        ref={drag}
        className={`p-0 mb-4 shadow-md cursor-pointer ${isDragging ? "opacity-50" : ""}`}
      >
        <CardContent style={{ display: "flex", justifyContent: "space-between", alignItems: "", width: "100%", gap: '0.3rem' }}>
          <div style={{ width: "93%" }} className="flex flex-col gap-1">
            <Typography style={{ flexGrow: 1 }}>{task.title}</Typography>

            <Box
              className="flex gap-1 scrollable"
              sx={{
                display: "flex", overflowX: "auto", whiteSpace: "nowrap",
                "&::-webkit-scrollbar": { height: "6px", },
                "&::-webkit-scrollbar-thumb": { backgroundColor: "#888", },
              }}
            >
              {task.assign.map((name, index) => (
                <Chip
                  key={index}
                  label={name}
                  className="m-1 bg-blue-500 text-white"
                  sx={{ flexShrink: 0, overflow: "hidden", textOverflow: "ellipsis", }}
                />
              ))}
            </Box>

            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
              <div
                style={{
                  color: task.priority === "High" ? "rgb(230, 57, 70)" : task.priority === "Medium" ? "rgb(241, 196, 15)" : task.priority === "Low" ? "rgb(39, 174, 96)" : "transparent",
                  backgroundColor: task.priority === "High" ? "rgba(230, 57, 70, 0.2)" : task.priority === "Medium" ? "rgba(241, 196, 15, 0.2)" : task.priority === "Low" ? "rgba(39, 174, 96, 0.2)" : "transparent",
                  padding: "1px 4px", borderRadius: "6px", fontWeight: "600", display: "inline-block",
                }}
              >
                {task.priority || ""}
              </div>
              <div>{task?.dueDate || ""}</div>
            </div>
          </div>

          {
            UserRole && UserRole !== 'client' &&
            <div style={{ width: "5%" }} className="flex float-right">
              <span onClick={() => handleOpen(task)} style={{ padding: 0 }}>
                <FontAwesomeIcon icon={faPen} className="icon" />
              </span>
            </div>
          }
        </CardContent>
      </Card>
    );
  };

  const StatusColumn = ({ status }) => {
    const [, drop] = useDrop({
      accept: "CARD",
      drop: async (item) => {

        if (UserRole === 'client') {
          return;
        }

        const taskId = item.id;

        if (status.title === "Done") {
          const taskToUpdate = tasks.find((task) => task._id === taskId);

          if (status.title == taskToUpdate.status) {
            console.log("Card Status Matched");
            return
          }

          setChecked({ deploy: false, test: false, document: false, });
          setDroppedItem({
            ...item,
            statusTitle: status.title,
          });
          setDialogueOpen(true);
          return;
        }

        else {
          const taskToUpdate = tasks.find((task) => task._id === taskId);

          if (!taskToUpdate) return;

          if (status.title == taskToUpdate.status) {
            console.log("Card Status Matched");
            return
          }

          const updatedTask = {
            ...taskToUpdate,
            status: status.title,
            assignDate: formatDate(taskToUpdate.assignDate),
            dueDate: formatDate(taskToUpdate.dueDate),
            UserId: UserId,
            etaDate: formatDate(taskToUpdate.etaDate),
          };
          setLoading(true);
          try {
            const response = await updateTask(taskId, updatedTask, dispatch);
            const responseData = response.data.task;
            // console.log(`Task has been updated successfully:`, responseData);
            setTasks((prevTasks) =>
              prevTasks.map((task) =>
                task._id === responseData._id ? responseData : task
              )
            );
            const updatedProjectData = {
              ...projectData,
              project: {
                ...projectData.project,
                tasks: tasks.map((task) =>
                  task._id === responseData._id ? responseData : task
                ),
              },
            };

            setProjectData(updatedProjectData);
            onUpdate(updatedProjectData);

            toast.success("🎉 Task has been update successfully!", {
              position: "top-right",
              autoClose: 1000,
              toastId: "upd-task-success",
              hideProgressBar: false,
            });
          } catch (error) {
            console.error("Error updating task:", error);

            toast.error(
              "Oops! There was an issue updating the task. Please try again!",
              { position: "top-right", autoClose: 1000, toastId: "task-up-error", hideProgressBar: false }
            );

          } finally {
            setLoading(false);
          }
        }


      },
    });
    const UserRole = useSelector((state) => state.user?.user?.role);
    //  console.log(`UserRole from status coloumn ${UserRole}`)

    return (
      <div
        ref={drop}
        className={`flex flex-col rounded-lg p-3 ${UserRole !== "admin" ? "w-1/4" : "w-1/5"}`}
        style={status.style}
      >
        <Typography variant="h6" sx={{ marginBottom: '5px' }} style={{ textAlign: "left" }}>
          {status.title}
        </Typography>


        {tasks.filter((task) => task.status === status.title)
          .sort((a, b) => {
            const dueDateA = a.dueDate ? new Date(a.dueDate.split('/').reverse().join('-')) : new Date('9999-12-31');
            const dueDateB = b.dueDate ? new Date(b.dueDate.split('/').reverse().join('-')) : new Date('9999-12-31');
            if (dueDateA > dueDateB) return -1;
            if (dueDateA < dueDateB) return 1;
            return priorityMap[b.priority] - priorityMap[a.priority];
          }).map((task, index, array) => (
            <div key={task._id}>
              <TaskCard key={task._id} task={task} />
              {UserRole && UserRole !== 'client' && index === array.length - 1 && (
                <Button
                  variant="outlined"
                  onClick={() => handleOpen(null, status.title)}
                  style={{
                    backgroundColor: status.style.backgroundColor,
                    textTransform: "capitalize",
                    fontWeight: "bold",
                    color: "#000",
                    marginTop: "auto",
                    border: "none",
                    padding: "0px",
                    textAlign: "left",
                    width: "50px"
                  }}
                >
                  + New
                </Button>
              )}
            </div>
          ))}



        {/* {tasks.filter((task) => task.status === status.title)
          .sort((a, b) => {
            const dueDateA = a.dueDate ? new Date(a.dueDate.split('/').reverse().join('-')) : new Date('9999-12-31');
            const dueDateB = b.dueDate ? new Date(b.dueDate.split('/').reverse().join('-')) : new Date('9999-12-31');
            if (dueDateA > dueDateB) return -1;
            if (dueDateA < dueDateB) return 1;
            return priorityMap[b.priority] - priorityMap[a.priority];
          }).map((task) => (<TaskCard key={task._id} task={task} />))} */}






        {/* {tasks.filter((task) => task.status === status.title).sort((a, b) => priorityMap[b.priority] - priorityMap[a.priority]).map((task) => (<TaskCard key={task._id} task={task} />))} */}
        {/* {tasks.filter((task) => task.status === status.title).sort((a,b)=> b.priority - a.priority ).map((task) => (<TaskCard key={task._id} task={task} />))} */}
      
      
        {/* {
          UserRole && UserRole !== 'client' &&
          <Button
            variant="outlined"
            onClick={() => handleOpen(null, status.title)}
            style={{ backgroundColor: status.style.backgroundColor, textTransform: "capitalize", fontWeight: "bold", color: "#000", marginTop: "auto", border: "none", padding: "0px", textAlign: "left", width: "50px" }}
          >
            + New
          </Button>
        } */}


      </div>
    );
  };

  return (
    <>
      {tasks.length !== null ? (
        <>
          <Backdrop
            open={loading}
            sx={{ color: "#2e7d32", zIndex: (theme) => theme.zIndex.drawer + 1, }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <DndProvider backend={HTML5Backend}>
            <Box className="flex justify-between py-4 px-0 gap-4">
              {statuses.map((status) => (
                <StatusColumn key={status.id} status={status} />
              ))}
            </Box>
          </DndProvider>

          <Modal open={openModal} onClose={handleClose}>
            <Box
              className="bg-white p-6 rounded-lg scrollable"
              style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: { xs: "98%", sm: 600 }, maxHeight: "70vh", overflowY: "auto", boxShadow: 24, }}
            >
              <Typography variant="h6" sx={{ marginBottom: "8px" }}>
                {currentTask ? "Edit Task" : "Add a New Task"}
              </Typography>
              <span
                style={{ display: "flex", gap: "1rem", marginBottom: "5px", alignItems: "center", border: "1px solid gray", padding: "10px", borderRadius: "5px", backgroundColor: "#f9f9f9", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", width: "100%", }}
              >
                <Typography variant="body1" style={{ fontWeight: "bold" }}>
                  Title :
                </Typography>
                <TextField
                  style={{ width: "85%" }}
                  value={newTask.title}
                  onChange={(e) =>
                    setNewTask({ ...newTask, title: e.target.value })
                  }
                  className="mb-4"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputProps={{ style: { border: "none", padding: "5px", fontSize: "16px" }, }}
                />
              </span>

              <span
                style={{ display: "flex", gap: "1rem", marginBottom: "5px", alignItems: "center", border: "1px solid gray", padding: "10px", borderRadius: "5px", backgroundColor: "#f9f9f9", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", }}
              >
                <Typography
                  variant="body1"
                  style={{ fontWeight: "bold", width: "atuo" }}
                >
                  Priority :
                </Typography>

                <TextField
                  select
                  value={newTask.priority}
                  onChange={(e) => {
                    console.log("Selected User ID:", e.target.value);
                    setNewTask({ ...newTask, priority: e.target.value });
                  }}
                  className="mb-4"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputProps={{
                    style: { border: "none", padding: "5px", fontSize: "16px" },
                  }}
                >
                  {priorities.map((priority) => (
                    <MenuItem key={priority} value={priority}>
                      {priority}
                    </MenuItem>
                  ))}
                </TextField>
              </span>

              <span
                style={{ marginBottom: "5px", display: "flex", gap: "1rem", alignItems: "center", border: "1px solid gray", padding: "10px", borderRadius: "5px", backgroundColor: "#f9f9f9", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", }}
              >
                <Typography
                  variant="body1"
                  style={{ fontWeight: "bold", width: "atuo" }}
                >
                  Status :
                </Typography>

                <TextField
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputProps={{
                    style: { border: "none", padding: "5px", fontSize: "16px" },
                  }}
                  select
                  value={newTask.status}
                  onChange={(e) =>
                    setNewTask({ ...newTask, status: e.target.value })
                  }
                  className="mb-4"
                >
                  {statuses.map((status) => (
                    <MenuItem key={status.id} value={status.title}>
                      {status.title}
                    </MenuItem>
                  ))}
                </TextField>
              </span>

              <span
                style={{ display: "flex", gap: "1rem", marginBottom: "5px", alignItems: "center", border: "1px solid gray", padding: "10px", borderRadius: "5px", backgroundColor: "#f9f9f9", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", }}
              >
                <Typography
                  variant="body1"
                  style={{ fontWeight: "bold", width: "atuo" }}
                >
                  Assign Date :
                </Typography>
                <TextField
                  type="date"
                  value={newTask.assignDate}
                  onChange={(e) =>
                    setNewTask({ ...newTask, assignDate: e.target.value })
                  }
                  className="mb-4"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputProps={{
                    style: { border: "none", padding: "5px", fontSize: "16px" },
                  }}
                />
              </span>

              <span
                style={{ display: "flex", gap: "1rem", marginBottom: "5px", alignItems: "center", padding: "10px", border: "1px solid gray", borderRadius: "5px", backgroundColor: "#f9f9f9", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", }}
              >
                <Typography
                  variant="body1"
                  style={{ fontWeight: "bold", width: "atuo" }}
                >
                  Due Date :
                </Typography>
                <TextField
                  type="date"
                  value={newTask.dueDate}
                  onChange={(e) =>
                    setNewTask({ ...newTask, dueDate: e.target.value })
                  }
                  className="mb-4"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputProps={{
                    style: { border: "none", padding: "5px", fontSize: "16px" },
                  }}
                />
              </span>

              <span style={{ display: "flex", flexDirection: "column", gap: "0.5rem", marginBottom: "5px", borderRadius: "5px", width: "100%", }}>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Assign To:
                </Typography>

                <Autocomplete
                  options={userId.map((user) => user.name)}
                  // freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select User"
                      InputProps={{
                        ...params.InputProps,
                      }}
                      style={{ width: "100%" }}
                    />
                  )}
                  onChange={(event, value) => {
                    if (value) {
                      handleChipChange(value);
                    }
                  }}
                  getOptionLabel={(option) => option}
                  style={{ width: "100%", borderRadius: "5px", }}
                />
                <div
                  style={{ maxWidth: "550px" }}
                  className="flex flex-wrap gap-1 mb-2"
                >
                  {newTask.assign.length > 0
                    ? newTask.assign.map((name, index) => (
                      <Chip
                        key={index}
                        label={name}
                        onDelete={() => handleChipChange(name)}
                      />
                    ))
                    : ""}
                </div>
              </span>

              {SprintData && SprintData.length > 0 && (
                <span style={{ display: "flex", flexDirection: "column", gap: "0.5rem", marginBottom: "5px", borderRadius: "5px", width: "100%", }}>
                  <Typography variant="body1" style={{ fontWeight: "bold" }}>
                    Assign Sprint:
                  </Typography>
                  <TextField
                    select
                    value={newTask.sprintname}
                    onChange={(e) => setNewTask({ ...newTask, sprintname: e.target.value })}
                    className="mb-4"
                    variant="standard"
                    InputProps={{ disableUnderline: true, }}
                    inputProps={{ style: { border: "none", padding: "5px", fontSize: "16px" }, }}
                    placeholder="Select Sprint"
                    style={{ width: "100%", border: "1px solid gray", padding: "10px", borderRadius: "5px", backgroundColor: "#f9f9f9", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", }}
                  >
                    {SprintData.map((sprint) => (
                      <MenuItem key={sprint.name} value={sprint.name}>
                        {sprint.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </span>
              )}

              {SprintData && SprintData.length > 0 && newTask.sprintname && (
                <span style={{ display: "flex", flexDirection: "column", gap: "0.5rem", marginBottom: "5px", borderRadius: "5px", width: "100%", }}>
                  <Typography variant="body1" style={{ fontWeight: "bold" }}>
                    Select Task:
                  </Typography>
                  <TextField
                    select
                    value={newTask.sprintcatname}
                    onChange={(e) => setNewTask({ ...newTask, sprintcatname: e.target.value })}
                    className="mb-4"
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputProps={{
                      style: { border: "none", padding: "5px", fontSize: "16px" },
                    }}
                    placeholder="Select Task"
                    style={{ width: "100%", border: "1px solid gray", padding: "10px", borderRadius: "5px", backgroundColor: "#f9f9f9", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", }}
                  >
                    {SprintData.filter((sprint) => sprint.name === newTask.sprintname)
                      .map((sprint) => sprint.tasks)
                      .flat()
                      .map((task) => (
                        <MenuItem key={task._id} value={task.taskName}>
                          {task.taskName}
                        </MenuItem>
                      ))}
                  </TextField>
                </span>
              )}

              <span style={{ width: "100%" }} className="flex flex-col gap-2 mb-8 sm:mb-16 md:mb-18 ">
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Description:
                </Typography>
                <ReactQuill
                  value={newTask.description}
                  onChange={(value) => {
                    setNewTask({ ...newTask, description: value });
                  }}
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, false] }],
                      ["bold", "italic", "underline", "strike"],
                      ["link", "image", "blockquote", "code-block"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["clean"],
                    ],
                  }}
                  style={{ height: "150px", borderRadius: "5px", width: "100%", maxWidth: "550px", }}
                />
              </span>

              <Button
                variant="contained"
                onClick={currentTask ? handleUpdateTask : handleAddTask}
                sx={{
                  backgroundColor: "#4caf50", textTransform: "capitalize", color: "#fff", borderRadius: "8px", padding: "10px 20px", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", transition: "background-color 0.3s, transform 0.2s",
                  "&:hover": { backgroundColor: "#388e3c", transform: "scale(1.05)", },
                }}
                disabled={loading}
                endIcon={
                  loading ? (
                    <CircularProgress size={20} sx={{ color: "#fff" }} />
                  ) : null
                }
              >
                {currentTask ? "Update Task" : "Add Task"}
              </Button>

            </Box>
          </Modal>

          <Dialog
            open={dialogueOpen}
            onClose={handleCloseDialogue}
            maxWidth="sm"
            fullWidth
            PaperProps={{
              style: { backgroundColor: 'white', borderRadius: '8px', padding: '24px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', },
            }}
          >
            <DialogTitle style={{ fontSize: '1.25rem', fontWeight: '600', textAlign: 'center' }}>
              Select Your Activities
            </DialogTitle>
            <DialogContent style={{ padding: '16px' }} className="flex flex-col space-y-2">
              {checkboxes.map(({ name, label }) => (
                <FormControlLabel
                  key={name}
                  control={
                    <Checkbox
                      checked={checked[name]}
                      onChange={handleCheckboxChange}
                      name={name}
                      sx={{ color: "#9e9e9e", "&.Mui-checked": { color: "#2e7d32", }, }}
                    />
                  }
                  label={label}
                  className="flex items-center justify-start"
                />
              ))}
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
              <Button
                onClick={handleDialogSubmit}
                variant="contained"
                style={{
                  backgroundColor: '#4caf50', textTransform: 'capitalize', color: '#fff', borderRadius: '8px', padding: '10px 20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', transition: "background-color 0.3s, transform 0.2s",
                  "&:hover": { backgroundColor: "#388e3c", transform: "scale(1.05)", },
                }}
                disabled={loading}
                endIcon={loading ? <CircularProgress size={20} style={{ color: '#fff' }} /> : null}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>

        </>
      ) : (
        ""
      )}

      <ToastContainer />
    </>
  );
};

export default TrelloBoard;