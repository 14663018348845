import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField, Button, Box, Grid, Typography } from '@mui/material';
import { TbFilterCheck } from "react-icons/tb";
import { useDispatch, useSelector } from 'react-redux';
import { setFilter } from '../../redux/reduxslices/FilterSlices';

const FilteredComp = ({ data, onUpdate, prevData }) => {

    const dispatch = useDispatch();
    const projectIdCat = useSelector((state) => state.filterSlices.filters);
    console.log(`projectIdCat is ${projectIdCat}`);

    const [members, setMembers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [projectData, setProjectData] = useState([]);
    const [projectId, setProjectId] = useState(null);

    const handleSelectChange = (event, newValue) => {
        const selectedIds = newValue.map((user) => user.name);
        // setSelectedUsers(selectedIds);
        console.log(`projectId is ${projectId}`);
        console.log('Selected User IDs:', selectedUsers);
        dispatch(setFilter({ projectidcat: projectId, selectedmembers: selectedIds }));

        // const filteredTasks = selectedIds.length === 0
        //     ? prevData.project.tasks
        //     : prevData.project.tasks.filter(task =>
        //         task.assign.some(user => selectedIds.includes(user))
        //     );

        // const updatedProjectData = {
        //     ...projectData,
        //     project: {
        //         ...projectData.project,
        //         tasks: filteredTasks,
        //     },
        // };

        // setProjectData(updatedProjectData);
        // onUpdate(updatedProjectData);
    };

    const handleFilterClick = () => {
        console.log("projectId is projectId");
        console.log('Selected User IDs:', selectedUsers);

        // const filteredTasks = selectedUsers.length === 0
        //     ? prevData.project.tasks
        //     : prevData.project.tasks.filter(task =>
        //         task.assign.some(user => selectedUsers.includes(user))
        //     );

        // const updatedProjectData = {
        //     ...projectData,
        //     project: {
        //         ...projectData.project,
        //         tasks: filteredTasks,
        //     },
        // };

        // setProjectData(updatedProjectData);
        // onUpdate(updatedProjectData);
    };

    useEffect(() => {
        setMembers(prevData?.project?.members || []);
        setProjectId(prevData?.project?._id)
        setProjectData(data);
    }, [prevData]);


    useEffect(() => {
        if (projectIdCat) {
            console.log(projectIdCat);
            const filteredProject = projectIdCat.filter(project => project.projectidcat === projectId);
            if (filteredProject.length > 0) {
                if (filteredProject[0].selectedmembers.length > 0) {
                    console.log(filteredProject[0].selectedmembers);
                    const selectedIds = filteredProject[0].selectedmembers.map((user) => user);
                    setSelectedUsers(selectedIds);
                    const filteredTasks = selectedIds.length === 0
                        ? prevData.project.tasks
                        : prevData.project.tasks.filter(task =>
                            task.assign.some(user => selectedIds.includes(user))
                        );

                    const updatedProjectData = {
                        ...projectData,
                        project: {
                            ...projectData.project,
                            tasks: filteredTasks,
                        },
                    };

                    setProjectData(updatedProjectData);
                    onUpdate(updatedProjectData);
                }
                else if (filteredProject[0].selectedmembers.length == 0) {
                    console.log(filteredProject[0].selectedmembers);
                    const selectedIds = filteredProject[0].selectedmembers.map((user) => user);
                    setSelectedUsers(selectedIds);
                    const filteredTasks = selectedIds.length === 0
                        ? prevData.project.tasks
                        : prevData.project.tasks.filter(task =>
                            task.assign.some(user => selectedIds.includes(user))
                        );

                    const updatedProjectData = {
                        ...projectData,
                        project: {
                            ...projectData.project,
                            tasks: filteredTasks,
                        },
                    };

                    setProjectData(updatedProjectData);
                    onUpdate(updatedProjectData);
                }
            } else {
                console.log("No project found with that ID.");
            }
        }
    }, [projectIdCat, projectId]);


    useEffect(() => {
        if (selectedUsers) {
            console.log(selectedUsers);
        }
    }, [selectedUsers])
    



    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2, 
                padding: 2,
                width:'100%',
            }}
        >
            {/* <Typography
                variant="h6"
                sx={{
                    fontWeight: 500,
                    color: 'text.primary',
                    textAlign: 'left',
                }}
            >
                Select user to apply filter
            </Typography> */}

            <Box
                sx={{
                    display: 'flex',
                    gap: 2, 
                    width: '100%',
                    justifyContent:'end',
                    alignItems:'center',
                    padding:2
                }}
            >
                <Autocomplete
                sx={{ minWidth: '15%',maxWidth:'80%',height:'30px',padding:0}}
                    multiple
                    options={members}
                    getOptionLabel={(option) => option.name}
                    value={members.filter((member) => selectedUsers.includes(member.name))}
                    onChange={handleSelectChange}
                    renderInput={(params) => (
                        <TextField
                        label="Filter by Users" 
                            {...params}
                            variant="outlined"
                            fullWidth
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: 2,
                                },
                            }}
                        />
                    )}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                />

                {selectedUsers && selectedUsers.length > 0 &&
                    <>

                    <div onClick={handleFilterClick} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30px', paddingTop: '20px', cursor: 'pointer' }}>
                        <TbFilterCheck color="#4caf50" size={25} />
                    </div>
                        {/* <Button
                            variant="contained"
                            color="primary"
                            onClick={handleFilterClick}
                            className='w-fit h-fit'
                            sx={{
                                // backgroundColor: "#4caf50",
                                backgroundColor: "#fff",
                                padding: '12px 10px',
                                color: "#fff",
                                transition: "background-color 0.3s, transform 0.2s",
                                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                borderRadius: "8px",
                                textTransform: 'capitalize',
                                fontSize: '1rem',
                                borderRadius: 2,
                                "&:hover": {
                                    backgroundColor: "#388e3c",
                                    transform: "scale(1.05)",
                                },
                            }}
                        >
                            

                        </Button> */}
                    </>
                }



            </Box>
        </Box>
    );
};

export default FilteredComp;























// import React, { useEffect, useState } from 'react';
// import { Autocomplete, TextField, Button, Typography } from '@mui/material';

// const FilteredComp = ({ data, onUpdate,prevData  }) => {

//     const [members, setMembers] = useState([]);
//     const [selectedUsers, setSelectedUsers] = useState([]);
//     const [preprojectData, setPreProjectData] = useState([]);
//     const [projectData, setProjectData] = useState([]);

//     const handleSelectChange = (event, newValue) => {
//         const selectedIds = newValue.map((user) => user.name);
//         setSelectedUsers(selectedIds);
//     };

//     const handleFilterClick = () => {
//         console.log('Selected User IDs:', selectedUsers);
//         if (selectedUsers.length == 0) {
//             const filteredTasks = prevData.project.tasks
//             // console.log('Filtered Tasks:', filteredTasks);
//             const updatedProjectData = {
//                 ...projectData,
//                 project: {
//                     ...projectData.project,
//                     tasks: filteredTasks,
//                 },
//             };

//             setProjectData(updatedProjectData);
//             onUpdate(updatedProjectData);

//         }
//         else {
//             const filteredTasks = prevData.project.tasks.filter(task =>
//                 task.assign.some(user => selectedUsers.includes(user))
//             );

//             const updatedProjectData = {
//                 ...projectData,
//                 project: {
//                     ...projectData.project,
//                     tasks: filteredTasks,
//                 },
//             };

//             setProjectData(updatedProjectData);
//             onUpdate(updatedProjectData);
//             // console.log('Filtered Tasks:', filteredTasks);
//         }
//     };

//     useEffect(() => {
//         setMembers(prevData?.project?.members || []);
//         setPreProjectData(prevData);
//         setProjectData(data)
//     }, [prevData]);

//     return (
//         <div style={{ display: 'flex', width: '100%', gap: '0.5rem' }} className="p-4 rounded-lg">
//             <div style={{ width: '50%' }}>
//                 <Typography variant='h6' >Select user to apply filter</Typography>
//                 <Autocomplete
//                     multiple
//                     options={members}
//                     getOptionLabel={(option) => option.name}
//                     value={members.filter((member) => selectedUsers.includes(member.name))}
//                     onChange={handleSelectChange}
//                     renderInput={(params) => (
//                         <TextField
//                             {...params}
//                             variant="outlined"
//                             fullWidth
//                         />
//                     )}
//                     isOptionEqualToValue={(option, value) => option.name === value.name}
//                 />
//             </div>
//             <div style={{ width: '20%' }}>
//             <Typography variant='h6' ></Typography>
//             <Button
//                 variant="contained"
//                 color="primary"
//                 sx={{ textTransform: 'capitalize' }}
//                 onClick={handleFilterClick}
//             >
//                 Filter
//             </Button>
//             </div>
//         </div>
//     );
// };

// export default FilteredComp;