import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { signOut } from '../redux/reduxslices/userSlices';
import { clearProjectId } from '../redux/reduxslices/ProjectIdSlice';
import { Button, Box, Drawer, Typography } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify'; 
import { fetchProjectsData } from '../api/appApi';

const Sidebar = ({ open, onClose, onProjectSelect, activeProject }) => {
  
  const location = useLocation(); 

  const projectId = useSelector((state) => state.projectId.projectId);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [projectsList, setProjectsList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeStateForOtherButtons, setActiveStateForOtherButtons] = useState(null);
  
  const handleSalarySlipClick = () => {
    // console.log(projectId);
    setActiveStateForOtherButtons('salary-slip');
    onProjectSelect(projectId);
    navigate('/salary-slip');
  };

  const handleAttendanceClick = () => {
    // console.log(projectId);
    setActiveStateForOtherButtons('attendance');
    onProjectSelect(projectId);
    navigate('/attendance-sheet');
  };

  const handleProjectClick = (projectId) => {
    onProjectSelect(projectId);
    console.log(`Project Id Set to redux state and navigating to /`);
    setActiveStateForOtherButtons(null);
    navigate('/');
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetchProjectsData(dispatch);
        setProjectsList(response.data.projects);
      } catch (error) {
        console.error(error);
        // Optionally show error toast here
        if (error.response && error.response.data.message === "Invalid token") {
          toast.error("Your session has expired. Please sign in again to continue.", {
            position: "top-right",
            autoClose: 1000,
            toastId: "token-error",
          });
          setTimeout(() => {
            dispatch(signOut({ user: null, token: null }));
            dispatch(clearProjectId());
            navigate("/signout");
          }, 1000);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, navigate]);

  const isHomePage = location.pathname === '/';

  return (
    <>
      <Drawer anchor="left" open={open} onClose={onClose}>
        <Box sx={{ width: 220, height: '100vh', padding: 2, backgroundColor: '#d9f9d9',display:'flex',flexDirection:'column',justifyContent:'space-between' }}>
          <Box>
            <Typography variant="h6" sx={{ marginBottom: 2, color: '#47934a', fontWeight: 'bold' }}>
              Project List
            </Typography>
            <div className="scrollable" style={{ height: 'calc(90vh - 200px)', overflowY: 'auto', paddingRight: '4px' }}>
              {loading ? (
                <Typography variant="body1" sx={{ textAlign: 'center', color: '#47934a' }}>Loading Projects...</Typography>
              ) : (
                projectsList?.map((project) => (
                  <Button
                    key={project._id}
                    variant={activeProject === project._id && isHomePage ? "contained" : "outlined"}
                    sx={{
                      marginBottom: 1,
                      textTransform: 'capitalize',
                      width: '100%',
                      color: activeProject === project._id && isHomePage  ? '#ffffff' : '#47934a',
                      backgroundColor: activeProject === project._id && isHomePage ? '#2e7d32' : 'transparent',
                      borderColor: '#47934a',
                      '&:hover': {
                        backgroundColor: activeProject === project._id && isHomePage ? '#1b5e20' : '#c8e6c9',
                        borderColor: '#47934a',
                      },

                      // backgroundColor: activeProject === project._id ? '#2e7d32' : 'transparent',
                      // borderColor: '#47934a',
                      // '&:hover': {
                      //   backgroundColor: activeProject === project._id ? '#1b5e20' : '#c8e6c9',
                      //   borderColor: '#47934a',
                      // },

                    }}
                    onClick={() => handleProjectClick(project._id)}
                  >
                    {project.name}
                  </Button>
                ))
              )}
            </div>
          </Box>
          <Box>


            <Button
              variant={activeStateForOtherButtons === 'attendance' ? "contained" : "outlined"}
              sx={{
                marginBottom: 1,
                textTransform: 'capitalize',
                width: '100%',
                color: activeStateForOtherButtons === 'attendance' ? '#ffffff' : '#47934a', 
                backgroundColor: activeStateForOtherButtons === 'attendance' ? '#2e7d32' : 'transparent', 
                borderColor: '#47934a',
                '&:hover': {
                  backgroundColor: activeStateForOtherButtons === 'attendance' ? '#1b5e20' : '#c8e6c9', 
                  borderColor: '#47934a',
                },
              }}
              onClick={handleAttendanceClick}
            >
              Attendance
            </Button>

            <Button
              variant={activeStateForOtherButtons === 'salary-slip' ? "contained" : "outlined"}
              sx={{
                marginBottom: 1,
                textTransform: 'capitalize',
                width: '100%',
                color: activeStateForOtherButtons === 'salary-slip' ? '#ffffff' : '#47934a', 
                backgroundColor: activeStateForOtherButtons === 'salary-slip' ? '#2e7d32' : 'transparent',
                borderColor: '#47934a',
                '&:hover': {
                  backgroundColor: activeStateForOtherButtons === 'salary-slip' ? '#1b5e20' : '#c8e6c9',
                  borderColor: '#47934a',
                },
              }}
              onClick={handleSalarySlipClick}
            >
              Salary Slip
            </Button>





            {/* <Button
              variant="outlined"
              sx={{
                marginBottom: 1,
                textTransform: 'capitalize',
                width: '100%',
                color: '#47934a',
                borderColor: '#47934a',
                '&:hover': {
                  backgroundColor: '#c8e6c9',
                  borderColor: '#47934a',
                },
              }}
              onClick={handleAttendanceClick}
            >
              Attendance
            </Button>
            <Button
              variant="outlined"
              sx={{
                marginBottom: 1,
                textTransform: 'capitalize',
                width: '100%',
                color: '#47934a',
                borderColor: '#47934a',
                '&:hover': {
                  backgroundColor: '#c8e6c9',
                  borderColor: '#47934a',
                },
              }}
              onClick={handleSalarySlipClick}
            >
              Salary Slip
            </Button> */}



          </Box>
        </Box>
      </Drawer>
      <ToastContainer />
    </>
  );
};

export default Sidebar;





























// import React, { useEffect, useState } from 'react';
// import { Drawer, Box, Typography, Button } from '@mui/material';
// // import { fetchProjectsData } from '../api/authApi';
// import { fetchProjectsData } from '../api/appApi';
// import { toast, ToastContainer } from 'react-toastify';
// import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { signOut } from '../redux/reduxslices/userSlices';
// import { clearProjectId } from '../redux/reduxslices/ProjectIdSlice';

// const Sidebar = ({ onProjectSelect, open, onClose, activeProject }) => {
//     const dispatch = useDispatch();
//     const navigate = useNavigate(); 
//     const [projectsList, setProjectsList] = useState(null);
//     const [loading, setLoading] = useState(false);


//     const handleSalarySlipClick = () => {
//         navigate('/salary-slip');
//     };

//     const handleAttendanceClick = () => {
//         navigate('/attendance');
//     };


//     const handleProjectClick = (projectId) => {
//         onProjectSelect(projectId);   
//         console.log(`Project Id Set to redux state and Nvigating to route /`);
//         navigate('/');                
//     };

//     useEffect(() => {
//         const fetchData = async () => {
//             setLoading(true);
//             try {
//                 const response_fr_projects = await fetchProjectsData(dispatch);
//                 setProjectsList(response_fr_projects.data.projects);

//             } catch (error) {

//                 // toast.error(
//                 //     "Oops! Error Occurs. Please try again!",
//                 //     // "Oops! There was an issue in Fetchng Project Data. Please try again!",
//                 //     {
//                 //       position: "top-right",
//                 //       autoClose: 1000,
//                 //       toastId: "task-error",
//                 //       hideProgressBar: false,
//                 //       closeOnClick: true,
//                 //       pauseOnHover: true,
//                 //       draggable: true,
//                 //     }
//                 //   );

//                 console.log(error);
//                 // alert('Error from Siderbar projectlists')
//                 // if (error.response && error.response.data.message == "Invalid token") {

//                 //     toast.error(
//                 //         "Your session has expired. Please sign in again to continue.",
//                 //         {
//                 //             position: "top-right",
//                 //             autoClose: 1000,
//                 //             toastId: "token-error",
//                 //             hideProgressBar: false,
//                 //             closeOnClick: true,
//                 //             pauseOnHover: true,
//                 //             draggable: true,
//                 //         }
//                 //     );
//                 //     console.error("Invalid token. Please log in again.");
//                 //     setTimeout(() => {
//                 //         dispatch(signOut({ user: null, token: null }));
//                 //         dispatch(clearProjectId());
//                 //         navigate("/signout");
//                 //     }, 1000);
//                 // }

//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchData();
//     }, []);

//     return (
//         <>
//             <Drawer anchor="left" open={open} onClose={onClose}>

//                 <Box sx={{ width: 220, height: '100vh', padding: 2, backgroundColor: '#d9f9d9' }}>
//                     <Box>
//                         <Typography variant="h6" sx={{ marginBottom: 2, color: '#47934a', fontWeight: 'bold' }}>
//                             Project List
//                         </Typography>
//                         <div className='scrollable' style={{ height: 'calc(90vh - 200px)', overflowY: 'auto', paddingRight: '4px' }}>
//                             {projectsList && projectsList.map((project) => (
//                                 <Button
//                                     key={project._id}
//                                     variant={activeProject === project._id ? "contained" : "outlined"}
//                                     sx={{
//                                         marginBottom: 1,
//                                         textTransform: 'capitalize',
//                                         width: '100%',
//                                         color: activeProject === project._id ? '#ffffff' : '#47934a',
//                                         backgroundColor: activeProject === project._id ? '#2e7d32' : 'transparent',
//                                         borderColor: '#47934a',
//                                         '&:hover': {
//                                             backgroundColor: activeProject === project._id ? '#1b5e20' : '#c8e6c9',
//                                             borderColor: '#47934a',
//                                         },
//                                     }}
//                                     onClick={() => handleProjectClick(project._id)}
//                                 // onClick={() => onProjectSelect(project._id)}
//                                 >
//                                     {project.name}
//                                 </Button>
//                             ))}
//                         </div>
//                     </Box>
//                     <Box>

//                         <Button
//                             // key={project._id}
//                             // variant={activeProject === project._id ? "contained" : "outlined"}
//                             sx={{
//                                 marginBottom: 1,
//                                 textTransform: 'capitalize',
//                                 width: '100%',
//                                 // color: activeProject === project._id ? '#ffffff' : '#47934a',
//                                 // backgroundColor: activeProject === project._id ? '#2e7d32' : 'transparent',
//                                 borderColor: '#47934a',
//                                 // '&:hover': {
//                                 //     backgroundColor: activeProject === project._id ? '#1b5e20' : '#c8e6c9',
//                                 //     borderColor: '#47934a',
//                                 // },
//                             }}
//                             onClick={handleAttendanceClick} 
         
//                         >
//                             Attendance
//                         </Button>

//                     </Box>
                    

//                     {/* <div style={{ marginTop: 'auto' }}>
//                         <Button
//                             variant="contained"
//                             color="primary"
//                             sx={{ width: '100%', marginBottom: 1 }}
//                             onClick={handleSalarySlipClick}
//                         >
//                             Salary Slip
//                         </Button>
//                         <Button
//                             variant="contained"
//                             color="primary"
//                             sx={{ width: '100%' }}
//                             onClick={handleAttendanceClick} 
//                         >
//                             Attendance
//                         </Button>
//                     </div> */}
//                 </Box>

//             </Drawer>

//             <ToastContainer />
//         </>

//     );
// };

// export default Sidebar;
