import {
    Box,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    IconButton,
    CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MdEdit, MdDelete } from 'react-icons/md';
import { MdAdd } from 'react-icons/md';
import { AddSprint, getSprints } from "../../api/authApi";
import { toast, ToastContainer } from "react-toastify";
import { signOut } from "../../redux/reduxslices/userSlices";
import { clearProjectId } from "../../redux/reduxslices/ProjectIdSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createDailyTask } from "../../api/appApi";

const Dailytask = ({ data, onUpd }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const today = new Date().toLocaleDateString();
    const [tasks, setTasks] = useState([]);
    const [projectId, setProjectId] = useState("");
    const [projectData, setProjectData] = useState(null);
    const [open, setOpen] = useState(false);
    const [sprintTitle, setSprintTitle] = useState('');
    const [sprintList, setSprintList] = useState([]);
    const [sprints, setSprints] = useState([]);
    const [sprintRow, setSprintRow] = useState({ taskDetails: '', date: `${today}`, dailyTask: '', timeSpent: '' });
    const [currentRowIndex, setCurrentRowIndex] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [TaskTitleMenue, setTaskTitleMenue] = useState([]);

    const handleClickOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        // setSprintTitle('');
        resetSprintRow();
        setIsEditing(false);    
        setSprintList([]);
    };

    const resetSprintRow = () => setSprintRow({ taskDetails: '', date: `${today}`, dailyTask: '', timeSpent: '' });

    const handleSave = async () => {

        if (sprintList.length == 0) {
            toast.info("Please complete the form to proceed.", {
                position: "top-right",
                autoClose: 1000,
                toastId: "no-tasks-today",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });

            // alert("Please Fill the form first");
            return;
        }
        const sprintData = {
            projectId: projectId,
            dailyTasks: sprintList.filter(item => item.taskDetails && item.dailyTask && item.timeSpent),
        };

        console.log("Sprint Data:", sprintData);
        try {

            setLoading(true);

            const response = await createDailyTask(sprintData, dispatch);
            const updatedDailyTasks = response.data.dailyTask.dailyTasks;

            const updatedProjectData = {
                ...projectData,
                project: {
                    ...projectData.project,
                    dailyTasks: [
                        ...projectData.project.dailyTasks,
                        ...updatedDailyTasks
                    ],
                },
            };

            setProjectData(updatedProjectData);
            onUpd(updatedProjectData);

            // setSprints((prevSprints) => [...prevSprints, response.data?.dailyTask?.dailyTasks]);
            handleClose();

            toast.success("🎉 DailyTask has been added successfully!", {
                position: "top-right",
                autoClose: 1000,
                toastId: "Sprint-task-success",
                hideProgressBar: false,

            });


        } catch (error) {
            console.error("Error adding task:", error);
            toast.error(
                "Oops! There was an issue adding the DailyTasks. Please try again!",
                {
                    position: "top-right",
                    autoClose: 1000,
                    toastId: "Sprint-error",
                    hideProgressBar: false,

                }
            );
            handleClose();

            

        } finally {
            handleClose();
            setLoading(false);

        }

    };

    const handleAddOrUpdateRow = () => {
        const { taskDetails, date, dailyTask, timeSpent } = sprintRow;

        if (!taskDetails || !dailyTask || !timeSpent) {
            toast.info("Please complete all fields.", {
                position: "top-right",
                autoClose: 1000,
                toastId: "no-tasks",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            // alert("All fields must be filled out!");
            return;
        }

        if (isEditing) {

            const updatedList = sprintList.map((row, index) =>
                index === currentRowIndex ? sprintRow : row
            );
            setSprintList(updatedList);
        } else {

            setSprintList([...sprintList, sprintRow]);
        }

        resetSprintRow();
        setIsEditing(false);
    };

    const handleEditRow = (index) => {
        setCurrentRowIndex(index);
        setSprintRow(sprintList[index]);
        setIsEditing(true);
    };

    const handleDeleteRow = (index) => {
        const newSprintList = sprintList.filter((_, i) => i !== index);
        setSprintList(newSprintList);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        // console.log(`name is ${name}`);
        setSprintRow({ ...sprintRow, [name]: value });
    };

    useEffect(() => {
        if (data) {
            setProjectData(data);
            setProjectId(data.project._id);
            // getSprints(data.project._id);
            // console.log(userId);
        }
        if (data?.project?.dailyTasks) {
            // console.log("The Daily Tasks Data is :", data.project?.dailyTasks);
            const today = new Date();
            const todayString = today.toLocaleDateString('en-GB');   //"2024-11-21T00:00:00.000Z"
            const filteredTasks = data.project?.dailyTasks.filter(task => task.date === todayString); //&& task.dailyTask == 'In Progress');

            console.log("Today's Tasks: ", filteredTasks);
            // setSprints(data.project?.dailyTasks);filteredTasks
            setSprints(filteredTasks);

        }

        if (data?.project?.tasks) {
            const inProgressTasks = data?.project?.tasks.filter(task => task.status === "In Progress");
            setTaskTitleMenue(inProgressTasks)
            console.log("In Progress Tasks:", inProgressTasks);
        }


    }, [data]);


    return (
        <>


            <TableContainer component={Paper} elevation={3} sx={{ borderRadius: "8px" }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                align="left"
                                sx={{
                                    backgroundColor: "#c8e6c9",
                                    color: "#47934a",
                                    fontWeight: "bold",
                                }}
                            >
                                Task Details
                            </TableCell>
                            <TableCell
                                align="left"
                                sx={{
                                    backgroundColor: "#c8e6c9",
                                    color: "#47934a",
                                    fontWeight: "bold",
                                }}
                            >
                                Date
                            </TableCell>
                            <TableCell
                                align="left"
                                sx={{
                                    backgroundColor: "#c8e6c9",
                                    color: "#47934a",
                                    fontWeight: "bold",
                                }}
                            >
                                Tasks
                            </TableCell>
                            <TableCell
                                align="left"
                                sx={{
                                    backgroundColor: "#c8e6c9",
                                    color: "#47934a",
                                    fontWeight: "bold",
                                }}
                            >
                                Time Spent(h)
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {sprints.length > 0 ? (

                            sprints.map((task) => (
                                <TableRow key={task._id} sx={{ "&:hover": { backgroundColor: "#b2e0b2e3" } }}>
                                    <TableCell align="left">{task.taskDetails}</TableCell>
                                    <TableCell align="left">{task.date}</TableCell>
                                    <TableCell align="left">{task.dailyTask}</TableCell>
                                    <TableCell align="left">{task.timeSpent}</TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={4} align="center" sx={{ fontStyle: "italic" }}>
                                    No Activiy available.
                                </TableCell>
                            </TableRow>
                        )}

                    </TableBody>
                </Table>
            </TableContainer>
            <Box className="flex justify-start mt-4">
                <Button
                    variant="contained"
                    onClick={handleClickOpen}
                    startIcon={<MdAdd />}
                    sx={{
                        backgroundColor: "#4caf50", textTransform: "capitalize", color: "#fff", borderRadius: "8px", padding: "10px 10px", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", transition: "background-color 0.3s, transform 0.2s",
                        "&:hover": { backgroundColor: "#388e3c", transform: "scale(1.05)", },
                    }}
                >
                    Add Task
                </Button>
            </Box>


            <Box className="mt-0">

            </Box>

            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                <DialogTitle>Add Task</DialogTitle>
                <DialogContent>

                    <TableContainer
                        component={Paper}
                        sx={{
                            marginTop: 2,
                            boxShadow: 2,
                            borderRadius: 2,
                        }}
                    >
                        <Table sx={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#333' }}>Task Details</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#333' }}>Date</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#333' }}>Tasks</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#333' }}>Time(h)</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#333' }}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sprintList && sprintList.length > 0 ? (
                                    sprintList.map((row, index) => (
                                        <TableRow key={index} sx={{ '&:hover': { backgroundColor: '#f1f1f1' } }}>
                                            <TableCell>{row.taskDetails}</TableCell>
                                            <TableCell>{row.date}</TableCell>
                                            <TableCell>{row.dailyTask}</TableCell>
                                            <TableCell>{row.timeSpent}</TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => handleEditRow(index)} sx={{ color: '#1976d2' }}>
                                                    <MdEdit />
                                                </IconButton>
                                                <IconButton onClick={() => handleDeleteRow(index)} sx={{ color: '#d32f2f' }}>
                                                    <MdDelete />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))

                                ) : (
                                    <TableRow>
                                        <TableCell align="center" colSpan={5} className="text-center">
                                            No Task found.
                                        </TableCell>
                                    </TableRow>
                                )}


                                <TableRow sx={{ '&:hover': { backgroundColor: '#f1f1f1' } }}>
                                    <TableCell>
                                        <TextField
                                            name="taskDetails"
                                            value={sprintRow.taskDetails}
                                            onChange={handleChange}
                                            required
                                        />
                                    </TableCell>
                                    <TableCell>

                                        <Typography variant="body1">
                                            {sprintRow.date}
                                        </Typography>
                                        
                                    </TableCell>
                                    <TableCell>
                                        <FormControl>
                                            <Select
                                                name="dailyTask"
                                                value={sprintRow.dailyTask}
                                                onChange={handleChange}
                                            >
                                                {TaskTitleMenue && TaskTitleMenue.length > 0 &&
                                                    TaskTitleMenue.map(task => (
                                                        <MenuItem key={task._id} value={task.title}>{task.title}</MenuItem>
                                                    ))
                                                }

                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell>

                                        <FormControl fullWidth>
                                            <Select
                                                name="timeSpent"
                                                value={sprintRow.timeSpent}
                                                onChange={handleChange}
                                                required
                                                displayEmpty
                                            >
                                                {Array.from({ length: 24 }, (_, index) => (
                                                    <MenuItem key={index} value={index}>
                                                        {index} hour{index !== 1 ? 's' : ''}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell>

                                        <Button
                                            onClick={handleAddOrUpdateRow}
                                            variant="contained"
                                            color="primary"
                                            startIcon={isEditing ? <MdEdit /> : <MdAdd />}
                                            sx={{
                                                backgroundColor: "#4caf50",
                                                textTransform: "capitalize",
                                                color: "#fff",
                                                borderRadius: "8px",
                                                padding: "10px 20px",
                                                transition: "background-color 0.3s, transform 0.2s",
                                                "&:hover": {
                                                    backgroundColor: "#388e3c",
                                                    transform: "scale(1.05)",
                                                },
                                            }}
                                        >

                                            {isEditing ? 'Update' : 'Add'}
                                        </Button>

                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={handleClose} color="primary">Cancel</Button> */}
                    <Button
                        onClick={handleSave}
                        sx={{
                            backgroundColor: "#4caf50",
                            textTransform: "capitalize",
                            color: "#fff",
                            borderRadius: "8px",
                            padding: "10px 20px",
                            transition: "background-color 0.3s, transform 0.2s",
                            "&:hover": {
                                backgroundColor: "#388e3c",
                                transform: "scale(1.05)",
                            },
                        }}

                        disabled={loading}
                        endIcon={
                            loading ? (
                                <CircularProgress size={20} sx={{ color: "#fff" }} />
                            ) : null
                        }

                    >
                        Save
                    </Button>
                </DialogActions>

            </Dialog>
            <ToastContainer />

        </>

    );
}

export default Dailytask;