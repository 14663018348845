// validationSchema.js
import * as Yup from 'yup';


// Password validation regex
// const passwordRegex = /^(?=.*[A-Z])(?=.*[\W_]).{8,}$/;
const passwordRegex = /^(?!\s*$).+/;

// const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const emailRegex = /^[a-zA-Z0-9._%+-]+@techtics\.ai$/;


export const SignupSchema = Yup.object().shape({
    name: Yup.string()
        .required('Name is required')
        .min(2, 'Name is too short')
        .max(50, 'Name is too long'),
    email: Yup.string()
        .matches(emailRegex, {
            message: 'Email must end with @techtics.ai',
        })
        .required('Email is required'),
    
    // email: Yup.string()
    //     .matches(emailRegex, 'Email must be a valid email address')
    //     .required('Email is required'),
    password: Yup.string()
        .matches(passwordRegex, {
            message: 'Password cannot be empty.',
            excludeEmptyString: true,
        })
        .required('Password is required'),
    // password: Yup.string()
    //     .matches(passwordRegex, {
    //         message: 'Password must be at least 8 characters long, contain at least one uppercase letter and one special character.',
    //         excludeEmptyString: true,
    //     })
    //     .required('Password is required'),

    // password: Yup.string()
    //     .required('Password is required')
    //     .min(8, 'Password must be at least 8 characters')
    //     .matches(/[a-zA-Z]/, 'Password can only contain letters and numbers'),
});


export const SignInSchema = Yup.object().shape({
    
    // email: Yup.string()
    //     .required('Email or Name is required')
    //     .test('valid-email-or-name', 'Must be a valid email or name', (value) => {
    //         // Check if the value is either a valid email or a valid name (basic check)
    //         return value && (emailRegex.test(value) || value.length >= 2);
    //     }),

    email: Yup.string()
    .matches(emailRegex, {
        message: 'Email must end with @techtics.ai',
    })
    .required('Email is required'),
    
    password: Yup.string()
        .matches(passwordRegex, {
            message: 'Password cannot be empty.',
            excludeEmptyString: true,
        })
        .required('Password is required'),
    // password: Yup.string()
    //     .matches(passwordRegex, {
    //         message: 'Password must be at least 8 characters long, contain at least one uppercase letter and one special character.',
    //         excludeEmptyString: true,
    //     })
    //     .required('Password is required'),
});