import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Paper, Tabs, Tab, Backdrop, CircularProgress, IconButton, Drawer, Button, Typography } from '@mui/material';
import TrelloBoard from './TrelloBoard';
import TableTrello from './TrelloTable';
import { HomeContent } from './HomeContent';
import { fetchProjectsData } from '../api/authApi';
import { GetfetchProjectById } from '../api/appApi';
import { FaBars } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import { signOut } from '../redux/reduxslices/userSlices';
import { clearProjectId } from '../redux/reduxslices/ProjectIdSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Sprint from './SprintView/Sprints';
import Dailytask from './DailyTask/Dailytask';
import FilteredComp from './FilterComp/FilteredComp';

const TabsComponent = ({ updId }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [tabValue, setTabValue] = useState(0);
    const [projectData, setProjectData] = useState(null);
    const [projectId, setProjectId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [activeProject, setActiveProject] = useState(null);
    const [projectsList, setProjectsList] = useState(null);
    const [PrevprojectData, setPrevProjectData] = useState(null);


    useEffect(() => {
        console.log('Received Project ID in TabsComponent:', updId);
        const fetchData = async () => {
            if (updId !== null && updId !== '') {
                setLoading(true);
                try {
                    const response = await GetfetchProjectById(updId, dispatch)
                    setProjectData(response.data);
                    setPrevProjectData(response.data);


                } catch (error) {
                    console.error(error);
                    toast.error(
                        "Oops! Error Occurs. Please try again!",
                        // "Oops! There was an issue in Fetchng Project Data. Please try again!",
                        {
                            position: "top-right",
                            autoClose: 1000,
                            toastId: "Project ID in TabsComponent",
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        }
                    );
                    // setError(err);
                } finally {
                    // console.log(projectData)
                    setLoading(false);
                }
            };
        }

        fetchData();
    }, [updId]);


    const updateDailyTasks = (projectData) => {
        setProjectData(projectData);
        // setDailyTasks(newDailyTasks);
    };


    const handleUpdate = (UpdatedData) => {
        setProjectData(UpdatedData);
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // useEffect(() => {
    //     const fetchData = async () => {
    //         if (updId !== null || updId !== '') {
    //             setLoading(true);
    //             try {
    //                 const response = await GetfetchProjectById(updId) //axios.get(`http://localhost:4000/api/projects/${updId}`);
    //                 // console.log(response.data);
    //                 const response_fr_projects = await fetchProjectsData();
    //                 setProjectData(response.data);
    //                 setProjectsList(response_fr_projects.data.projects);
    //             } catch (err) {
    //                 // setError(err);
    //             } finally {
    //                 console.log(projectData)
    //                 setLoading(false);
    //             }
    //         }

    //     };

    //     fetchData();
    // }, []);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const handleProjectClick = async (projectId) => {
        setLoading(true);
        try {
            const response = await GetfetchProjectById(projectId, dispatch)
            // console.log(response.data);
            // const response_fr_projects = await fetchProjectsData();
            setProjectData(response.data);
            setActiveProject(projectId);
            setSidebarOpen(!sidebarOpen);
            // setProjectsList(response_fr_projects.data.projects);

        } catch (err) {
            setError(err);
            toast.error(
                "Oops! Error Occurs. Please try again!",
                // "Oops! There was an issue in Fetchng Project Data. Please try again!",
                {
                    position: "top-right",
                    autoClose: 1000,
                    toastId: "Project ID in TabsComponent",
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                }
            );
        } finally {
            console.log(projectData)
            setLoading(false);
        }

        // console.log(projectId);
    };


    if (error) return <div>Error loading data: {error.message}</div>;

    return (

        <>

            {projectData &&
                <>
                    <HomeContent data={projectData} />
                    <Sprint data={projectData} onUpdate={handleUpdate} />
                </>
            }



            <Box sx={{ padding: 2, backgroundColor: 'transparent', borderRadius: 2 }}>

                <Backdrop
                    open={loading}
                    sx={{
                        color: '#2e7d32',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>


                {!projectData &&
                    <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '80vh',
                            }}
                        >
                            <img
                                src="https://img.icons8.com/ios/452/project.png"
                                alt="No Project"
                                className="mb-5 w-full max-w-[200px] h-auto mx-auto"
                            />

                            <Typography variant="h6" sx={{ marginBottom: 2, color: '#47934a', fontWeight: 'bold' }}>
                                Select a project to preview
                            </Typography>

                            <Typography variant="body2" sx={{ textAlign: 'center', color: '#555' }}>
                                You can choose a project from the sidebar to view its details and manage tasks.
                            </Typography>
                        </Box>
                    </Box>
                }


                {projectData &&
                    <>
                        <Paper elevation={3} sx={{ backgroundColor: '#d9f9d9', borderRadius: 2 }}>
                            <Tabs
                                value={tabValue}
                                onChange={handleTabChange}
                                textColor="inherit"
                                indicatorColor="transparent"
                                sx={{
                                    '& .MuiTab-root': {
                                        minHeight: '48px',
                                        color: '#47934a',
                                        fontWeight: 'bold',
                                    },
                                    '& .Mui-selected': {
                                        backgroundColor: '#a5d6a7',
                                        color: '#2e7d32',
                                        borderRadius: '8px',
                                    },
                                    '& .MuiTab-root:hover': {
                                        backgroundColor: '#c8e6c9',
                                    },
                                    padding: 1,
                                }}
                            >
                                <Tab sx={{ textTransform: 'capitalize' }} label="Dashboard" />
                                <Tab sx={{ textTransform: 'capitalize' }} label="Table View" />
                                <Tab sx={{ textTransform: 'capitalize' }} label="Daily Tasks" />
                            </Tabs>
                        </Paper>

                    <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
                        <FilteredComp data={projectData} onUpdate={handleUpdate} prevData={PrevprojectData} />

                        {tabValue === 0 && (
                            <TrelloBoard data={projectData} onUpdate={handleUpdate} />
                        )}
                        {tabValue === 1 && (
                            <TableTrello data={projectData} onUpdate={handleUpdate} />
                        )}

                        {tabValue === 2 && (
                            <Dailytask data={projectData} onUpd={updateDailyTasks} />
                        )}
                    </Box>


                        {/* {tabValue === 0 && (
                            <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
                                <FilteredComp data={projectData} onUpdate={handleUpdate} prevData={PrevprojectData} />
                                <TrelloBoard data={projectData} onUpdate={handleUpdate} />
                            </Box>
                        )}

                        {tabValue === 1 && (
                            <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
                                <TableTrello data={projectData} onUpdate={handleUpdate} />
                            </Box>
                        )}

                        {tabValue === 2 && (
                            <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
                                <Dailytask data={projectData} onUpd={updateDailyTasks} />
                            </Box>
                        )} */}

                    </>
                }
                {/* <ToastContainer /> */}

            </Box>

        </>
    );
};

export default TabsComponent;