import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const RedirectRoute = () => {
    const token = useSelector((state) => state.user.token);

    return token ? <Navigate to="/" /> : <Navigate to="/signin" />;
};

export default RedirectRoute;
