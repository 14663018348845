import { Box, Typography, Link, List, ListItem } from "@mui/material";
import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

export const HomeContent = ({ data }) => {
  return (
    <Box className="p-6 pb-0">
      <Typography variant="h4" sx={{ fontWeight: 600, color: "main",textTransform:'capitalize' }}>
        {data.project.name || "Untitled Project"}
      </Typography>

      {data.project.description && (
        <>
         <Typography
            variant="h6"
            sx={{ fontWeight: 500, 
              marginBottom: "3px",
              // color: "primary.main"
             }}
          >
            Description
          </Typography>
          {/* <Typography
            variant="h6"
            sx={{
              marginBottom: "3px",
              fontWeight: 500,
              color: "text.secondary",
            }}
          >
            Description
          </Typography> */}
          <ReactQuill
            value={data.project.description.trim()}
            readOnly={true}
            theme={"bubble"}
            style={{
              width: "100%",
              overflow: "hidden",
              display: "block",
              paddingLeft: "16px",
              // border: '1px solid #e0e0e0',
              borderRadius: "4px",
              // backgroundColor: '#ffffff',
            }}
          />
        </>
      )}

      {/* Project Documents */}
      {data.project.documents && data.project.documents.length > 0 ? (
        <List>
          <Typography
            variant="h6"
            sx={{ fontWeight: 500, 
              // color: "primary.main"
             }}
          >
            Project Overview
          </Typography>

          {data.project.documents.map((doc) => (
            <ListItem
              key={doc._id}
              disableGutters
              sx={{ paddingLeft: "32px", paddingTop: "2px", paddingBottom: "2px" }}
            >
              <Link
                href={doc.url}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  fontSize: "0.875rem",
                  color: "text.primary",
                  textDecoration: "none",
                  "&:hover": {
                    color: "primary.main",
                    textDecoration: "underline",
                  },
                }}
              >
                {doc.name}
              </Link>
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography
          variant="body2"
          sx={{ marginTop: "16px", color: "text.disabled" }}
        >
          No documents available.
        </Typography>
      )}
    </Box>
  );
};
