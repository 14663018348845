import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "../redux/reduxslices/userSlices";
import { clearProjectId } from "../redux/reduxslices/ProjectIdSlice";

const apiClient = axios.create({
  // baseURL: "http://localhost:4000/api",
    // baseURL:"https://us-central1-islam360-stagging.cloudfunctions.net/taskmanagerserver/api",
    baseURL: "https://us-central1-sampleprojectforusman.cloudfunctions.net/taskmanagerserver/api",

});

// Function to log in a user
export const loginUser = (userData) => apiClient.post("/users/login", userData);
