import React, { useEffect, useState } from "react";
import {
  FormControlLabel,
  Radio,
  Button,
  capitalize,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  IconButton,
  Autocomplete,
  Chip,
  Backdrop,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUsersData,
  fetchProjectsData,
  createNewProject,
  updateProject
} from "../../api/appApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import { FaPencilAlt } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";


export const ProjectList = ({ onSelectProject }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [selectedProject, setSelectedProject] = useState("");
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [newProject, setNewProject] = useState({
    name: "",
    documents: [],
    members: [],
    description: "",
  });
  const [newDocument, setNewDocument] = useState({ name: "", url: "" });
  const [editingIndex, setEditingIndex] = useState(null);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [error, setError] = useState(null);
  const [editingProject, setEditingProject] = useState(null);
  const [projects, setProjects] = useState(null);
  const [members, setMembers] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const UserRole = useSelector((state) => state.user?.user?.role);
  const UserId = useSelector((state) => state.user?.user?.userid);

  useEffect(() => {
    const fetchProjects = async () => {
      setLoading(true);
      try {

        const response = await fetchProjectsData(dispatch);
        // console.log(response.data)
        setProjects(response.data.projects);
        const user_response = await fetchUsersData(dispatch);
        // console.log(user_response.data)
        setMembers(user_response.data.users);
        toast.success("Project Data Fetched Successfully", {
          position: "top-right",
          autoClose: 1000,
          toastId: "fetched-project",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } catch (error) {
        setError(error.message);

        toast.error(
          "Oops! Error Occurs. Please try again!",
          // "Oops! There was an issue in Fetchng Project Data. Please try again!",
          {
            position: "top-right",
            autoClose: 1000,
            toastId: "task-error",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );

      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  // const projects = [
  //     { id: 1, name: 'Islam-360 Chatbot' },
  //     { id: 2, name: 'Talina' },
  //     { id: 3, name: 'FirmFox' },
  // ];

  // const members = [
  //     { id: '1', name: 'Muhammad Faisal' },
  //     { id: '2', name: 'Kumail' },
  //     { id: '3', name: 'ALi Hamza' },
  //     { id: '4', name: 'ALi Naveed' },
  // ];

  const handleChange = (event) => {
    setSelectedProject(event.target.value);
    console.log(event.target.value);
  };

  const handleOpenDialog = () => {
    setNewProject({ name: "", documents: [], members: [], description: "" });
    setSelectedMembers([]);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    // setOpenDialog(false);
    setNewProject({ name: "", documents: [], members: [], description: "" });
    setEditingProject(null);
    setOpenDialog(false);
    setSelectedMembers([]);
  };

  const handleContinue = () => {
    console.log("Selected Project ID:", selectedProject);
    onSelectProject(selectedProject);
  };

  const handleAddDocument = () => {
    if (newDocument.name && newDocument.url) {
      setNewProject((prev) => ({
        ...prev,
        documents: [...prev.documents, newDocument],
      }));
      setNewDocument({ name: "", url: "" }); // Reset document input fields
    }
  };

  const resetForm = () => {
    setNewProject({ name: "", documents: [], members: [] });
    setNewDocument({ name: "", url: "" });
    setEditingIndex(null);
    setSelectedMembers([]);
  };

  const handleAddOrUpdateDocument = () => {
    if (newDocument.name && newDocument.url) {
      if (editingIndex !== null) {
        const updatedDocuments = newProject.documents.map((doc, index) =>
          index === editingIndex ? newDocument : doc
        );
        setNewProject((prev) => ({ ...prev, documents: updatedDocuments }));
        setEditingIndex(null);
      } else {
        setNewProject((prev) => ({
          ...prev,
          documents: [...prev.documents, newDocument],
        }));
      }
      setNewDocument({ name: "", url: "" });
    }
  };

  const handleEditDocument = (index) => {
    setNewDocument(newProject.documents[index]);
    setEditingIndex(index);
  };

  const handleDeleteDocument = (index) => {
    const updatedDocuments = newProject.documents.filter((_, i) => i !== index);
    setNewProject((prev) => ({ ...prev, documents: updatedDocuments }));
  };

  const handleAddMember = (event, value) => {
    if (value && !selectedMembers.includes(value._id)) {
      setSelectedMembers((prev) => [...prev, value._id]);
      setNewProject((prev) => ({
        ...prev,
        members: [...prev.members, value._id],
      }));
      setSelectedUser(null);
    }
  };

  const handleDeleteMember = (id) => {
    setSelectedMembers((prev) => prev.filter((member) => member !== id));
    setNewProject((prev) => ({
      ...prev,
      members: prev.members.filter((memberId) => memberId !== id),
    }));
  };

  const handleEditProject = (project) => {
    setNewProject({ ...project });
    setEditingProject(project);
    setOpenDialog(true);
    // setSelectedMembers(project.members );
    // Set selected members to only the IDs
    setSelectedMembers(project.members.map((member) => member._id));
  };

  const handleAddProject = async () => {
    try {
      setLoading(true);
      console.log("Project Details are:", newProject);
      const response = await createNewProject(newProject, dispatch);
      const createdProject = response.data.projects;
      setProjects((prevProjects) => [...prevProjects, createdProject]);
      // console.log("Project created:", response.data);
      handleCloseDialog();
      onSelectProject(response.data.project._id);
    } catch (error) {
      console.error("Error creating project:", error);
      toast.error(
        "Oops! Error Occurs. Please try again!",
        // "Oops! There was an issue in Fetchng Project Data. Please try again!",
        {
          position: "top-right",
          autoClose: 500,
          toastId: "add-project-fails",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );

    } finally {
      setLoading(false);
    }
  };

  const handleUpdateProject = async () => {
    if (
      !editingProject ||
      newProject.name.trim() === "" ||
      newProject.documents.length == 0
    ) {
      toast.error("Project title or Document cannot be empty", {
        position: "top-right",
        autoClose: 1000,
        toastId: "document-id",
      });
      return;
    }

    setLoading(true);
    try {
      // console.log("editingProject _id is:" , editingProject._id ,"and newProject is : " , newProject);

      const UpdateProject = {
        ...newProject,
        members: selectedMembers,
        documents: newProject.documents.map(({ _id, ...documents }) => documents),
      };

      console.log(`UpdatedProject is ${UpdateProject}`);

      const response = await updateProject(editingProject._id, UpdateProject, dispatch);
      const updatedProject = response.data;

      setProjects((prevProjects) =>
        prevProjects.map((project) => {
          // console.log('Current Project:', project);
          return project._id === updatedProject.project._id
            ? updatedProject.project
            : project;
        })
      );

      toast.success("Project updated successfully!", {
        position: "top-right",
        autoClose: 1000,
        toastId: "update-success",
      });
      setOpenDialog(false);
    } catch (error) {
      console.error("Error updating project:", error);
      toast.error(
        "Oops! Error Occurs. Please try again!",
        // "Oops! There was an issue in Fetchng Project Data. Please try again!",
        {
          position: "top-right",
          autoClose: 500,
          toastId: "update-fails",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );

    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-[92vh] p-8">
      <Backdrop
        open={loading}
        sx={{
          color: "#2e7d32",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="flex flex-col items-start p-5 bg-white shadow-2xl rounded-2xl w-full max-w-2xl mx-auto">
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">
          Select a Project
        </h2>
        <div className="w-full mb-4">
          <ul className="list-none pl-0">
            {projects && projects.length > 0 ? (
              projects
                .filter((project) => {
                  if (UserRole === "admin") {
                    return true; // Show all projects for admin
                  } else if (UserRole === "user") {
                    // Show only assigned projects for user
                    return project.members.some(
                      (member) => member._id === UserId
                    );
                  }
                  return false; // If role is not recognized, show no projects
                })
                .map((project) => (
                  <li
                    key={project._id?.toString()}
                    className="mb-2 flex items-center justify-between p-2 hover:bg-gray-100 rounded"
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={selectedProject === project._id?.toString()}
                          onChange={handleChange}
                          value={project._id?.toString()}
                          name="project-radio"
                          sx={{
                            color: "#9e9e9e",
                            "&.Mui-checked": {
                              color: "#2e7d32",
                            },
                          }}
                        />
                      }
                      label={
                        <span className="text-gray-700 text-lg">
                          {project.name}
                          {/* {UserRole === "user" && (
                            <span className="text-gray-500 text-sm ml-2">
                              (
                              {project.members
                                .map((member) => member.name)
                                .join(", ")}
                              )
                            </span>
                          )} */}
                        </span>
                      }
                    />
                    {UserRole && UserRole !== "user" && (
                      <FaPencilAlt
                        className="cursor-pointer"
                        style={{
                          color: "#4caf50",
                          transition: "color 0.2s ease-in-out",
                        }}
                        onClick={() => handleEditProject(project)}
                        title="Edit project"
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.color = "#2e7d32")
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.color = "#4caf50")
                        }
                      />
                    )}
                  </li>
                ))
            ) : (
              <li className="mb-2 text-gray-700 text-lg">
                Project data not found
              </li>
            )}
          </ul>
        </div>

        <div className="w-full flex justify-end gap-2">
          {UserRole && UserRole !== "user" && (
            <Button
              variant="contained"
              onClick={handleOpenDialog}
              sx={{
                backgroundColor: "#4caf50",
                textTransform: "capitalize",
                color: "#fff",
                borderRadius: "8px",
                padding: "10px 20px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                transition: "background-color 0.3s, transform 0.2s",
                "&:hover": {
                  backgroundColor: "#388e3c",
                  transform: "scale(1.05)",
                },
              }}
              disabled={loading}
            >
              Add New
            </Button>
          )}

          <Button
            variant="contained"
            onClick={handleContinue}
            disabled={!selectedProject || loading}
            sx={{
              backgroundColor: "#4caf50",
              textTransform: "capitalize",
              color: "#fff",
              borderRadius: "8px",
              padding: "10px 20px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              transition: "background-color 0.3s, transform 0.2s",
              "&:hover": {
                backgroundColor: "#388e3c",
                transform: "scale(1.05)",
              },
            }}
          >
            Continue
          </Button>
        </div>
      </div>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="sm"
        PaperProps={{
          className: "flex items-start justify-center",
          style: {
            maxHeight: "80vh",
            borderRadius: "10px",
          },
        }}
        fullWidth
      >
        {/* <DialogTitle>Add New Project</DialogTitle> */}
        <DialogTitle sx={{ textAlign: "right" }}>
          {editingProject ? "Edit Project" : "Add New Project"}
        </DialogTitle>
        <DialogContent className="scrollable" dividers sx={{
          width: '100%',
          maxWidth: '100%',
          boxSizing: 'border-box',
          overflowX : "hidden",
        }}>
          {/* Title Field */}
          <span
            style={{
              display: "flex",
              gap: "1rem",
              marginBottom: "10px",
              alignItems: "center",
              border: "1px solid gray",
              padding: "10px",
              borderRadius: "5px",
              backgroundColor: "#f9f9f9",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              width: "auto",
            }}
          >
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
              Title:
            </Typography>
            <TextField
              sx={{ width: "100%", background: "transparent" }}
              value={newProject.name}
              onChange={(e) =>
                setNewProject({ ...newProject, name: e.target.value })
              }
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{
                style: { border: "none", padding: "5px", fontSize: "16px" },
              }}
            />
          </span>

          {/* Task Assign Section */}
          <Typography
            variant="body1"
            style={{ fontWeight: "bold", marginBottom: "10px" }}
          >
            User Assign:
          </Typography>
          <Autocomplete
            options={members || []}
            getOptionLabel={(option) => option.name}
            onChange={handleAddMember}
            value={selectedUser}
            renderInput={(params) => (
              <TextField {...params} label="" value={selectedUser || ""} variant="outlined" />
            )}
          />

          {/* Selected Members as Chips */}
          <div style={{ marginTop: "10px" }}>
            {selectedMembers.map((id) => {
              const member = members.find((m) => m._id === id);
              return (
                <Chip
                  key={id}
                  label={member ? member.name : id}
                  onDelete={() => handleDeleteMember(id)}
                  style={{ margin: "5px" }}
                />
              );
            })}
          </div>

          <Typography
            variant="body1"
            style={{ fontWeight: "bold", marginBottom: "10px" }}
          >
            Documents:
          </Typography>
          {newProject.documents.map((doc, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "1rem",
                marginBottom: "5px",
                alignItems: "center",
                border: "1px solid gray",
                padding: "10px",
                borderRadius: "5px",
                backgroundColor: "#f9f9f9",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                width:'100%',
                maxWidth:'100%',
                boxSizing:'border-box'
              }}
            >
              <div style={{ display: "flex", gap: "0.3rem" ,width:'calc(100% - 20%)' }}>
                <Typography variant="body1" style={{ fontWeight: "bold" }}>
                  {doc.name}:
                </Typography>
                <a style={{ textOverflow: "ellipsis", overflow: 'hidden', whiteSpace: "nowrap" }} href={doc.url} target="_blank" rel="noopener noreferrer">
                  {doc.url}
                </a>
              </div>
              <div style={{ display: "flex", gap: "0rem" }}>
                <IconButton
                  onClick={() => handleEditDocument(index)}
                  size="small"
                  style={{
                    transition: "color 0.2s ease-in-out",
                    color: "#4caf50",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.color = "#2e7d32")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.color = "#4caf50")
                  }
                >
                  <FaPencilAlt />
                </IconButton>

                {/* <IconButton
                  onClick={() => handleEditDocument(index)}
                  size="small"
                >
                  <FontAwesomeIcon icon={faEdit} />
                </IconButton> */}

                <IconButton
                  onClick={() => handleDeleteDocument(index)}
                  size="medium"
                  style={{
                    transition: "color 0.2s ease-in-out",
                    color: "#f44336",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.color = "#d32f2f")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.color = "#f44336")
                  }
                >
                  <RiDeleteBinLine size={21} />
                </IconButton>

                {/* <IconButton
                  onClick={() => handleDeleteDocument(index)}
                  size="small"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </IconButton> */}
              </div>
            </div>
          ))}

          <span
            style={{
              display: "flex",
              gap: "1rem",
              marginBottom: "10px",
              justifyContent: "space-between",
              alignItems: "center",
              border: "1px solid gray",
              padding: "10px",
              borderRadius: "5px",
              backgroundColor: "#f9f9f9",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <TextField
              label="Document Name"
              value={newDocument.name}
              onChange={(e) =>
                setNewDocument({ ...newDocument, name: e.target.value })
              }
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{
                style: { border: "none", padding: "5px", fontSize: "16px" },
              }}
            />
            <TextField
              label="Document URL"
              value={newDocument.url}
              onChange={(e) =>
                setNewDocument({ ...newDocument, url: e.target.value })
              }
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{
                style: { border: "none", padding: "5px", fontSize: "16px" },
              }}
            />
            <Button
              variant="contained"
              onClick={handleAddOrUpdateDocument}
              sx={{
                backgroundColor: "#4caf50",
                textTransform: "capitalize",
                color: "#fff",
                borderRadius: "8px",
                padding: "10px 20px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                transition: "background-color 0.3s, transform 0.2s",
                "&:hover": {
                  backgroundColor: "#388e3c",
                  transform: "scale(1.05)",
                },
              }}
              disabled={loading}
            // endIcon={loading ? <CircularProgress size={20} sx={{ color: '#fff' }} /> : null}
            >
              {editingIndex !== null ? "Update" : "Add"}
            </Button>
          </span>

          <span
            style={{ width: "100%" }}
            className="flex flex-col gap-2 mb-8 sm:mb-16 md:mb-18 "
          >
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Description:
            </Typography>
            <ReactQuill
              value={newProject.description}
              // onChange={(e) => setNewProject({ ...newProject, description: e.target.value })}
              // value={newTask.description}
              onChange={(value) => {
                setNewProject({ ...newProject, description: value });
              }}
              modules={{
                toolbar: [
                  [{ header: [1, 2, false] }],
                  ["bold", "italic", "underline", "strike"],
                  ["link", "image", "blockquote", "code-block"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["clean"],
                ],
              }}
              style={{
                height: "150px",
                borderRadius: "5px",
                width: "100%", 
                maxWidth: "550px",
                // backgroundColor: '#f9f9f9',
                // marginBottom: '40px',
                // marginBottom: {
                //     xs: '40px',
                //     md:'40px',
                //     sm: '60px',
                // },
              }}
            />
          </span>
        </DialogContent>
        <DialogActions sx={{ paddingRight: 2 }}>
          {/* <Button onClick={handleCloseDialog} color="primary">Cancel</Button> */}
          <Button
            variant="contained"
            // onClick={() => { console.log("Project Details are :", newProject); handleCloseDialog(); }}
            onClick={editingProject ? handleUpdateProject : handleAddProject}
            // onClick={handleAddProject}
            sx={{
              backgroundColor: "#4caf50",
              textTransform: "capitalize",
              color: "#fff",
              borderRadius: "8px",
              marginLeft: "15px",
              padding: "10px 20px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              transition: "background-color 0.3s, transform 0.2s",
              "&:hover": {
                backgroundColor: "#388e3c",
                transform: "scale(1.05)",
              },
            }}
            disabled={loading}
            endIcon={
              loading ? (
                <CircularProgress size={20} sx={{ color: "#fff" }} />
              ) : null
            }
          >
            {editingProject ? "Update Project" : "Add Project"}
          </Button>
          {/* <Button onClick={() => { console.log(newProject); handleCloseDialog(); }} color="primary">Add Project</Button> */}
        </DialogActions>
      </Dialog>
      {/* <ToastContainer /> */}
    </div>
  );
};