import React, { useState } from 'react';
import DatePicker from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import { MdDateRange } from 'react-icons/md';
import dayjs from 'dayjs';
import InputIcon from "react-multi-date-picker/components/input_icon";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import * as XLSX from "xlsx";
import { FaDownload } from "react-icons/fa";

const Attendance = () => {
    const userData = {
        name: 'John Doe',
        joiningDate: '2023-10-01',
    };

    const today = dayjs();
    const [selectedRange, setSelectedRange] = useState([null, null]);
    const [attendance, setAttendance] = useState([]);
    const [showResults, setShowResults] = useState(false);

    const handleDateRangeChange = (range) => {
        setSelectedRange(range);
    };

    const generateDummyData = (startDate, endDate) => {
        const data = [];
        let currentDate = startDate;

        while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
            const dayOfWeek = currentDate.format('dddd'); // Get the day of the week (e.g., Monday, Tuesday)
            let status = '';
            let timeIn = '';
            let timeOut = '';

            if (dayOfWeek === 'Saturday' || dayOfWeek === 'Sunday') {
                // Public holiday for weekends
                status = 'PublicHoliday';
            } else {
                // Randomly assign present/leave
                const isPresent = Math.random() > 0.3; // 70% chance of being present
                status = isPresent ? 'Present' : 'Leave';

                if (isPresent) {
                    // If present, generate random time-in and time-out
                    timeIn = `08:${Math.floor(Math.random() * 60)} AM`;
                    timeOut = `05:${Math.floor(Math.random() * 60)} PM`;
                }
            }

            data.push({
                Date: currentDate.format('MM/DD/YYYY'),
                Day: dayOfWeek,
                Present: status,
                TimeIn: timeIn,
                TimeOut: timeOut,
            });

            currentDate = currentDate.add(1, 'day');
        }

        return data;
    };


    const handleSearch = () => {
        if (selectedRange && selectedRange[0] && selectedRange[1]) {
            const startDate = dayjs(selectedRange[0]);
            const endDate = dayjs(selectedRange[1]);

            if (!startDate.isValid() || !endDate.isValid()) {
                console.error("Invalid date range.");
                return;
            }

            const attendanceData = generateDummyData(startDate, endDate);
            setAttendance(attendanceData);
            setShowResults(true);
        } else {
            console.error("Please select a valid date range.");
        }
    };

    const downloadExcel = () => {

        const ws = XLSX.utils.json_to_sheet(attendance);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Attendance Data");

        XLSX.writeFile(wb, "attendance_data.xlsx");
    };

    // const handleSearch = () => {

    //     if (selectedRange && selectedRange[0] && selectedRange[1]) {
    //         const startDate = dayjs(selectedRange[0]);
    //         const endDate = dayjs(selectedRange[1]);

    //         if (!startDate.isValid() || !endDate.isValid()) {
    //             console.error("Invalid date range.");
    //             return;
    //         }

    //         console.log(`Attendance Date Range: From ${startDate.format('MM/DD/YYYY')} to ${endDate.format('MM/DD/YYYY')}`);

    //         const selectedDates = [];
    //         let currentDate = startDate;

    //         while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
    //             selectedDates.push(currentDate.format('MM/DD/YYYY'));
    //             currentDate = currentDate.add(1, 'day');
    //         }

    //         setAttendance(selectedDates);
    //         setShowResults(true); 
    //     } else {
    //         console.error("Please select a valid date range.");
    //     }
    // };


    return (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
            <h2 className="text-2xl font-semibold mb-4">Attendance Tracker</h2>

            {/* <p className="text-lg">
                User: {userData.name} | Joining Date: {dayjs(userData.joiningDate).format('MM/DD/YYYY')}
            </p>

            <p className="mt-2">
                Attendance Range: {dayjs(userData.joiningDate).format('MM/DD/YYYY')} - {today.format('MM/DD/YYYY')}
            </p> */}

            <div className='date_picker_react' style={{ marginTop: '20px', display: 'flex', gap: '10px', alignItems: 'center' }}>
                <DatePicker
                    value={selectedRange}
                    onChange={handleDateRangeChange}
                    range
                    minDate={dayjs(userData.joiningDate).toDate()}
                    maxDate={today.toDate()}
                    plugins={[<DatePanel />]}
                    render={<InputIcon style={{
                        padding: '16px 8px',
                        fontSize: '18px',
                        color: '#47934a',
                        cursor: 'pointer',
                        '&.icon-tabler-calendar-event': {
                            color: 'green',
                        },

                    }} />}
                />

                <Button
                    onClick={handleSearch}
                    variant="contained"
                    sx={{
                        backgroundColor: '#4CAF50',
                        color: '#fff',
                        borderRadius: '8px',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        textTransform: 'capitalize',
                        '&:hover': {
                            backgroundColor: '#388e3c',
                            transform: 'scale(1.05)',
                        },
                    }}
                >
                    Search
                </Button>
            </div>



            {showResults && (
                <div style={{ marginTop: '20px' }}>

                    {/* <h3 className="text-xl font-semibold mb-2">Attendance Data</h3> */}

                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: '8px 0px' }}>
                        <h3 className="text-xl font-semibold mb-2">Attendance Data</h3>

                        <Button
                            onClick={downloadExcel}
                            variant="contained"
                            sx={{
                                backgroundColor: '#4CAF50',
                                color: '#fff',
                                borderRadius: '8px',
                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                textTransform: 'capitalize',
                                display: 'flex',
                                alignItems: 'center',
                                '&:hover': {
                                    backgroundColor: '#388e3c',
                                    transform: 'scale(1.05)',
                                },
                            }}
                        >
                            <FaDownload style={{ marginRight: '8px' }} />
                            Download
                        </Button>
                        {/*                         
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={downloadExcel}
                            sx={{ marginLeft: 'auto' }}
                        >
                            Download as XLSX
                        </Button> */}
                    </div>

                    <TableContainer component={Paper} elevation={3} sx={{ borderRadius: "8px" }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        align="left"
                                        sx={{
                                            backgroundColor: "#c8e6c9",
                                            color: "#47934a",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Date
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        sx={{
                                            backgroundColor: "#c8e6c9",
                                            color: "#47934a",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Day
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        sx={{
                                            backgroundColor: "#c8e6c9",
                                            color: "#47934a",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Present
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        sx={{
                                            backgroundColor: "#c8e6c9",
                                            color: "#47934a",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Time In
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        sx={{
                                            backgroundColor: "#c8e6c9",
                                            color: "#47934a",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Time Out
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {attendance.map((entry, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="left">{entry.Date}</TableCell>
                                        <TableCell align="left">{entry.Day}</TableCell>
                                        <TableCell align="left">{entry.Present}</TableCell>
                                        <TableCell align="left">{entry.TimeIn || '-'}</TableCell>
                                        <TableCell align="left">{entry.TimeOut || '-'}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            )}





            {/* {showResults && (
                <div style={{ marginTop: '20px' }}>
                    <h3 className="text-xl font-semibold mb-2">Selected Attendance Dates</h3>

                    <TableContainer component={Paper} elevation={3} sx={{ borderRadius: "8px" }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        align="left"
                                        sx={{
                                            backgroundColor: "#c8e6c9",
                                            color: "#47934a",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        User
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        sx={{
                                            backgroundColor: "#c8e6c9",
                                            color: "#47934a",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Attendance Dates
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="left">{userData.name}</TableCell>
                                    <TableCell align="left">
                                        {attendance.length > 0 ? (
                                            <ul>
                                                {attendance.map((date, index) => (
                                                    <li key={index}>{date}</li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <p>No attendance selected yet.</p>
                                        )}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            )} */}
        </div>
    );
};

export default Attendance;


