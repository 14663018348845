import { createSlice } from "@reduxjs/toolkit";

// Initial state
const initialState = {
    filters: JSON.parse(localStorage.getItem("filters")) || [],  // Initialize from local storage if available
};

const filterSlice = createSlice({
    name: "filters",
    initialState,
    reducers: {
        // Add or update a filter with projectidcat and selectedmembers
        setFilter(state, action) {
            const { projectidcat, selectedmembers } = action.payload;

            // Find the filter by projectidcat
            const existingFilter = state.filters.find(
                (filter) => filter.projectidcat === projectidcat
            );

            if (existingFilter) {
                // If filter exists, update selectedmembers
                existingFilter.selectedmembers = selectedmembers;
            } else {
                // Otherwise, add new filter
                state.filters.push({ projectidcat, selectedmembers });
            }

            // Store the updated filters in local storage
            localStorage.setItem("filters", JSON.stringify(state.filters));
        },

        // Remove a filter by projectidcat
        removeFilter(state, action) {
            const { projectidcat } = action.payload;
            // Remove the filter by projectidcat
            state.filters = state.filters.filter(
                (filter) => filter.projectidcat !== projectidcat
            );
            // Update local storage
            localStorage.setItem("filters", JSON.stringify(state.filters));
        },

        // Clear all filters
        clearFilters(state) {
            state.filters = [];
            localStorage.removeItem("filters");
        },
    },
});

// Exporting the actions
export const { setFilter, removeFilter, clearFilters } = filterSlice.actions;

// Exporting the reducer
export default filterSlice.reducer;






// import { createSlice } from "@reduxjs/toolkit";

// // Initial state with an empty array for filters (can be pre-loaded from local storage if needed)
// const initialState = {
//     filters: JSON.parse(localStorage.getItem("filters")) || [],  // Initialize from local storage if available
// };

// const filterSlice = createSlice({
//     name: "filters",
//     initialState,
//     reducers: {
//         // Add or update a filter in the filters array
//         setFilter(state, action) {
//             const { projectidcat, selectedmembers } = action.payload;

//             // Find the filter by projectidcat
//             const existingFilter = state.filters.find(filter => filter.projectidcat === projectidcat);

//             if (existingFilter) {
//                 // If the filter exists, update the selectedmembers
//                 existingFilter.selectedmembers = selectedmembers;
//             } else {
//                 // Otherwise, add a new filter
//                 state.filters.push({ projectidcat, selectedmembers });
//             }

//             // Store the updated filters in local storage
//             localStorage.setItem("filters", JSON.stringify(state.filters));
//         },

//         // Remove a filter by projectidcat
//         removeFilter(state, action) {
//             const { projectidcat } = action.payload;
//             // Filter out the filter with the given projectidcat
//             state.filters = state.filters.filter(filter => filter.projectidcat !== projectidcat);

//             // Store the updated filters in local storage
//             localStorage.setItem("filters", JSON.stringify(state.filters));
//         },

//         // Clear all filters
//         clearFilters(state) {
//             state.filters = [];
//             // Clear the filters from local storage
//             localStorage.removeItem("filters");
//         },
//     },
// });

// // Exporting the actions
// export const { setFilter, removeFilter, clearFilters } = filterSlice.actions;

// // Exporting the reducer
// export default filterSlice.reducer;
