import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { Box, Button, CircularProgress, Container, TextField, Typography } from '@mui/material';
import TurndownService from 'turndown';
import { marked } from 'marked';
import 'react-quill/dist/quill.snow.css';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { createHighLevelRequirements } from '../../api/appApi';

const HLRequirements = ({ onNext, onBack, data, projectId }) => {

    const dispatch = useDispatch();

    const [inputData, setInputData] = useState('');
    const [projectName, setProjectName] = useState('');
    const [loading, setLoading] = useState(false);


    const handleChange = (value) => {
        setInputData(value);
    };

    const handleProjectNameChange = (e) => {
        setProjectName(e.target.value);
    };

    const handleNext = async () => {
        
        // console.log(`HtmlData is : ${inputData}`);
        const turndownService = new TurndownService();
        // Convert HTML to markdown
        const convertedMarkdown = turndownService.turndown(inputData);
        // console.log(convertedMarkdown);

        const PostHlData = {
            "name": projectName,
            "requirements": convertedMarkdown,
            "projectId": projectId
        }

        // console.log(`PostHlData is ${JSON.stringify(PostHlData)}`);

        if (projectName.trim() === '' || convertedMarkdown.trim() === '') {
            toast.info("Project Name and Requirements cannot be empty!", {
                position: "top-right",
                autoClose: 1000,
                toastId: "empty-fields",
                hideProgressBar: false,
            });
            return;
        }

        try {
            setLoading(true);
            const response = await createHighLevelRequirements(PostHlData, dispatch);
            toast.success("🎉 project has been Initalize successfully!", {
                position: "top-right",
                autoClose: 1000,
                toastId: "upd-task-success",
                hideProgressBar: false,
            });
            // console.log(response.data.document);

            onNext({ highLevelRequirements: { projectName: projectName, inputData: inputData }, detailedSpecs: response.data.generateRequirementsData.response.detailed_requirements, projectId: response.data._id });

        } catch (error) {
            console.error("Error updating task:", error);
            toast.error(
                "Oops! There was an issue updating the task. Please try again!",
                {
                    position: "top-right",
                    autoClose: 1000,
                    toastId: "task-upd-error",
                    hideProgressBar: false,
                }
            );

        } finally {
            setLoading(false);
        }




        // const convertedHtml = marked(convertedMarkdown);
        // console.log(convertedHtml);
    };

    useEffect(() => {
        if (data !== '') {
            setProjectName(data.projectName);
            const convertedHtml = marked(data.inputData);
            console.log(convertedHtml);
            setInputData(convertedHtml);
        }
        console.log(data)
    }, [data]);


    return (
        <>
            <Box className="React-Quill-textArea" sx={{
                width: '100%',
                height: 'auto',
                // padding: { xs: 2, sm: 4, md: 6 }, 
                paddingLeft: { xs: 2, sm: 4, md: 6 },
                paddingRight: { xs: 2, sm: 4, md: 6 },
                paddingTop: '5px',
                borderRadius: '8px',
            }}
            >
                <Container>
                    <Typography variant="h5" gutterBottom sx={{
                        fontWeight: 'bold',
                        color: '#333',
                        marginBottom: 3,
                        textAlign: 'center'
                    }}>
                        High-Level Requirements
                    </Typography>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.5rem',
                        marginBottom: 3,
                    }}>
                        <Typography variant="body1" sx={{
                            fontWeight: 'bold',
                            color: '#333',
                            textAlign: 'left'
                        }}>
                            Project Name:
                        </Typography>
                        <TextField
                            variant="outlined"
                            value={projectName}
                            onChange={handleProjectNameChange}
                            fullWidth
                            placeholder="Enter Project Name"
                            sx={{
                                backgroundColor: '#fff',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                '& .MuiOutlinedInput-root': {
                                    padding: '0px 0px',
                                },
                            }}
                        />
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.5rem',
                        marginBottom: 3,
                    }}>
                        <Typography variant="body1" sx={{
                            fontWeight: 'bold',
                            color: '#333',
                            textAlign: 'left',
                        }}>
                            Enter Your Requirements:
                        </Typography>
                        <ReactQuill
                            value={inputData}
                            onChange={handleChange}
                            modules={{
                                toolbar: [
                                    [{ 'header': '1' }, { 'header': '2' }],
                                    [{ 'font': [] }, { 'size': ['small', false, 'large', 'huge'] }],
                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                    [{ 'indent': '-1' }, { 'indent': '+1' }],
                                    ['bold', 'italic', 'underline', 'strike'],
                                    [{ 'align': [] }],
                                    [{ 'color': [] }, { 'background': [] }],
                                    ['link', 'image', 'video'],
                                    ['blockquote'],
                                    ['code-block'],
                                    ['clean'],
                                    [{ 'direction': 'rtl' }],
                                ],
                            }}

                        />
                    </Box>
                </Container>

            </Box>

            <Box sx={{
                mt: 2,
                // padding: { xs: 1, sm: 4 }, 
                paddingLeft: { xs: 2, sm: 4, md: 6 },
                paddingRight: { xs: 2, sm: 4, md: 6 },
                textAlign: { xs: 'center', sm: 'right' }
            }}>

                <Button
                    onClick={handleNext}
                    variant="contained"
                    style={{
                        backgroundColor: '#4caf50', textTransform: 'capitalize', color: '#fff', borderRadius: '8px', padding: '10px 20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', transition: "background-color 0.3s, transform 0.2s",
                        "&:hover": { backgroundColor: "#388e3c", transform: "scale(1.05)", },
                    }}
                    disabled={loading}
                    endIcon={loading ? <CircularProgress size={20} style={{ color: '#fff' }} /> : null}
                >
                    Next
                </Button>
                {/* <Button
                    onClick={handleNext}
                    sx={{
                        backgroundColor: '#2e7d32',
                        color: '#fff',
                        fontWeight: 'bold',
                        textTransform: 'capitalize',
                        padding: '6px 12px',
                        borderRadius: '4px',
                        border: 'none',
                        '&:hover': {
                            backgroundColor: '#388e3c',
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                        },
                    }}
                >
                    Next
                </Button> */}
            </Box>
        </>
    );
};

export default HLRequirements;