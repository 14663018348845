import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';

const commits = [
  { id: 1, name: '12355', imageurl: 'https://via.placeholder.com/150', },
  { id: 2, name: 'CommitID', imageurl: 'https://via.placeholder.com/150', },
  { id: 3, name: 'commit-info-card', imageurl: 'https://via.placeholder.com/150', },
  { id: 4, name: 'commit-info-card', imageurl: 'https://via.placeholder.com/150', },
];

const GithubChatbot = () => {
  return (
    <div className="p-6 space-y-6">
      <Box className="text-center">
        <Typography variant="h4" className="font-bold text-gray-800 mb-8 text-start">
          Github-Chatbot
        </Typography>
        <Typography variant="h6" className="font-semibold text-gray-700 text-start">
          Git Push Guidelines
        </Typography>
        <ul className="list-disc list-inside text-gray-600 text-start">
          <li>Must Update Change Logs</li>
          <li>Must Update documentation before Merge on Production</li>
          <li>Must Update requirement.txt</li>
          <li>Must test all APIs</li>
        </ul>
      </Box>


      <Box className="space-y-4">
        <Typography variant="h5" className="font-bold text-gray-700">
          Commits
        </Typography>
        <Typography variant="subtitle1" className="text-gray-500 flex items-center">
          <span role="img" aria-label="gallery" className="mr-2">📁</span> Gallery
        </Typography>
        <Box className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">

          {commits.map((commit) => (
            <Card key={commit.id} className="shadow-md flex flex-col">
              <img src={commit.imageurl} alt={commit.name} className="object-cover h-40 w-full" />
              <CardContent className="flex flex-col items-start justify-between flex-grow">
                <Typography variant="body1" className="text-gray-600  text-start">
                  📃 {commit.name}
                </Typography>

              </CardContent>
            </Card>
          ))}
        </Box>
      </Box>
    </div>
  );
};

export default GithubChatbot;
