import React from 'react';
import { Card, CardContent, Typography, Button, Grid, Box, Container } from '@mui/material';


const salaryData = [
    { month: 'Aug', number: 3, pdfLink: 'https://file-examples.com/wp-content/storage/2017/10/file-sample_150kB.pdf' },
    { month: 'Sep', number: 2, pdfLink: 'https://file-examples.com/wp-content/storage/2017/10/file-example_PDF_500_kB.pdf' },
    { month: 'Oct', number: 5, pdfLink: 'https://file-examples.com/wp-content/storage/2017/10/file-example_PDF_1MB.pdf' },
    { month: 'Nov', number: 1, pdfLink: 'https://file-examples.com/wp-content/storage/2017/10/file-example_PDF_500_kB.pdf' },
    { month: 'Dec', number: 6, pdfLink: 'https://file-examples.com/wp-content/storage/2017/10/file-example_PDF_1MB.pdf' },
];

const SalarySlip = () => {

    const handleDownload = (pdfLink) => {
        window.open(pdfLink, '_blank');
    };

    return (
        <Container>
            <div style={{ padding: 20 }}>
                <Typography variant="h4" sx={{ marginBottom: 3, fontWeight: 'bold', color: '#4caf50' }}>
                    Salary Slips
                </Typography>




                <Grid container spacing={2}>
                    {salaryData.map((data, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card sx={{
                                maxWidth: 345,
                                boxShadow: 3,
                                borderRadius: 2,
                                backgroundColor: '#f9f9f9',
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: 6,
                                }
                            }}>
                                <CardContent>
                                    {/* Month and number display */}
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#388e3c' }}>
                                        {data.month} {data.number}
                                    </Typography>

                                    <Box sx={{ marginTop: 2 }}>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                backgroundColor: "#4caf50",
                                                textTransform: "capitalize",
                                                color: "#fff",
                                                borderRadius: "8px",
                                                padding: "10px 20px",
                                                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                                transition: "background-color 0.3s, transform 0.2s",
                                                "&:hover": {
                                                    backgroundColor: "#388e3c",
                                                    transform: "scale(1.05)",
                                                },
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                            onClick={() => handleDownload(data.pdfLink)}
                                        >
                                            Download PDF
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </Container>

    );
};

export default SalarySlip;