import React, { useState } from 'react'
import { Box, Button, Container, CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, ImageList, ImageListItem, Typography } from '@mui/material'
import ReactMarkdown from 'react-markdown';
import { FaTimes } from 'react-icons/fa' 
import { useNavigate } from 'react-router-dom';

const SOWReview = ({ onNext, onBack, data, projectId }) => {

    console.log(`data For SOWReciew : ${data}`);
    const navigate = useNavigate();

    const [open, setOpen] = useState(false)
    const [selectedImage, setSelectedImage] = useState('')

    const handleOpen = (image) => {
        setSelectedImage(image)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleNext = () => {
        navigate(`/project-dashboard`);
    }
    

    return (
        <>
            <div className="text-xl font-semibold text-center py-4">
                SOW Review
            </div>

            <Container maxWidth="lg" className="py-6">
                {data?.highLevelRequirements && data.highLevelRequirements !== "" ? (
                    <>
                        <div className="mb-4">
                            <Typography variant="h5" align="left" className="font-semibold text-gray-800">
                                Project Name:
                            </Typography>
                            <Typography variant="h6" align="left" className="text-gray-700">
                                {data.highLevelRequirements.projectName}
                            </Typography>
                        </div>

                        <div className="mt-4 mb-2">
                            <Typography variant="h5" align="left" className="font-semibold text-gray-800">
                                High-Level Requirements:
                            </Typography>
                            <Typography variant="body1" align="left" className="text-gray-700 mt-2">
                                <ReactMarkdown>{data.highLevelRequirements.inputData}</ReactMarkdown>
                            </Typography>
                        </div>
                    </>
                ) : (
                    <div className="text-center text-lg text-gray-600 mt-6">
                        No high-level requirements available.
                    </div>
                )}

                <hr className="my-6 border-t-2 border-gray-300" />
            </Container>

            <Container maxWidth="lg" className="py-0">
                {data?.detailedSpecs && data.detailedSpecs !== "" ? (
                    <>
                        <div className="mb-2">
                            <Typography variant="h5" align="left" className="font-semibold text-gray-800">
                                Detailed Project Documentation:
                            </Typography>
                            <Typography variant="body1" align="left" className="text-gray-700 mt-2">
                                <ReactMarkdown>{data.detailedSpecs}</ReactMarkdown>

                            </Typography>
                        </div>
                    </>
                ) : (
                    <div className="text-center text-lg text-gray-600">
                        No detailed specifications available.
                    </div>
                )}
                <hr className="my-6 border-t-2 border-gray-300" />

            </Container>

            <Container maxWidth="lg" className="py-0">
                {data?.workflowBreakdown && data.workflowBreakdown !== "" ? (
                    <>
                        <div className="mt-6">
                            <Typography variant="h5" align="left" className="font-semibold text-gray-800">
                                Work Breakdown & Resource Allocation Summary:
                            </Typography>
                            <Typography variant="body1" align="left" className="text-gray-700 mt-2">
                                <ReactMarkdown>{data.workflowBreakdown}</ReactMarkdown>

                            </Typography>
                        </div>
                    </>
                ) : (
                    <div className="text-center text-lg text-gray-600">
                        No Work Flow available.
                    </div>
                )}
                <hr className="my-6 border-t-2 border-gray-300" />

            </Container>

            <Container maxWidth="lg" className="py-6">
                {data?.figmaDesign && Object.keys(data.figmaDesign).length > 0 ? (
                    <>
                        <Typography
                            variant="h5"
                            align="left"
                            className="font-semibold text-gray-800 mb-6"
                        >
                            Figma Designs
                        </Typography>

                        <ImageList
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))', 
                                gap: 30, 
                                marginTop: '1rem', 
                                transition: 'all 0.3s ease',
                                padding:2
                            }}
                            cols={2} 
                            rowHeight={250} 
                        >
                            {Object.entries(data.figmaDesign).map(([key, base64Image], index) => (
                                <ImageListItem
                                    key={index}
                                    sx={{
                                        borderRadius: 4,
                                        overflow: 'hidden',
                                        boxShadow: 4, 
                                        transition: 'transform 0.3s ease-in-out',
                                        // '&:hover': {
                                        //     transform: 'scale(1.05)', 
                                        // },
                                    }}
                                >
                                    <img
                                        src={`data:image/png;base64,${base64Image}`}
                                        alt={key}
                                        loading="lazy"
                                        className="w-full h-full object-cover rounded-md transition-transform duration-300"
                                        style={{
                                            maxHeight: '250px',
                                        }}
                                        onClick={() => handleOpen(base64Image)} 
                                    />
                                    <Typography
                                        variant="caption"
                                        align="center"
                                        className="mt-2 text-gray-600"
                                    >
                                        {key}
                                    </Typography>
                                </ImageListItem>
                            ))}
                        </ImageList>

                        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                            <DialogTitle>
                                <div className="flex justify-between items-center">
                                    <Typography variant="h6" textAlign={'center'}>Image Preview</Typography>
                                    <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                                        <FaTimes size={24} /> 
                                    </IconButton>
                                </div>
                            </DialogTitle>
                            <DialogContent>
                                <img
                                    src={`data:image/png;base64,${selectedImage}`}
                                    alt="Selected"
                                    className="w-full h-auto rounded-md shadow-md"
                                />
                            </DialogContent>
                            {/* <DialogActions>
                                <button
                                    onClick={handleClose}
                                    className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors"
                                >
                                    Close
                                </button>
                            </DialogActions> */}
                        </Dialog>
                    </>
                ) : (
                    <div className="text-center text-lg text-gray-600 mt-6">
                        No Figma designs available.
                    </div>
                )}
            </Container>


            <Box sx={{
                mt: 2,
                paddingLeft: { xs: 2, sm: 4, md: 6 },
                paddingRight: { xs: 2, sm: 4, md: 6 },
                textAlign: { xs: 'center', sm: 'right' },
            }}>

                <Button
                    onClick={onBack}
                    variant="contained"
                    style={{
                        backgroundColor: '#4caf50', textTransform: 'capitalize', color: '#fff', borderRadius: '8px', padding: '10px 20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', transition: "background-color 0.3s, transform 0.2s", marginRight: '0.7rem',
                        "&:hover": { backgroundColor: "#388e3c", transform: "scale(1.05)", },
                    }}
                    // disabled={loading}
                // endIcon={loading ? <CircularProgress size={20} style={{ color: '#fff' }} /> : null}
                >
                    Back
                </Button>


                <Button
                    onClick={handleNext}
                    variant="contained"
                    style={{
                        backgroundColor: '#4caf50', textTransform: 'capitalize', color: '#fff', borderRadius: '8px', padding: '10px 20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', transition: "background-color 0.3s, transform 0.2s",
                        "&:hover": { backgroundColor: "#388e3c", transform: "scale(1.05)", },
                    }}
                    // disabled={loading}
                    // endIcon={loading ? <CircularProgress size={20} style={{ color: '#fff' }} /> : null}
                >
                    Next
                </Button>
            </Box>



            {/* <div className="flex justify-between px-4 py-2">
                <button
                    onClick={onBack}
                    className="px-6 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-700 disabled:opacity-50"
                    disabled={false} ic
                >
                    Back
                </button>

                <button
                    onClick={onNext}
                    className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
                    disabled={false}
                >
                    Next
                </button>
            </div> */}

        </>
    )
}

export default SOWReview